import React from "react";

const CultureAndValues = () => {
  return (
    <>
      {/* <div className="w-full bg-[#EDEBE9] text-[#1F1F1E] font-semibold text-sm py-3 pl-6 shadow shadow-[#1F1F1E]  ">
                CAREERS
            </div> */}
      <section className="min-h-screen grid  xs:grid-cols-2 00040F bg-[#00040F] text-[#EDEBE9]">
        <div className="cultureBannerBg mb-8  flex flex-col justify-end pl-4  h-[620px]  xs:h-[750px] sm:h-[750px]  md:h-[800px] lg:h-[850px] xl:w-[70%] ">
          <h1 className="text-white  font-semibold pt-4 text-1xl md:text-4xl ">
            CULTURE AND VALUES
          </h1>
          <p className="text-gray-100 text-xs md:text-base font-medium mb-14 "></p>
        </div>
        <div className="px-6 xs:pt-10 md:pr-10 ">
          <h2 className=" leading-6  font-[550] text-2xl pb-3">
            Expect more than connections.
          </h2>
          <p className="text-xs leading-5">
            From its inception, Above Ground Solutions aimed to be a distinctive
            telecommunications company. One that not only thrived on innovation
            but also connectivity. We're a bridge to seamless communication, an
            integral part of your everyday connections. As you get to know us,
            you'll discover that we are more than just the technology we
            provide. We call our employees team members because we are all
            united in achieving shared success. We ensure that everything we do
            is guided by a human-centric approach—from our commitment to
            delivering the best telecommunications solutions, to the way we
            interact with our clients and communities to conduct business
            responsibly
          </p>

          <h3 className="font-semibold py-2 leading-5 ">
            Not bound by convention
          </h3>
          <p className="text-xs leading-5">
            Do it our way. Our success—and much of the fun—lies in developing
            new ways to do things.
          </p>
          <img
            className="pt-2 rounded-lg"
            src="https://images.unsplash.com/photo-1568992687947-868a62a9f521?auto=format&fit=crop&q=80&w=1932&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          ></img>

          <div className=" xs:hidden md:inline ">
            <h3 className="font-semibold leading-5 py-2">
              Our A culture of inclusion
            </h3>
            <p className="text-xs leading-5">
              We're committed to upholding a culture where inclusion, diversity,
              equity and accessibility are valued and respected. Your entire
              experience—starting with your application—is designed to be the
              beginning of an inspirational journey, where you are treated
              warmly and with transparency, dignity and respect.
            </p>
          </div>
        </div>

        <div className=" xs:col-span-2 text-xl  md:text-4xl xl:text-5xl md:px-10 px-3 md:pr-10 font-bold py-12 ">
          At Above Ground Solutions, we're looking ahead, not back. Together, we
          can place a higher importance on our mission than on profits and work
          to preserve this space, our one and only home for people and
          connectivity.
        </div>
      </section>
    </>
  );
};

export default CultureAndValues;
