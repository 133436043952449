import React, { useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useMutation, useQuery } from "@apollo/client";

import { Link } from "react-router-dom";
import Style from "../userPage.module.css";
import { FcBriefcase } from "react-icons/fc";
import { MdLibraryAddCheck } from "react-icons/md";
import { BsPersonFillAdd } from "react-icons/bs";
import { QUERY_USER_FRIENDS } from "../utils/queries";

import { FaUsersRays } from "react-icons/fa6";
import { CREATE_COMPANY } from "../utils/mutations";

const CreateNewCompany = () => {
  const { loading, data } = useQuery(QUERY_USER_FRIENDS, {
    variables: { email: "email." },
  });
  const [isTrueForError, SetIsTrueForError] = useState(false);

  const [newCompany, setNewCompany] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [crewMembers, setCrewMembers] = useState([]);
  const [createCompany, { error }] = useMutation(CREATE_COMPANY);
  const [chosenCrewMembers, SetCrewMembers] = useState([]);
  const returnPage = () => {
    window.history.back();
  };

  useEffect(() => {
    if (data) {
      setCrewMembers(data.oneUser.friends);
    }
  }, [loading, data]);
  const handleError = () => {
    SetIsTrueForError(true);
    setTimeout(() => {
      SetIsTrueForError(false);
    }, 5000);
  };
  const handleCreateCompany = async (e) => {
    e.preventDefault();
    if (newCompany.length === 0) {
      handleError();
      return;
    }

    const data = e.currentTarget;
    const arrayOfEmails = chosenCrewMembers.map((obj) => obj.crewEmail);
    try {
      const { data } = await createCompany({
        variables: {
          companyName: newCompany,
          emails: arrayOfEmails,
        },
      })(data);

      if (data.createCompany.id) {
        returnPage();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCLick = async (e) => {
    e.preventDefault();

    const data = e.currentTarget;
    // const firstAid = data.getAttribute('cprFirstAid') === 'true';
    // const certifiedClimber = data.getAttribute('certifiedClimber') === 'true'
    const name = data.getAttribute("name");
    const updatedData = [...chosenCrewMembers];

    if (updatedData.some((item) => item.name === name)) {
      const clearedData = updatedData
        .filter((item) => item.name !== name) // Remove the clicked item
        .concat({
          name: " ",
          crewEmail: " ",
          joined: false,
          company: " ",
          cprFirstAid: false,
          certifiedClimber: false,
        }); // Add the clicked item to the end

      SetCrewMembers(clearedData);
      setCurrentIndex(currentIndex - 1);
      return;
    }
    if (currentIndex === 7) {
      return;
    }
    // const company = data.getAttribute('company')
    const crewEmail = data.getAttribute("email");

    updatedData[currentIndex] = {
      name: name,
      crewEmail: crewEmail,
    };

    SetCrewMembers(updatedData);
    setCurrentIndex(currentIndex + 1);
  };

  return (
    <section className="1f] cursor-default ">
      <div className="col-span-4 bg-[#000206] shadow-md shadow-[#070b0f] flex items-center w-full">
        <Link onClick={returnPage} className="icon  md:ml-5 xl:ml-32 ">
          <FaLongArrowAltLeft size={20} className="icon pb-1 hover:h-[.1%]" />
        </Link>
      </div>
      <div
        className={`text-white px-6 py-1 border-0 z-50 rounded-b-sm  mb-3 text-center bg-red-700 shadow-red-950 shadow-md  ${
          !isTrueForError ? "invisible" : "block"
        }`}
      >
        <span className="text-lg inline-block ">
          <i className="fas fa-bell" />
        </span>
        <span className="inline-block  text-sm">
          <p className=" text-lg  inline-flex"></p> Please fill in all mandatory
          fields.
        </span>
        <button className="absolute  bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"></button>
      </div>
      <div className={` min-h-screen `}>
        <div className={Style.account_info}>
          <p className="pt-5">
            You can set preferred display name, create your profile URL and
            manage other personal settings.
          </p>
        </div>

        <div className={` cursor-default ${Style.account_box}`}>
          <div className={`cursor-default ${Style.account_box_img}`}>
            <FaUsersRays size={60} className="icon pb-1 hover:h-[.1%]" />
          </div>
          <div className={Style.account_box_from}>
            <div className={Style.Form}>
              <div className={Style.Form_box}>
                <form className="mt-[-10px]">
                  <div className={Style.Form_box_input}>
                    <label htmlFor="Company">Company</label>
                    <div className={Style.Form_box_input_box}>
                      <div className={Style.Form_box_input_box_icon}>
                        <FcBriefcase />
                      </div>
                      <input
                        onChange={(e) => setNewCompany(e.target.value)}
                        className={Style.Form_box_input_userName}
                        value={newCompany}
                        type="string"
                        placeholder="Company"
                      />
                    </div>
                  </div>

                  <label
                    htmlFor="AddUsers"
                    className="text-sm font-semibold ml-2"
                  >
                    Add Users
                  </label>
                  <ul className="grid grid-cols-1  gap-2    ">
                    {data === false
                      ? "Loading..."
                      : crewMembers.map((item, i) => {
                          const isAlreadyAdded = chosenCrewMembers.some(
                            (member) => item.email === member.crewEmail
                          );
                          return (
                            <li
                              htmlFor={`${item.email}-option`}
                              className={` flex px-2 rounded-md bg-[#050505] justify-between gap-x-6 py-2 ${
                                isAlreadyAdded
                                  ? "bg-blue-600 shadow-md"
                                  : "bg-[#0E0E0E] rounded-lg cursor-pointer  peer-checked:shadow-blue-950"
                              }  rounded-md  border-[#62626228]  shadow-lg  `}
                              cprFirstAid={item.cprFirstAid.toString()}
                              certifiedClimber={item.certifiedClimber.toString()}
                              number={i}
                              onClick={handleCLick}
                              email={item.email}
                              name={item.username}
                              company={item.company}
                            >
                              <div className="flex min-w-0 gap-x-4 ">
                                <img
                                  className="h-10 w-10 flex-none rounded-full bg-gray-50"
                                  src={item.profilePicture}
                                  alt="profile picture"
                                />
                                <div className="min-w-0 w-36">
                                  <p className="text-sm font-semibold leading-5 text-gray-100">
                                    {item.username}
                                  </p>
                                  <p className="mt-1 truncate text-xs leading-3 text-gray-300">
                                    {item.email}
                                  </p>
                                </div>
                              </div>
                              <div className=" shrink-0 flex flex-col items-end">
                                <p className="text-sm invisible leading-6 text-gray-400">
                                  .
                                </p>

                                <div className=" flex items-center ">
                                  {isAlreadyAdded ? (
                                    <MdLibraryAddCheck />
                                  ) : (
                                    <BsPersonFillAdd
                                      key={i} // Assuming 'i' is defined elsewhere in your component
                                      onClick={handleCLick}
                                      email={item.email}
                                    />
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                  </ul>
                  <button
                    btnName="Upload profile"
                    onClick={handleCreateCompany}
                    // onSubmit={UpdateProfile}
                    className="ring-2 hover:bg-blue-600 bg-blue-700 font-semibold w-full flex text-center rounded justify-center my-9 mb-14"
                  >
                    {" "}
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateNewCompany;
