import autoTable from 'jspdf-autotable'
import { jsPDF } from "jspdf";









const createNewJsa = async (data) => {
  const doc = new jsPDF();

  // let { 'chosenCrewMembers' } = data;
  let { jobName, jobNumber, otherJobSafetyCheckboxList, towerRescueOtherCheckBox,
    physicalAddress, longitude,
    hospitalAddress,
    latitude, supervisor,
    listTelephone, creatorName, company,
    ambulance, fire,
    police, localHospital,
    telephoneCo, utilityCo, do911SystemsWorkCheckBoxList,
    evacuationPoint, checkFirstAidOnSite,
    hardHatCheckBoxList,
    potentialHazards, preventiveMeasures,
    date, fallsFromElevationsCheckedBoxList,
    firstAidKitCheckBoxList, rescueBagTowerCheckBoxList,
    towerErectionCheckboxList,
    civilConcreteCheckBoxList, lineAtennasCheckBoxList,
    blueNoticeSignCheckBoxList, yellowCautionSignCheckBox,
    redWarningSignCheckBoxList, redDangerSignCheckBoxList,
    jobTasks, electricalHazardsCheckBoxList,
    silicaExposureCheckBox, coldStressCheckBoxList,

    hearingCheckBoxList, glovesCheckBoxList,
    rfmonitorsCheckBoxList, lockoutTagoutCheckBoxList,
    tailgateMeetingCheckBoxList, fourInOneSignCheckBoxList,
    excavationLogCheckBoxList, elecricalCheckBoxList,
    creatorFirstAid,creatorClimber,
    slopingCheckBoxList, benchingCheckBoxList,
    shoringCheckBoxList, trenchCheckboxList,
    shieldBoxCheckBoxList, laddertrench4FeetCheckBoxList,
    elevationSiteTerrainCheckBoxList,
    fullBodyHarnessCheckBoxList, ropeGrabCheckBoxList,
    cableGrabCheckBoxList, doubleLegTwoLanyardsCheckBoxList,
    retractableLifeLineChecBoxList,
    anchorageStrapsCheckBoxList,
    fallProtectionRopeCheckBoxList, craneBoomTruckCheckBoxList,
    suspenedPersonnelPlatformChechBoxList, vehicleTrafficCheckBoxList,
    undergroundUtilitiesCheckBoxList, heatStressCheckBoxList,
    highNoise85dBACheckBoxList, slipsTripsOrFallsCheckBoxList,
    emeAndRfCheckBoxList, chemicalExposureCheckBoxList,
    liftingHazardsCheckBoxList, safetyGlassesCheckBoxList,
    rfSuitsCheckBoxList, hoistsCheckBoxList,
    tagLinesCheckBoxList, ginPolesCheckBoxList,
    toolsEquipmentCheckBoxList, housekeepingCheckBoxList,
    riggingCheckBoxList, groundFaultProtectionCheckBoxList,
    towerginPoleCheckBoxList, fallProtectionCheckBoxList,
    descendersCheckBoxList, permitSystemCheckBoxList, towerRescueFireDeparment,
    towerRescueInHouseCrew,

    //tower work
    typeTowerOrRoofTop, typeAntennaBoom, inspectedFallProtectionEquipmentCheckBoxList,
    describeFallProtectionSystemUsedAccessAntenna, exitManLiftMoPCheckBoxList,

    // mpe 
    blueNoticeFirstMpeReading, blueNoticeSecondMpeReading, yellowCautionFirstMpeReading,
    yellowCautionSecondMpeReading, redWarningFirstMpeReading, redWarningSecondMpeReading,


    //others
    otherPhysicalHazards, otherHealthHazards,

    // civilwork
    describeTypeDepthExcavation, elevationSiteTerrainEnivromentalConcersHazards,
    hazardsWithSiteVehicleStorage, describeTypeElectricalCocernsOrHazards
  } = data;









  const isfirstAidKitBold = checkFirstAidOnSite ? ('bold') : ('')
  autoTable(doc, {
    headStyles: {
      fillColor: "orange"
    }
    ,
    theme: 'grid',
    head: [[{ content: 'Above ground Solutions', rowSpan: 1, colSpan: 4 }, { content: '', rowSpan: 2, colSpan: 2 }, { colSpan: 2, rowSpan: 1, content: '' }, { colSpan: 2, rowSpan: 1, content: '' }, { colSpan: 2, rowSpan: 1, content: '' }, { colSpan: 2, rowSpan: 1, content: '' }, { colSpan: 4, rowSpan: 1, content: '', styles: { halign: 'right' } }],
    [{ content: 'Complete Daily (before Beginning Work or for Pre-jon Analysis)', rowSpan: 1, colSpan: 16, styles: { textColor: 'black', } }],
    ],
    body: [

      [{ content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }, { content: 'dsfs', styles: { textColor: 'white' } }],
      [{ content: 'Job Safety Analyis', colSpan: 18, rowSpan: 1, styles: { halign: 'center', textColor: 'red' } }],



      [{ content: 'Job Information', colSpan: 18, rowSpan: 1, styles: { halign: 'center', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 7.5 }, }],
      [{ content: `Date: ` + date, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }, { content: `Job Name:` + jobName, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }, { content: `Job #:` + jobNumber, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }, { content: `Latitude` + latitude, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }, { content: `Longitude:` + longitude, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } },],
      ['', { content: `Physical Address:` + physicalAddress, colSpan: 10, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }, { content: `Supervisor: ` + supervisor, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }],


      [{ content: 'Project Personnel', colSpan: 18, rowSpan: 1, styles: { halign: 'center', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 7.5 }, }],
      [{ content: 'Name:', colSpan: 4, rowSpan: 1, styles: { halign: 'center', } }, { content: 'Company:', colSpan: 3, rowSpan: 1, styles: { halign: 'center', } }, { content: 'CPR/  FirstAid ', colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 5.8, textColor: "black" } }, { content: 'Certified Climber ', colSpan: 1, rowSpan: 1, styles: { halign: 'center', textColor: "black", fontSize: 5.3 } }, { content: 'Name:', colSpan: 4, rowSpan: 1, styles: { halign: 'center', } }, { content: 'Company:', colSpan: 3, rowSpan: 1, styles: { halign: 'center' } }, { content: 'CPR/  FirstAid ', colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 5.8, text: 'black' } }, { content: 'Certified Climber ', colSpan: 1, rowSpan: 1, styles: { halign: 'center', textColor: "black", fontSize: 5.3 } },],
      [{ content: creatorName, colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: company, colSpan: 3, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: creatorFirstAid?'yes':' ', colSpan: 1, rowSpan: 1, styles: { halign: 'center',fontSize:7 } }, { content: creatorClimber? 'yes':'no', colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize:7} }, { content: data.chosenCrewMembers[6].name, colSpan: 4, rowSpan: 1, styles: { halign: 'center',fontSize:7 } }, { content: data.chosenCrewMembers[6].cprFirstAid?"yes":" ", colSpan: 3, rowSpan: 1, styles: { halign: 'center',fontSize:7 } }, { content: data.chosenCrewMembers[6].certifiedClimber?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center' ,fontSize:7} }],
      [{ content: data.chosenCrewMembers[0].name, colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[0].company, colSpan: 3, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[0].cprFirstAid?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content:  data.chosenCrewMembers[0].certifiedClimber?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[3].name, colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[3].company, colSpan: 3, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[3].cprFirstAid?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } },{ content: data.chosenCrewMembers[3].certifiedClimber?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }],
      [{ content: data.chosenCrewMembers[1].name, colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[1].company, colSpan: 3, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content:  data.chosenCrewMembers[1].cprFirstAid?"yes":" ",colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[1].certifiedClimber?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[4].name, colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[4].company, colSpan: 3, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[4].cprFirstAid?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } },{ content: data.chosenCrewMembers[3].certifiedClimber?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }],
      [{ content: data.chosenCrewMembers[2].name, colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[2].company, colSpan: 3, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content:  data.chosenCrewMembers[2].cprFirstAid?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[2].certifiedClimber?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[5].name, colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[5].company, colSpan: 3, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[5].cprFirstAid?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } },{ content: data.chosenCrewMembers[3].certifiedClimber?"yes":" ", colSpan: 1, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }],


      [{ content: 'Emergency Porcedures', colSpan: 18, rowSpan: 1, styles: { halign: 'center', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 7.5 }, }],


      [{ content: 'List telephone numbers and attach drections to the site:' + '', colSpan: 18, rowSpan: 1, styles: { halign: 'left', fontSize: 8 } }],

      [{ content: do911SystemsWorkCheckBoxList ? 'Are 911 systems functional with cell phone use?:          yes' : 'Are 911 systems functional with cell phone use?: no', colSpan: 18, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }],
      [{ content: '', rowSpan: 1 }, { content: 'Tower Rescue Procedures to be used:', colSpan: 16, rowSpan: 1, styles: { lineColor: 'white', halign: 'left', fontSize: 8.5 } }],
      [{ content: '', rowSpan: 1 }, { content: towerRescueFireDeparment ? `× Fire Department` : `_ Fire Department`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 8, lineColor: 'white', fontStyle: towerRescueFireDeparment ? 'bold' : 'normal', } }, { content: towerRescueInHouseCrew ? '× In-House Crew (Crew must be properly trained in tower rescue)' : '_ In-House Crew (Crew must be properly trained in tower rescue)', colSpan: 9, rowSpan: 1, styles: { halign: 'left', fontSize: 8, fontStyle: towerRescueInHouseCrew ? 'bold' : 'normal', lineColor: 'white' } }, { content: `  other: ` + towerRescueOtherCheckBox, colSpan: 4, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontSize: 8, fontStyle: shoringCheckBoxList ? 'bold' : 'normal', } }],
      [{ content: `Ambulance:` + ambulance, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }, { content: `Police:` + police, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }, { content: `Fire:` + fire, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }],
      [{ content: `Local Hospital:` + localHospital, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }, { content: `Telephone Co:` + telephoneCo, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }, { content: `Utility co:` + utilityCo, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }],
      [{ content: `Evacuation Point:` + evacuationPoint, colSpan: 8, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }, { content: `Hospital Address:` + hospitalAddress, colSpan: 10, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } } ,],


      [{ content: 'Job Safety / Task for Today', colSpan: 18, rowSpan: 1, styles: { halign: 'center', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 7.5 }, }],
      [{ content: '', rowSpan: 1 }, { content: `Check safety procedures/ work being performe:`, colSpan: 8, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5, lineColor: 'white' } }, { content: ``, colSpan: 5, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5, lineColor: 'white' } }, { content: ``, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5, lineColor: 'white' } }],
      [{ content: '', rowSpan: 1 }, { content: firstAidKitCheckBoxList ? '× First Aid Kit on site' : '_ First Aid Kit on site', colSpan: 8, rowSpan: 1, styles: { halign: 'left', fontSize: 8, fontStyle: firstAidKitCheckBoxList ? 'bold' : 'normal', lineColor: 'white' } }, { content: towerErectionCheckboxList ? `× tower Erection` : `_ tower Erection`, colSpan: 5, rowSpan: 1, styles: { halign: 'left', fontSize: 8, lineColor: 'white', fontStyle: towerErectionCheckboxList ? 'bold' : 'normal', } }, { content: elecricalCheckBoxList ? `× Electrical` : `_ Electrical`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5, lineColor: 'white', fontStyle: elecricalCheckBoxList ? 'bold' : 'normal' } }],
      [{ content: '', rowSpan: 1 }, { content: rescueBagTowerCheckBoxList ? `× Rescue Bag at Base of Tower` : `_ Rescue Bag at Base of Tower`, colSpan: 8, rowSpan: 1, styles: { halign: 'left', fontSize: 8, lineColor: 'white', fontStyle: rescueBagTowerCheckBoxList ? 'bold' : 'normal' } }, { content: lineAtennasCheckBoxList ? `× Line and Antennas` : `_ Line and Antennas`, colSpan: 5, rowSpan: 1, styles: { halign: 'left', fontSize: 8, lineColor: 'white', fontStyle: lineAtennasCheckBoxList ? 'bold' : 'normal' } }, { content: civilConcreteCheckBoxList ? `× Civil / concrete` : `_ Civil / concrete`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5, lineColor: 'white', fontStyle: civilConcreteCheckBoxList ? 'bold' : 'normal' } }],
      [{ content: '', rowSpan: 1 }, { content: `Other: ` + otherJobSafetyCheckboxList, colSpan: 8, rowSpan: 1, styles: { halign: 'left', fontSize: 8, lineColor: 'white' } }, { content: ``, colSpan: 5, rowSpan: 1, styles: { halign: 'left', fontSize: 8, lineColor: 'white' } }, { content: ``, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5, lineColor: 'white' } }],

      [{ content: 'RF Signage & MPE readings', colSpan: 18, rowSpan: 1, styles: { halign: 'center', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 8.5 }, fontSize: 8.5 }],
      [{ content: blueNoticeSignCheckBoxList ? ` × Blue Notice Sign` : `_ Blue Notice Sign `, colSpan: 5, rowSpan: 1, styles: { halign: 'left', fontSize: 7, fontStyle: blueNoticeSignCheckBoxList ? 'bold' : 'normal' } }, { content: yellowCautionSignCheckBox ? `× Yellow Caution Sign` : `_ Yellow Caution Sign`, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 7, fontStyle: yellowCautionSignCheckBox ? 'bold' : 'normal' } }, { content: redWarningSignCheckBoxList ? `× red Warning Sign` : `_ red Warning Sign`, colSpan: 5, rowSpan: 1, styles: { halign: 'left', fontSize: 7, fontStyle: redWarningSignCheckBoxList ? 'bold' : 'normal' } }, { content: redDangerSignCheckBoxList ? `× Red Danger Sign` : `_ Red Danger Sign`, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 7, fontStyle: redDangerSignCheckBoxList ? 'bold' : 'normal' } }],
      [{ content: `Eeuipment MPE reading: ` + blueNoticeFirstMpeReading, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 5.5 } }, { content: `Equipement MPE reading: ` + yellowCautionFirstMpeReading, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 5.5 } }, { content: `Equiment Mpe reading: ` + redWarningFirstMpeReading, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 5.5 } }],
      [{ content: `Elevation MPE reading: ` + blueNoticeSecondMpeReading, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 5.5 } }, { content: `Elevation MPE reading: ` + yellowCautionSecondMpeReading, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 5.5 } }, { content: `Elevation MPE reading: ` + redWarningSecondMpeReading, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 5.5 } }],

      [{ content: `Job/Tasks (Be Specific)`, colSpan: 7, rowSpan: 1, styles: { halign: 'left', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 8.5 } }, { content: `Potential Hazards (Be specific)`, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 8.5 } }, { content: `Preventive Measures`, colSpan: 7, rowSpan: 1, styles: { halign: 'left', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 8.5 } }],

      [{ content: jobTasks, colSpan: 7, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5, minCellHeight: 35 } }, { content: potentialHazards, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }, { content: preventiveMeasures, colSpan: 7, rowSpan: 1, styles: { halign: 'left', fontSize: 8.5 } }],



      [{ content: '(2) Job Site Exposure', colSpan: 18, rowSpan: 1, styles: { halign: 'center', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 7.5 }, }],
      [{ content: '', rowSpan: 1 }, { content: `Physical Hazards:`, colSpan: 7, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white' } }, { content: '', rowSpan: 1 }, { content: `Health Hazards:`, colSpan: 8, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white' } },],
      [{ content: '', rowSpan: 1 }, { content: fallsFromElevationsCheckedBoxList ? `× Falls from Elevations ` : `_ Falls from Elevations `, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: fallsFromElevationsCheckedBoxList ? 'bold' : 'normal' } }, { content: vehicleTrafficCheckBoxList ? `× Vehicle Traffic` : `_ Vehicle Traffic`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: vehicleTrafficCheckBoxList ? 'bold' : 'normal' } }, { content: undergroundUtilitiesCheckBoxList ? `× Underground Utilities` : `_ Underground Utilities`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: undergroundUtilitiesCheckBoxList ? 'bold' : 'normal' } }, { content: '', rowSpan: 1 }, { content: heatStressCheckBoxList ? `× Heat Stress` : `_ Heat Stress`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: heatStressCheckBoxList ? 'bold' : 'normal' } }, { content: highNoise85dBACheckBoxList ? `× High Noise (>85 dBA)` : `_ High Noise (>85 dBA)`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: highNoise85dBACheckBoxList ? 'bold' : 'normal' } }, { content: coldStressCheckBoxList ? `× Cold Stress` : `_ Cold Stress`, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: coldStressCheckBoxList ? 'bold' : 'normal' } },],
      [{ content: '', rowSpan: 1 }, { content: slipsTripsOrFallsCheckBoxList ? `× Slips, Trip or Falls` : `_ Slips, Trip or Falls`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: slipsTripsOrFallsCheckBoxList ? 'bold' : 'normal' } }, { content: electricalHazardsCheckBoxList ? `× Electrical` : `_ Electrical`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: electricalHazardsCheckBoxList ? 'bold' : 'normal' } }, { content: elevationSiteTerrainCheckBoxList ? `× Elevation / Site Terrain` : `_ Elevation / Site Terrain`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: elevationSiteTerrainCheckBoxList ? 'bold' : 'normal' } }, { content: '', rowSpan: 1 }, { content: emeAndRfCheckBoxList ? `× EME / RF` : `_ EME / RF`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: emeAndRfCheckBoxList ? 'bold' : 'normal' } }, { content: chemicalExposureCheckBoxList ? `× Chemical Exposure` : `_ Chemical Exposure`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: chemicalExposureCheckBoxList ? 'bold' : 'normal' } }, { content: liftingHazardsCheckBoxList ? `× Lifting Hazard` : `_ Lifting Hazard`, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: liftingHazardsCheckBoxList ? 'bold' : 'normal' } }],
      [{ content: '', rowSpan: 1 }, { content: `Other: ` + otherPhysicalHazards, colSpan: 7, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white' } }, { content: '', rowSpan: 1 }, { content: silicaExposureCheckBox ? `× Silica Exposure` : `_ Silica Exposure`, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: silicaExposureCheckBox ? 'bold' : 'normal' } }, { content: `Other: ` + otherHealthHazards, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white' } }, { content: ``, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 8, lineColor: 'white' } }],



      [{ content: '(3) Hazard Control Measures', colSpan: 18, rowSpan: 1, styles: { halign: 'center', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 7.5 }, }],

      [{ content: '', rowSpan: 1 }, { content: `Personal Protective Equipment/Monitoring Equipment`, colSpan: 7, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white' } }, { content: '', rowSpan: 1 }, { content: `Inspections *Complete all additional forms required*`, colSpan: 8, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white' } },],
      [{ content: '', rowSpan: 1 }, { content: safetyGlassesCheckBoxList ? `× Safety Glasses` : `_ Safety Glasses`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: safetyGlassesCheckBoxList ? 'bold' : 'normal' } }, { content: rfSuitsCheckBoxList ? `× RF Suits` : `_ RF Suits`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: rfSuitsCheckBoxList ? 'bold' : 'normal' } }, { content: hearingCheckBoxList ? `× Hearing` : `_ Hearing`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: hearingCheckBoxList ? 'bold' : 'normal' } }, { content: '', rowSpan: 1 }, { content: tagLinesCheckBoxList ? `× Tag Lines` : `_ Tag Lines`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: tagLinesCheckBoxList ? 'bold' : 'normal' } }, { content: ginPolesCheckBoxList ? `× Gin Poles` : `_ Gin Poles`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: ginPolesCheckBoxList ? 'bold' : 'normal' } }, { content: toolsEquipmentCheckBoxList ? `× Tools/Equipment` : `_ Tools/Equipment`, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: toolsEquipmentCheckBoxList ? 'bold' : 'normal' } },],
      [{ content: '', rowSpan: 1 }, { content: fallProtectionCheckBoxList ? `× Fall Protection` : `_ Fall Protection`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: fallProtectionCheckBoxList ? 'bold' : 'normal' } }, { content: hardHatCheckBoxList ? `× Hard Hat` : `_ Hard Hat`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: hardHatCheckBoxList ? 'bold' : 'normal' } }, { content: glovesCheckBoxList ? `× Gloves` : `_ Gloves`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: glovesCheckBoxList ? 'bold' : 'normal' } }, { content: '', rowSpan: 1 }, { content: hoistsCheckBoxList ? `× Hoists` : `_ Hoists`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: hoistsCheckBoxList ? 'bold' : 'normal' } }, { content: riggingCheckBoxList ? `× Rigging` : `_ Rigging`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: riggingCheckBoxList ? 'bold' : 'normal', } }, { content: housekeepingCheckBoxList ? `× Housekeeping` : `_ Housekeeping`, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: housekeepingCheckBoxList ? 'bold' : 'normal' } }],
      [{ content: '', rowSpan: 1 }, { content: rfmonitorsCheckBoxList ? `× RF Monitors` : `_ RF Monitors`, colSpan: 7, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: rfmonitorsCheckBoxList ? 'bold' : 'normal' } }, { content: '', rowSpan: 1 }, { content: groundFaultProtectionCheckBoxList ? `× Ground Fault Protection` : `_ Ground Fault Protection`, colSpan: 6, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: groundFaultProtectionCheckBoxList ? 'bold' : 'normal' } }, { content: ``, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white' } }],

      [{ content: '', rowSpan: 1 }, { content: 'Safety Program / Training:', colSpan: 16, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white' } },],

      [{ content: '', rowSpan: 1 }, { content: tailgateMeetingCheckBoxList ? `× TailGate    Meeting` : `_ TailGate  Meeting`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: tailgateMeetingCheckBoxList ? 'bold' : 'normal', } }, { content: fourInOneSignCheckBoxList ? '× 4 in 1 Sign' : '_ 4 in 1 Sign', colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, fontStyle: fourInOneSignCheckBoxList ? 'bold' : 'normal', lineColor: 'white' } }, { content: lockoutTagoutCheckBoxList ? `× Lockout/Tagout` : `_ Lockout/Tagout`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontSize: 7, fontStyle: lockoutTagoutCheckBoxList ? 'bold' : 'normal', } }, { content: excavationLogCheckBoxList ? `× excavation Log` : `_ excavation Log`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontSize: 7, fontStyle: excavationLogCheckBoxList ? 'bold' : 'normal' } }, { content: permitSystemCheckBoxList ? `× Permit System(hoisting personnel, Confined Space, Excavation, etc.` : `_ Permit System(hoisting personnel, Confined Space, Excavation, etc.`, colSpan: 6, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontSize: 7, fontStyle: permitSystemCheckBoxList ? 'bold' : 'normal' } }],

      [{ content: 'Complete for Civil Work', colSpan: 18, rowSpan: 1, styles: { halign: 'center', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 7.5 }, }],
      [{ content: '1. Describe type and depth of excavations:' + describeTypeDepthExcavation, colSpan: 18, rowSpan: 1, styles: { halign: 'left', fontSize: 7 } }],
      [{ content: '', rowSpan: 1 }, { content: '2. Cave-in control measures to be used if excavation will be greater than 5 feet and personnel are entering the trench:  ', rowSpan: 1, styles: { fontSize: 7, lineColor: 'white' }, colSpan: 16 },],
      [{ content: '', rowSpan: 1 }, { content: slopingCheckBoxList ? `× Sloping` : `_ Sloping`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: slopingCheckBoxList ? 'bold' : 'normal', } }, { content: benchingCheckBoxList ? '× Benching' : '_ Benching', colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 7, fontStyle: benchingCheckBoxList ? 'bold' : 'normal', lineColor: 'white' } }, { content: shoringCheckBoxList ? `× Shoring` : `_ Shoring`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontSize: 7, fontStyle: shoringCheckBoxList ? 'bold' : 'normal', } }, { content: trenchCheckboxList ? `× Trench` : `_ Trench`, colSpan: 2, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontSize: 7, fontStyle: trenchCheckboxList ? 'bold' : 'normal' } }, { content: shieldBoxCheckBoxList ? `× Shield/Box` : `_ Shield/Box`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: shieldBoxCheckBoxList ? 'bold' : 'normal' } }, { content: laddertrench4FeetCheckBoxList ? `× Ladder in Trench at 4 feet` : `_ Ladder in Trench at 4 feet`, colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontSize: 7, lineColor: 'white', fontStyle: laddertrench4FeetCheckBoxList ? 'bold' : 'normal' } }],

      [{ content: '3. Describe elevation / site terrain / environmental concerns or hazards: ' + elevationSiteTerrainEnivromentalConcersHazards, colSpan: 18, rowSpan: 1, styles: { halign: 'left', fontSize: 7 } }],
      [{ content: '4.Describe hazards with site / vehicle access (i.e. boom and cranes/electrical lines) and storage of materials: ' + hazardsWithSiteVehicleStorage, colSpan: 18, rowSpan: 1, styles: { halign: 'left', fontSize: 7 } }],
      [{ content: '5. Describe the type of electrical cocerns or hazards: ' + describeTypeElectricalCocernsOrHazards, colSpan: 18, rowSpan: 1, styles: { halign: 'left', fontSize: 7 } }],

      [{ content: 'Complete for Tower Work (Fall Protection & Using Suspended Personnel PlatForm)', colSpan: 18, rowSpan: 1, styles: { halign: 'center', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 7.5 }, }],
      [{ content: 'Type of Tower / Rooftop: ' + typeTowerOrRoofTop, rowSpan: 1, colSpan: 9, styles: { fontSize: 7 } }, { content: 'Type of Antenna Boom: ' + typeAntennaBoom, rowSpan: 1, colSpan: 9, styles: { fontSize: 7 } }],
      [{ content: '', rowSpan: 1 }, { content: `Fall protection to be used:        `, rowSpan: 1, colSpan: 16, styles: { lineColor: 'white', fontSize: 7 } },],
      [{ content: '', rowSpan: 1 }, { content: cableGrabCheckBoxList ? `× Cable Grab` : `_ Cable Grab`, colSpan: 4, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontStyle: cableGrabCheckBoxList ? 'bold' : 'normal', fontSize: 7 } }, { content: fullBodyHarnessCheckBoxList ? '× FullBody Harness' : '_ FullBody Harness', colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontStyle: fullBodyHarnessCheckBoxList ? 'bold' : 'normal', lineColor: 'white', fontSize: 7 } }, { content: doubleLegTwoLanyardsCheckBoxList ? `× Double Leg or 2 Lanyards` : `_ Double Leg or 2 Lanyards`, colSpan: 5, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontStyle: doubleLegTwoLanyardsCheckBoxList ? 'bold' : 'normal', fontSize: 7 } }, { content: ropeGrabCheckBoxList ? `× Rope Grab` : `_ Rope Grab`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontStyle: ropeGrabCheckBoxList ? 'bold' : 'normal', fontSize: 7 } }],
      [{ content: '', rowSpan: 1 }, { content: retractableLifeLineChecBoxList ? `× Retractable Lifeline` : `_ Retractable Lifeline`, colSpan: 4, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontStyle: retractableLifeLineChecBoxList ? 'bold' : 'normal', fontSize: 7 } }, { content: anchorageStrapsCheckBoxList ? '× Anchorage Straps' : '_ Anchorage Straps', colSpan: 4, rowSpan: 1, styles: { halign: 'left', fontStyle: anchorageStrapsCheckBoxList ? 'bold' : 'normal', lineColor: 'white', fontSize: 7 } }, { content: fallProtectionRopeCheckBoxList ? `× Ropes` : `_ Ropes`, colSpan: 5, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontStyle: fallProtectionCheckBoxList ? 'bold' : 'normal', fontSize: 7 } }, { content: descendersCheckBoxList ? `× Descenders` : `_ Descenders`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontStyle: descendersCheckBoxList ? 'bold' : 'normal', fontSize: 7 } }],

      [{ content: inspectedFallProtectionEquipmentCheckBoxList ? '1. Has each employee inspected his or her fall protection equipment?: Yes' : '1. Has each employee inspected his or her fall protection equipment?: ', colSpan: 18, rowSpan: 1, styles: { halign: 'left', fontSize: 7 } }],
      [{ content: '2. Describe the fall protection system to be used when acessing antenna booms or performing tower erection: ' + describeFallProtectionSystemUsedAccessAntenna, colSpan: 18, rowSpan: 1, styles: { halign: 'left', fontSize: 8 } }],
      [{ content: '', rowSpan: 1 }, { content: 'Hoisting Equipment to be used: ', rowSpan: 1, colSpan: 16, styles: { fontSize: 7 } },],
      [{ content: '', rowSpan: 1 }, { content: suspenedPersonnelPlatformChechBoxList ? '× Suspended Personnel PlatForm(Manbasket)' : '_ Suspended Personnel PlatForm(Manbasket)', colSpan: 8, rowSpan: 1, styles: { halign: 'left', fontStyle: suspenedPersonnelPlatformChechBoxList ? 'bold' : 'normal', lineColor: 'white', fontSize: 7 } }, { content: craneBoomTruckCheckBoxList ? `× Crane/Boom Truck` : `_ Crane/Boom Truck`, colSpan: 5, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontStyle: craneBoomTruckCheckBoxList ? 'bold' : 'normal', fontSize: 7 } }, { content: towerginPoleCheckBoxList ? `× GinPole` : `_ GinPole`, colSpan: 3, rowSpan: 1, styles: { halign: 'left', lineColor: 'white', fontStyle: towerginPoleCheckBoxList ? 'bold' : 'normal', fontSize: 7 } }],
      [{ content: suspenedPersonnelPlatformChechBoxList ? '3. Will operator be required to exit manlift while working at heights? If yes, please include MoP:Yes' : '3. Will operator be required to exit manlift while working at heights? If yes, please include MoP: ', colSpan: 18, rowSpan: 1, styles: { halign: 'left', fontSize: 8 } }],
      [{ content: 'All Personnel Reviews and Signatures', colSpan: 18, rowSpan: 1, styles: { halign: 'center', fillColor: '#000206', textColor: 'white', fontStyle: 'bold', fontSize: 7.5 }, }],
      [{ content: data.chosenCrewMembers[0].joined === true ? data.chosenCrewMembers[0].name : ' ', colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[0].joined === true ? ' Digitally Signed ' : ' ', colSpan: 5, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[4].joined === true ?  data.chosenCrewMembers[4].name : ' ', colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[4].joined === true ? ' Digitally Signed ' : ' ', colSpan: 5, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } },],
      [{ content: data.chosenCrewMembers[1].joined === true ? data.chosenCrewMembers[1].name : ' ', colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[1].joined === true ? ' Digitally Signed ' : ' ', colSpan: 5, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[5].joined === true ? data.chosenCrewMembers[5].name : ' ', colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[5].joined === true ? ' Digitally Signed ' : ' ', colSpan: 5, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } },],
      [{ content: data.chosenCrewMembers[2].joined === true ? data.chosenCrewMembers[2].name : ' ', colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[2].joined === true ? ' Digitally Signed ' : ' ', colSpan: 5, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[6].joined === true ?  data.chosenCrewMembers[6].name : ' ', colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[6].joined === true ? ' Digitally Signed ' : ' ', colSpan: 5, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } },],
      [{ content: data.chosenCrewMembers[3].joined === true ? data.chosenCrewMembers[3].name : ' ', colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: data.chosenCrewMembers[3].joined === true ? ' Digitally Signed ' : ' ', colSpan: 5, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content: creatorName, colSpan: 4, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } }, { content:  ' Digitally Signed ', colSpan: 5, rowSpan: 1, styles: { halign: 'center', fontSize: 7 } },],

    ],



  })




  const pdfDataUrl = doc.output('datauristring');
  async function savePdfToDatabase(pdfDataUrl) {
    try {
      // Save the PDF data URL to your database
      // Replace 'yourDatabaseFunction' with the actual function to save data in your database
      //   await yourDatabaseFunction('pdfs', { pdfData: pdfDataUrl });

    } catch (error) {
      console.error('Error saving PDF to database:', error);
    }
  }
  return pdfDataUrl
  // Call the function to save the PDF to your database
  savePdfToDatabase();


}


export default createNewJsa;