import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { QUERY_USERS_JSA_FILES } from "../utils/queries";
import styles from "../style";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import LoaderModal from "./LoaderModal";

const SearchOldJsa = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const { loading, data, refetch } = useQuery(QUERY_USERS_JSA_FILES, {
    variables: { email: "email." },
  });
  const index_of_last_post = currentPage * postsPerPage;
  const index_of_first_post = index_of_last_post - postsPerPage;
  const currentPost = posts.slice(index_of_first_post, index_of_last_post);
  useEffect(() => {
    refetch();
  });
  useEffect(() => {
    if (data) {
      setPosts(data.jsaFilesOfUser.JsaFile.slice().reverse());
    }
  }, [data]);

  // useEffect(() => {
  //     const index_of_last_post = currentPage * postsPerPage;
  //     const index_of_first_post = index_of_last_post - postsPerPage;
  //     const currentPost = posts.slice(index_of_first_post, index_of_last_post)
  //     // console.log(currentPost, 'currentpage');
  // }, [posts])

  //change page
  const paginate = (pageNumber) => {
    if (pageNumber === 0) {
      return;
    }
    window.scrollTo(0, 0);
    setCurrentPage(pageNumber);
  };

  return (
    <section
      className={` ${styles.boxWidth} w-[85%] mt-5  flex flex-col  h-full   mx-auto `}
    >
      {!data ? (
        <LoaderModal />
      ) : (
        <>
          <div className="h-[868px] ">
            <p className="text-xs ml-4  leading-6">{/* recents */}</p>
            <ul className="overflow-hidden px-4 rounded-lg grid gap-2 pb-5 text-center">
              {currentPost.map((item, i) => {
                return (
                  <Link key={item._id} to={`/Jsa/${item._id}`}>
                    <li className="text-start cursor-pointer bg-zinc-950 border-[3px] border-black shadow-[#080808] py-1 px-4 shadow rounded-md flex">
                      <div className="flex flex-col col-span-2 md:col-span-1">
                        <h1 className="text-xs justify-end text-start font-bold">
                          {item.jobNumber}
                        </h1>
                        <p className="text-[10px] text-orange-500 truncate font-extralight leading-5">
                          {item.physicalAddress}
                        </p>
                        <p className="text-[10px] leading-5 text-blue-500 font-bold ">
                          created on: {item.creactionDate}
                        </p>
                      </div>
                    </li>
                  </Link>
                );
              })}
            </ul>
          </div>
        </>
      )}
      {posts.length > 10 ? (
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={posts.length}
          paginate={paginate}
        />
      ) : (
        <></>
      )}
    </section>
  );
};

export default SearchOldJsa;
