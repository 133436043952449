import React, { useDeferredValue, useEffect, useState } from "react";
import { BiArrowFromLeft, BiArrowFromRight, BiCircle } from "react-icons/bi";
import { FaSquarePlus } from "react-icons/fa6";
import { TiArrowLeftThick, TiArrowRightThick } from "react-icons/ti";
import { UPLOAD_IMG } from "../utils/mutations";
import { useMutation } from "@apollo/client";

const ImageSlider = ({ refetchPdf, type, imageUrls, jsaPageId }) => {
  const [url, setUrl] = useState("");
  const [image, setImage] = useState("");
  const [uploadImg, { loading, error }] = useMutation(UPLOAD_IMG);
  const [imageIndex, setImageIndex] = useState(0);
  function showNextImage() {
    setImageIndex((index) => {
      if (index === imageUrls.length - 1) return 0;
      return index + 1;
    });
  }

  function showPrevImage() {
    setImageIndex((index) => {
      if (index === 0) return imageUrls.length - 1;
      return index - 1;
    });
  }

  useEffect(() => {
    if (image === "") {
      return;
    }

    uploadImage();
  }, [image]);
  const uploadImage = async () => {
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "aboveground");

    try {
      await fetch("https://api.cloudinary.com/v1_1/dsvedzvrk/image/upload", {
        method: "post",
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          setUrl(data.url);
        })
        .catch((errors) => console.log(errors));

      setImage("");
    } catch (error) {
      console.log("errors:", error);
      alert("img was unable to be saved");
    }
  };
  useEffect(() => {
    if (url === "") {
      return;
    }
    const database = async () => {
      try {
        const { data } = await uploadImg({
          variables: { jsaPageId: jsaPageId, imgUrl: url, typePhotos: type },
        });
        refetchPdf();
        alert("img has been saved");
        setUrl("");
      } catch (error) {
        alert("error");
        console.log(error);
      }
    };
    database();
  }, [url]);

  return (
    <>
      <section className={`py-5 px-5 `}>
        <div className="mb-6 ">
          <h5 className="pl-1 text-[12px] font-semibold leading-3">
            {" "}
            {type} Photos{" "}
          </h5>

          <div className="border-[1px] my-2 mb-4 w-0%] mx-auto border-white/10">
            {" "}
          </div>

          <div className="max-h-[400px] max-w-[300px] mx-auto w-full mb-1 h-[400px] aspect-square relative">
            <div className=" w-full h-full flex overflow-hidden">
              {imageUrls.map((url) => (
                <img
                  alt="img of work"
                  key={url}
                  src={url}
                  className="object-cover rounded-md  w-full h-full block trans flex-shrink-0 flex-grow-0"
                  style={{
                    translate: `${-100 * imageIndex}%`,
                    transition: `translate 400ms ease-in-out`,
                  }}
                />
              ))}
            </div>
            <div className="object-cover  absolute rounded-md end-0  top-0 bottom-0 p-[1rem] ">
              <p className="bg-black/70 text-xs p-1  text-white/95 rounded-xl px-2">
                {imageIndex + 1}/{imageUrls.length}
              </p>
            </div>

            <button
              onClick={showPrevImage}
              className=" block absolute  foc  top-0 bottom-0 p-[1rem] cursor-pointer left-0 "
            >
              <TiArrowLeftThick className="text-white/50   " />
            </button>
            <button
              onClick={showNextImage}
              className=" block  absolute top-0 bottom-0 p-[1rem] cursor-pointer right-0 "
            >
              <TiArrowRightThick className="text-white/50" />
            </button>
          </div>
          <div className="  mb-3 w-full  justify-center gap-2 flex ">
            {imageUrls.map((_, index) => (
              <button
                key={index}
                onClick={() => setImageIndex(index)}
                className={`h-[9px] ${
                  index === imageIndex ? "bg-blue-600" : "bg-slate-800"
                } hover:scale-[1.2] focus:scale-[1.2] w-[9px] rounded-full mt-2`}
                style={{ transition: `scale 100ms ease-in-out` }}
              ></button>
            ))}
          </div>
          <div className="border-[1px] mt-2 w-0%] mx-auto border-white/10">
            {" "}
          </div>

          <label
            htmlFor={type}
            className="text-center mb-4 hover:to-blue-400 text-xs center my-2 font-semibold  bg-blue-600 px-2 w-full flex justify-center py-2 rounded"
          >
            <FaSquarePlus className="mt-[1px] mr-1" />
            Upload {type} Photos
          </label>
          <input
            type="file"
            name="image"
            id={type}
            key={type}
            style={{ display: "none" }}
            onChange={(e) => setImage(e.target.files[0])}
            accept=".png, .jpg, .jpeg"
          />
        </div>
      </section>
    </>
  );
};

export default ImageSlider;
