import { gql } from "@apollo/client";


export const QUERY_USER = gql`
query oneUser($email: String!){
    oneUser(email:$email){
        email
        username
        company
        cprFirstAid
    certifiedClimber

    }
}

`
export const QUERY_USER_COMPANIES = gql`
query userCompanies {
  userCompanies {
    id
    creator {
      username
    }
    companyName
    JsaFile {
      creactionDate
      jobNumber
      _id
      physicalAddress
    }

  }
}
`
export const QUERY_FOR_PROFILE = gql`
query ForProfile ($email:String){
  forProfile(email:$email){
    username
    email
    profilePicture
    company
    cprFirstAid
    certifiedClimber
    friends{
      username
      email
      profilePicture
    }

  }
}
`


export const QUERY_SEARCH_USER = gql`
query SearchForUser($searchInput: String!) {
  searchForUser(searchInput: $searchInput) {
    username
    email
    profilePicture
  }
}
  `


export const QUERY_JSA_PDFDATAURL = gql`
query ShowEmbedJsa($jsaPageId: ID) {
  showEmbedJsa(jsaPageId: $jsaPageId) {
    prePhotos
    postPhotos
    chosenCrewMembers {
      name
      crewEmail
      joined
      company
      cprFirstAid
    certifiedClimber
    dateJoined
    
dateOut
    }
    JobProgress{
      task
      progress
    }
    createdAt
    creatorClimber
    creatorID
    creatorFirstAid
    hospitalAddress
    _id
    pdfDataUrl
    jobName
    creatorName
    jobNumber
    physicalAddress
    longitude
    latitude
    supervisor
    tagLinesCheckBoxList
    suspenedPersonnelPlatformChechBoxList
    fire
    police
    vehicleTrafficCheckBoxList
    ambulance
    yellowCautionFirstMpeReading
    yellowCautionSecondMpeReading
    yellowCautionSignCheckBox
    localHospital
    telephoneCo
    utilityCo
    evacuationPoint
    firstAidKitCheckBoxList
    towerRescueOtherCheckBox
    towerErectionCheckboxList
    lineAtennasCheckBoxList
    civilConcreteCheckBoxList
    jobTasks
    potentialHazards
    preventiveMeasures
    fallsFromElevationsCheckBoxList
    fallsFromElevationsCheckedBoxList
    slipsTripsOrFallsCheckBoxList
    undergroundUtilitiesCheckBoxList
    elevationSiteTerrainEnivromentalConcersHazards
    heatStressCheckBoxList
    coldStressCheckBoxList
    highNoise85DBaCheckBoxList
    chemicalExposureCheckBoxList
    liftingHazards
    liftingHazardsCheckBoxList
    emeAndRfCheckBoxList
    silicaExposureCheckBox
    healthHazardsOther
    safetyGlassesCheckBoxList
    riggingCheckBoxList
    hardHatCheckBoxList
    hazardsWithSiteVehicleStorage
    hearingCheckBoxList
    glovesCheckBoxList
    rfSuitsCheckBoxList
    rfmonitorsCheckBoxList
    toolsEquipmentCheckBoxList
    housekeepingCheckBoxList
    towerginPoleCheckBoxList
    groundFaultProtectionCheckBoxList
    inspectedFallProtectionEquipmentCheckBoxList
    hoistsCheckBoxList
    otherHealthHazards
    otherJobSafetyCheckboxList
    otherPhysicalHazards
    tailgateMeetingCheckBoxList
    lockoutTagoutCheckBoxList
    permitSystemCheckBoxList
    fourInOneSignCheckBoxList
    do911SystemsWorkCheckBoxList
    describeTypeElectricalCocernsOrHazards
    describeTypeDepthExcavation
    excavationLogCheckBoxList
    slopingCheckBoxList
    benchingCheckBoxList
    shoring
    trenchCheckboxList
    shieldBoxCheckBoxList
    laddertrench4FeetCheckBoxList
    typeAntennaBoom
    elericalCheckBoxList
    elevationSiteTerrainCheckBoxList
    electricalHazardsCheckBoxList
    typeTowerOrRoofTop
    fullBodyHarnessCheckBoxList
    doubleLegTwoLanyardsCheckBoxList
    elecricalCheckBoxList
    ginPolesCheckBoxList
    cableGrabCheckBoxList
    retractableLifeLineChecBoxList
    anchorageStrapsCheckBoxList
    ropeGrabCheckBoxList
    shoringCheckBoxList
    fallProtectionRopeCheckBoxList
    describeFallProtectionSystemUsedAccessAntenna
    craneBoomTruckCheckBoxList
    blueNoticeFirstMpeReading
    blueNoticeSecondMpeReading
    blueNoticeSignCheckBoxList
    exitManLiftMoPCheckBoxList
    redDangerSignCheckBoxList
    redWarningFirstMpeReading
    redWarningSecondMpeReading
    redWarningSignCheckBoxList
    rescueBagTowerCheckBoxList
    fallProtectionCheckBoxList
    descendersCheckBoxList
    date
  }

}`

export const QUERY_JSA_FOR_UPDATE = gql`
query ShowJsaForUpdate($jsaPageId: ID) {
  showJsaForUpdate(jsaPageId: $jsaPageId) {
    chosenCrewMembers {
      name
      crewEmail
      joined
      company
      cprFirstAid
    certifiedClimber
    dateOut
dateJoined
    }
    company
    creatorClimber
    creatorID
    creatorFirstAid
    hospitalAddress
    _id
    pdfDataUrl
    jobName
    creatorName
    jobNumber
    physicalAddress
    longitude
    latitude
    supervisor
    tagLinesCheckBoxList
    suspenedPersonnelPlatformChechBoxList
    fire
    police
    vehicleTrafficCheckBoxList
    ambulance
    yellowCautionFirstMpeReading
    yellowCautionSecondMpeReading
    yellowCautionSignCheckBox
    localHospital
    telephoneCo
    utilityCo
    evacuationPoint
    firstAidKitCheckBoxList
    towerRescueOtherCheckBox
    towerErectionCheckboxList
    lineAtennasCheckBoxList
    civilConcreteCheckBoxList
    jobTasks
    potentialHazards
    preventiveMeasures
    fallsFromElevationsCheckBoxList
    fallsFromElevationsCheckedBoxList
    slipsTripsOrFallsCheckBoxList
    undergroundUtilitiesCheckBoxList
    elevationSiteTerrainEnivromentalConcersHazards
    heatStressCheckBoxList
    coldStressCheckBoxList
    highNoise85DBaCheckBoxList
    chemicalExposureCheckBoxList
    liftingHazards
    liftingHazardsCheckBoxList
    emeAndRfCheckBoxList
    silicaExposureCheckBox
    healthHazardsOther
    safetyGlassesCheckBoxList
    riggingCheckBoxList
    hardHatCheckBoxList
    hazardsWithSiteVehicleStorage
    hearingCheckBoxList
    glovesCheckBoxList
    rfSuitsCheckBoxList
    rfmonitorsCheckBoxList
    toolsEquipmentCheckBoxList
    housekeepingCheckBoxList
    towerginPoleCheckBoxList
    groundFaultProtectionCheckBoxList
    inspectedFallProtectionEquipmentCheckBoxList
    hoistsCheckBoxList
    otherHealthHazards
    otherJobSafetyCheckboxList
    otherPhysicalHazards
    tailgateMeetingCheckBoxList
    lockoutTagoutCheckBoxList
    permitSystemCheckBoxList
    fourInOneSignCheckBoxList
    do911SystemsWorkCheckBoxList
    describeTypeElectricalCocernsOrHazards
    describeTypeDepthExcavation
    excavationLogCheckBoxList
    slopingCheckBoxList
    benchingCheckBoxList
    shoring
    trenchCheckboxList
    shieldBoxCheckBoxList
    laddertrench4FeetCheckBoxList
    typeAntennaBoom
    elericalCheckBoxList
    elevationSiteTerrainCheckBoxList
    electricalHazardsCheckBoxList
    typeTowerOrRoofTop
    fullBodyHarnessCheckBoxList
    doubleLegTwoLanyardsCheckBoxList
    elecricalCheckBoxList
    ginPolesCheckBoxList
    cableGrabCheckBoxList
    retractableLifeLineChecBoxList
    anchorageStrapsCheckBoxList
    ropeGrabCheckBoxList
    shoringCheckBoxList
    fallProtectionRopeCheckBoxList
    describeFallProtectionSystemUsedAccessAntenna
    craneBoomTruckCheckBoxList
    blueNoticeFirstMpeReading
    blueNoticeSecondMpeReading
    blueNoticeSignCheckBoxList
    exitManLiftMoPCheckBoxList
    redDangerSignCheckBoxList
    redWarningFirstMpeReading
    redWarningSecondMpeReading
    redWarningSignCheckBoxList
    rescueBagTowerCheckBoxList
    fallProtectionCheckBoxList
    descendersCheckBoxList
    date
  }
}
 `

export const QUERY_BELONGS_USER = gql`
 query belongstoUser( $email:ID){
  belongstoUser(email:$email){
    email
  }
 }
 `


export const QUERY_USER_FRIENDS = gql`
query oneUser($email: String!) {
    oneUser(email: $email) {
    username
    _id
    company
   cprFirstAid,
   certifiedClimber,
    friends {
      _id
      cprFirstAid,
      certifiedClimber,
      username
      email
      profilePicture
      company
    }

  }
}
`

export const QUERY_SIGNED_JSA = gql`
query querysignedJSA($email:String){
  querysignedJSA(email:$email){
    signedJSA{
      _id
      jobName
date    
hospitalAddress
chosenCrewMembers{
        
        crewEmail
        joined
      }
    }
  }
}

`

export const QUERY_ALL_INDIVIDUAL_JSA_OF_FILE = gql`
query IndiviualJsasOfUser($jsaFolderId: ID) {
  indiviualJsasOfUser(jsaFolderId: $jsaFolderId) {
    creactionDate
    _id
    jobNumber
    physicalAddress
    pages {
      hospitalAddress
      _id
      date
      creatorName
      chosenCrewMembers{
        
        crewEmail
        joined
      }
    }
  }
}
`

export const QUERY_ALL_USERS_SEARCH = gql`
query User {
  user {
    username
    email
  }
} 

`

export const QUERY_USERS_JSA_FILES = gql`
query JsaFilesOfUser($email: String) {
  jsaFilesOfUser(email: $email) {
    _id

    JsaFile {
      creactionDate
      jobNumber
      _id
      physicalAddress
    }
  }
}

`
export const QUERY_COMPANY_JSA_FILES = gql`
query jsaFielsOfCompany($ID: String) {
  jsaFielsOfCompany(ID: $ID) {
    id
    companyName
users{
  _id
  username
email
profilePicture
}
    JsaFile {
      creactionDate
      jobNumber
      _id
      physicalAddress
    }
  }
}

`