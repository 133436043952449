import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_JSA_FOR_UPDATE, QUERY_USER } from "../utils/queries";
import { useState } from "react";
import createNewJsa from "../utils/createPDF";
import { UPDATE_JSA_1 } from "../utils/mutations";
import ErrorPage from "../pages/ErrorPage";
import verifySVG from "../assets/signature-doc-svgrepo-com.svg";
import Auth from "../utils/auth";

const JsaEmailVerify = () => {
  const [crewMembers, setCrewMembers] = useState([
    { name: " ", crewEmail: " ", joined: false, company: "" },
    { name: " ", crewEmail: " ", joined: false, company: "" },
    { name: " ", crewEmail: " ", joined: false, company: "" },
    { name: " ", crewEmail: " ", joined: false, company: "" },
    { name: " ", crewEmail: " ", joined: false, company: "" },
    { name: " ", crewEmail: " ", joined: false, company: "" },
    { name: " ", crewEmail: " ", joined: false, company: "" },
    { name: " ", crewEmail: " ", joined: false, company: "" },
    { name: " ", crewEmail: " ", joined: false, company: "" },
  ]);
  const [updatedCrewMembers, setUpdatedCrewMembers] = useState([]);
  const [jsaData, setjsaData] = useState({
    creatorName: " ",
    company: " ",
    creatorID: " ",
    creatorFirstAid: false,
    creatorClimber: false,
    jobName: " .",
    jobNumber: " .",
    physicalAddress: " .",
    date: " .",
    longitude: " .",
    supervisor: " .",
    latitude: " .",
    ambulance: " .",
    fire: " .",
    police: " .",
    localHospital: " .",
    telephoneCo: " .",
    utilityCo: " .",
    hospitalAddress: "",
    evacuationPoint: " .",
    jobTasks: " .",
    potentialHazards: " .",
    preventiveMeasures: " .",
    fallsFromElevationsCheckBoxList: false,
    firstAidKitCheckBoxList: false,
    rescueBagTowerCheckBoxList: false,
    towerErectionCheckboxList: false,
    elericalCheckBoxList: false,
    civilConcreteCheckBoxList: false,
    lineAtennasCheckBoxList: false,
    blueNoticeSignCheckBoxList: false,
    yellowCautionSignCheckBox: false,
    redWarningSignCheckBoxList: false,
    redDangerSignCheckBoxList: false,
    electricalHazardsCheckBoxList: false,
    silicaExposureCheckBox: false,
    coldStressCheckBoxList: false,
    chemicalExposure: false,
    liftingHazards: false,
    hardHatCheckBoxList: false,
    hearingCheckBoxList: false,
    glovesCheckBoxList: false,
    rfmonitorsCheckBoxList: false,
    tailgateMeetingCheckBoxList: false,
    lockoutTagoutCheckBoxList: false,
    fourInOneSignCheckBoxList: false,
    excavationLogCheckBoxList: false,
    heatStressCheckBoxList: false,
    elecricalCheckBoxList: false,
    LineAntennasCheckBoxList: false,
    describeTypeDepthExcavation: " .",
    slopingCheckBoxList: false,
    benchingCheckBoxList: false,
    shoringCheckBoxList: false,
    trenchCheckboxList: false,
    shieldBoxCheckBoxList: false,
    laddertrench4FeetCheckBoxList: false,
    elevationSiteTerrainEnivromentalConcersHazards: " .",
    hazardsWithSiteVehicleStorage: " .",
    describeTypeElectricalCocernsOrHazards: " .",
    typeTowerOrRoofTop: " .",
    typeAntennaBoom: " .",
    exitManLiftMoPCheckBoxList: false,
    fullBodyHarnessCheckBoxList: false,
    ropeGrabCheckBoxList: false,
    cableGrabCheckBoxList: false,
    doubleLegTwoLanyardsCheckBoxList: false,
    retractableLifeLineChecBoxList: false,
    anchorageStrapsCheckBoxList: false,
    fallProtectionRopeCheckBoxList: false,
    inspectedFallProtectionEquipmentCheckBoxList: false,
    describeFallProtectionSystemUsedAccessAntenna: " .",
    craneBoomTruckCheckBoxList: false,
    otherJobSafetyCheckboxList: " .",
    towerRescueOtherCheckBox: " .",
    vehicleTrafficCheckBoxList: false,
    suspenedPersonnelPlatformChechBoxList: false,
    undergroundUtilitiesCheckBoxList: false,
    highNoise85DBaCheckBoxList: false,
    slipsTripsOrFallsCheckBoxList: false,
    emeAndRfCheckBoxList: false,
    chemicalExposureCheckBoxList: false,
    liftingHazardsCheckBoxList: false,
    safetyGlassesCheckBoxList: false,
    rfSuitsCheckBoxList: false,
    toolsEquipmentCheckBoxList: false,
    housekeepingCheckBoxList: false,
    ginPolesCheckBoxList: false,
    riggingCheckBoxList: false,
    do911SystemsWorkCheckBoxList: false,
    tagLinesCheckBoxList: false,
    groundFaultProtectionCheckBoxList: false,
    hoistsCheckBoxList: false,
    towerginPoleCheckBoxList: false,
    fallProtectionCheckBoxList: false,
    descendersCheckBoxList: false,
    fallProtectionRopeCheckBoxList: false,
    permitSystemCheckBoxList: false,
    fallsFromElevationsCheckedBoxList: false,
    elevationSiteTerrainCheckBoxList: false,
    //mpe readings
    blueNoticeFirstMpeReading: " .",
    blueNoticeSecondMpeReading: " .",
    yellowCautionFirstMpeReading: " .",
    yellowCautionSecondMpeReading: " .",
    redWarningFirstMpeReading: " .",
    redWarningSecondMpeReading: " .",
    //others
    otherPhysicalHazards: " .",
    otherHealthHazards: " .",
  });
  const [background, setBackGround] = useState(
    " bg-gradient-to-b from-[#000000a9]   via-[#0d2043c8]  to-[#000206] "
  );
  const id = window.location.pathname.split("verify/").pop();
  const [createJsa, { error }] = useMutation(UPDATE_JSA_1);
  const [isTrueForError, SetIsTrueForError] = useState(false);

  const {
    loading: userLoading,
    data: userData,
    refetch,
  } = useQuery(QUERY_USER, {
    variables: { email: "email." },
  });
  const {
    loading,
    data,
    refetch: refetchJsa,
  } = useQuery(QUERY_JSA_FOR_UPDATE, {
    variables: { jsaPageId: id },
  });
  const handleError = () => {
    SetIsTrueForError(true);
    setTimeout(() => {
      SetIsTrueForError(false);
      setBackGround(
        "bg-gradient-to-b from-[#000000a9]   via-[#0d2043c8]  to-[#000206]"
      );
    }, 5000);
  };

  // need a function that waits for click
  useEffect(() => {
    if (data === null || data === undefined) {
      // Use the data from the query directly
      return;
    }
    if (data.showJsaForUpdate === null || data.showJsaForUpdate === undefined) {
      // Use the data from the query directly
      return;
    }
    setjsaData(data.showJsaForUpdate);
    setCrewMembers(data.showJsaForUpdate.chosenCrewMembers);
  }, [loading, data]);

  const handleCLick = async (e) => {
    if (!crewMembers) {
      return;
    }
    const updatedCrew = jsaData.chosenCrewMembers.map((member) =>
      member.crewEmail === userData.oneUser.email
        ? {
            ...member,
            joined: true,
            company: userData.oneUser.company,
            name: userData.oneUser.username,
            cprFirstAid: userData.oneUser.cprFirstAid,
            dateJoined: new Date().toLocaleString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "2-digit",
              hour: "numeric",
              minute: "2-digit",
              hour12: true,
            }),
            certifiedClimber: userData.oneUser.certifiedClimber,
          }
        : member
    );

    const matchingMember = jsaData.chosenCrewMembers.find(
      (member) => member.crewEmail === userData.oneUser.email
    );
    if (matchingMember) {
      refetchJsa();
      setBackGround(
        "bg-gradient-to-b from-[#000000a9]   via-[#2A914E]  to-[#000206]"
      );
    } else {
      handleError();
      return;
    }
    // Update the state
    setUpdatedCrewMembers(updatedCrew);
    localStorage.setItem("isActiveJSA", id);
    refetch();
    // Now use the updated jsaData within a useEffect
  };
  useEffect(() => {
    if (updatedCrewMembers.length === 0) {
      return;
    }

    const JSA = { ...jsaData, chosenCrewMembers: updatedCrewMembers };
    createNewJsa(JSA)
      .then(async (response) => {
        const pdfDataUrl = response;
        try {
          const JSA = { chosenCrewMembers: updatedCrewMembers };
          const usersWithoutAge = updatedCrewMembers.map(
            ({ __typename, ...rest }) => rest
          );
          const { data } = await createJsa({
            variables: {
              updatedpdfDataUrl: response,
              updatedCrewMembers: usersWithoutAge,
              jsaPageId: id,
            },
          });
        } catch (error) {
          console.log(error, "");
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  }, [updatedCrewMembers]);
  // clicked data gets changed just crewmembers
  // regenerate a pdf with url and save upadte and save to mongo db
  const onButtonClick = () => {
    // using Java Script method to get PDF file

    // Setting various property values
    let alink = document.createElement("a");
    alink.href = data.showJsaForUpdate.pdfDataUrl;
    alink.download = "SamplePDF.pdf";
    alink.click();
  };

  // make sure user has that jsa ref so they can i see it
  return (
    <>
      {!Auth.loggedIn() ? (
        <ErrorPage />
      ) : (
        <section className={`${background}`}>
          <div
            className={`text-white px-6 py-1 border-0 rounded-b-sm  mb-3 text-center bg-red-700 shadow-red-950 shadow-xl  ${
              !isTrueForError ? "invisible" : "block"
            }`}
          >
            <span className="text-lg inline-block ">
              <i className="fas fa-bell" />
            </span>
            <span className="inline-block  text-sm">
              <p className=" text-lg  inline-flex"></p> Unable To Join JSA.
            </span>
            <button className="absolute  bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"></button>
          </div>
          <div className="flex  min-h-screen flex-1 flex-col  pt-32 px-6  lg:px-8">
            <div className="sm:mx-auto sm:w-full justify-center sm:max-w-sm">
              <img
                className="mx-auto h-[65px] w-auto"
                src={verifySVG}
                alt="verify icon"
              />
              <h2 className=" mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-50">
                Join JSA With One Click!
              </h2>
            </div>
            <div className="justify-center mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
              <div>
                <p className="text-[10px] pl-2 mb-[-3px] text-gray-300">
                  If you have an account please make sure you are signed in.
                </p>

                {data === null || data === undefined ? (
                  <>yo</>
                ) : (
                  <>
                    <div className=" grid">
                      <a
                        href={data.showJsaForUpdate.pdfDataUrl}
                        target="_blank"
                        className={` rounded-md text-center py-2 px-4  mb-4  border-2  border-gray-800 border-[bg-orange-600 ]text-[18px]  font-bold bg-[#00000034] hover:bg-white/5  shadow-xl text-gray-300`}
                      >
                        Open PDF in New Tab
                      </a>
                    </div>
                  </>
                )}
                <p className="text-[10px] pl-2 text-gray-300 mb-[-3px]">
                  Click Join here to e sign the pdf.
                </p>

                <button
                  onClick={() => handleCLick()}
                  className="flex mb-2 w-full mx-auto  justify-center rounded-md px-8 py-1.5 text-xs font-semibold leading-6 text-white shadow-sm bg-orange-600 hover:bg-orange-500 focus-visible:outline "
                >
                  {" "}
                  Join Here
                </button>
              </div>

              <div>
                <button
                  onClick={onButtonClick}
                  className="sm:flex w-full mx-auto mt-5 hidden   justify-center rounded-md px-8 py-1.5 text-xs font-semibold leading-6 text-white shadow-sm bg-blue-600 hover:bg-orange-500 focus-visible:outline "
                >
                  {" "}
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default JsaEmailVerify;
