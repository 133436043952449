import React from "react";
import styles from "../style";

const UserCerts = () => {
  return (
    <section
      className={`bg-[#000206] min-h-screen ${styles.paddingX}  ${styles.flexStart} `}
    >
      <section className={`   ${styles.boxWidth}  `}>
        <div className="text-center py-10 md:py-20">
          <h5 className="pl-1 text-lg md:text-2xl font-semibold leading-3">
            {" "}
            Matthew Garcia{" "}
          </h5>
          <p className=" font-semibold text-[12px] md:text-md  text-yellow-400">
            Certifications{" "}
          </p>
        </div>
        <h5 className="pl-1 text-[12px] pt-5 font-semibold leading-3">
          {" "}
          Crane Spotter/Signal Person{" "}
        </h5>
        <div className="border-[1px] my-2 mb-4 w-0%] mx-auto border-white/10">
          {" "}
        </div>
        <div className="max-h-[300px] max-w-[400px] md:max-h-[400px] md:max-w-[500px] max mx-auto w-full mb-1 h-[400px] aspect-square relative">
          <a
            target="_blank"
            href="https://safetylms.com/home/verify?cert_number=B1023-87165"
          >
            <img
              className="object-cover rounded-md  w-full h-full block trans flex-shrink-0 flex-grow-0"
              src="https://res.cloudinary.com/dsvedzvrk/image/upload/v1702596522/certs/SafetyLMSCert_kw7s4h.jpg"
            />
          </a>
        </div>

        <h5 className="pl-1 text-[12px] pt-5 font-semibold leading-3">
          {" "}
          First Aid CPR AED <span className=" text-md text-red-500">
            +
          </span>{" "}
        </h5>
        <div className="border-[1px] my-2 mb-4 w-0%] mx-auto border-white/10">
          {" "}
        </div>
        <div className="max-h-[300px] max-w-[400px] md:max-h-[400px] md:max-w-[500px] max mx-auto w-full mb-1 h-[400px] aspect-square relative">
          <div className=" w-full h-full flex overflow-hidden">
            <a
              target="_blank"
              href="https://ecards.heart.org/student/eCards?cid=8AB242C8-3A68-4FCC-9A16-A5976BAD8888"
            >
              <img
                className="object-cover rounded-md  w-full h-full block trans flex-shrink-0 flex-grow-0"
                src="https://res.cloudinary.com/dsvedzvrk/image/upload/v1702598497/certs/Capture_ezx3u4.jpg"
              />
            </a>
          </div>
        </div>

        <h5 className="pl-1 text-[12px] pt-5 font-semibold leading-3">
          {" "}
          OSHA 30{" "}
        </h5>
        <div className="border-[1px] my-2 mb-4 w-0%] mx-auto border-white/10">
          {" "}
        </div>
        <div className="max-h-[300px] max-w-[400px] md:max-h-[400px] md:max-w-[500px] max mx-auto w-full mb-1 h-[400px] aspect-square relative">
          <a
            target="_blank"
            href="https://lms.360training.com/lms/rest/certificate/19636485/osha_30_hour_construction_industry_outreach.pdf?adminSelectedEntityType=NONE&adminCurrentMode=ROLE_LEARNER&brand=DEFAULT&userAgent=Mozilla%2F5.0+%28Windows+NT+10.0%3B+Win64%3B+x64%29+AppleWebKit%2F537.36+%28KHTML%2C+like+Gecko%29+Chrome%2F122.0.0.0+Safari%2F537.36&currentView=redirect%3A%2Frest%2Fcertificate%2F19636485%2Fosha_30_hour_construction_industry_outreach.pdf&locale=en_US&isStoreUser=false"
          >
            <img
              className="object-cover rounded-md  w-full h-full block trans flex-shrink-0 flex-grow-0"
              src="https://res.cloudinary.com/dsvedzvrk/image/upload/v1702599145/certs/osha_iucjwm.jpg"
            />
          </a>
        </div>
      </section>
    </section>
  );
};
export default UserCerts;
