import React from "react";

import towerTech from "../../assets/towerTech.jpg";
import foreman from "../../assets/foreman.jpg";
import warehouseWorker from "../../assets/warehouseworker1.jpg";
import warehouseWorker1 from "../../assets/WorkerWarehouse.jpg";

import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";

const JobPositions = () => {
  return (
    <section className="grid grid-cols-2 mb-10 md:grid-cols-3 gap-7 text-gray-400 ">
      <Link to={'/Tech/JobDescription'} className=" cursor-pointer shadow-lg bg-[#0C2333] rounded-md ">
        <img
          className="w-full rounded-t-md "
          src={towerTech}
          alt="cell tower tech"
        />
        <div className="px-4  text-[10px] sm:text-[10px] sm:text-xs">
          <h1 className="font-semibold md:text-base  pt-6 h-9 leading-none  ">
            Cell Tower Technician level I, II
          </h1>
          <div className="flex items-center leading-8 gap-1">
            <FaLocationDot />
            <p className=" leading-none">So Ca.</p>
            <p className="bg-green-700 leading-none py-1 my-3 md:my-4 text-[11px] text-white font-medium px-2 ml-1 rounded-sm">
              Full Time
            </p>
          </div>
          <p className="leading-[17px] mb-3 ">
            The employee will install, troubleshoot, and repair cellular and
            microwave outside plant systems{" "}
            <span className="hidden sm:inline">
              to include coax, hybrid, waveguide, antennas, connectors,
              grounding, and supporting hardware
            </span>
            {/* The Telecommunications Technician reports to the Communications Supervisor and is responsible for the installation, modification, maintenance, and removal of cabling and cabling components */}
          </p>
        </div>
      </Link>
      <Link to={'/topHand/JobDescription'} className=" cursor-pointer  shadow-lg bg-[#0C2333] rounded-md ">
        <img className="w-full rounded-t-md " src={foreman} alt="tower tech" />
        <div className="px-4 text-[10px] sm:text-xs">
          <h1 className="font-semibold md:text-base  pt-6 h-9 leading-none ">
            Lead Cell Tower Technician
          </h1>
          <div className="flex items-center leading-8 gap-1">
            <FaLocationDot />
            <p className=" leading-none">So Ca.</p>
            <p className="bg-green-700 leading-none py-1 my-3 md:my-4 text-white font-medium px-2 ml-1 rounded-sm">
              Full Time
            </p>
          </div>
          <p className="leading-[17px] mb-3 ">
            Perform installation, inspection and maintenance services for
            wireless radio and antenna systems{" "}
            <span className="hidden sm:inline">
              on towers, rooftops and other elevated structures in conjunction
              with the Crew Supervisor
            </span>
          </p>
        </div>
      </Link>
      
    </section>
  );
};

export default JobPositions;
