import React from "react";
import { MdEmergency, MdOutlineAddToHomeScreen } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";

const IndiviualHomeJsa = ({ data }) => {
  return (
    <div className="px-4 py-5 ">
      <dt className="text-[10px] font-medium leading-3 ml-1 mb-1">
        View pdf or if on computer download here.
      </dt>
      <dd className=" text-sm  sm:col-span-2 ">
        <ul role="list" className="rounded-md  grid gap-2">
          <li className="md:flex hidden bg-[#0E0E0E] rounded-md shadow-[#151515] shadow items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
            <div className="flex w-0 flex-1 items-center">
              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                <span className="truncate font-medium">
                  {data?.showEmbedJsa.jobNumber || "loading"}.pdf
                </span>
                <span className="flex-shrink-0 text-gray-400">63.8.KB</span>
              </div>
            </div>
            <div className="ml-4 flex-shrink-0">
              <a
                href={data?.showEmbedJsa.pdfDataUrl || ""}
                target="_blank"
                download={`${data?.showEmbedJsa.jobNumber || "loading"}.pdf`}
                className="font-medium  text-blue-600 hover:text-blue-500"
              >
                Download
              </a>
            </div>
          </li>
          <li className="flex items-center rounded-md shadow-[#151515] shadow bg-[#0E0E0E] justify-between py-4 pl-4 pr-5 text-sm leading-6">
            <div className="flex w-0 flex-1 items-center">
              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                <span className="truncate font-medium ">
                  {" "}
                  View {data?.showEmbedJsa.jobNumber || "loading"}
                </span>
                <span className="flex-shrink-0 text-gray-400 pt-1">
                  <MdOutlineAddToHomeScreen />
                </span>
              </div>
            </div>
            <div className="ml-4 flex-shrink-0">
              <a
                href={data?.showEmbedJsa.pdfDataUrl || "loading"}
                target="_blank"
                className="font-medium text-blue-600  hover:text-blue-500"
              >
                View PDF
              </a>
            </div>
          </li>
        </ul>

        <ul role="list" className="rounded-md    mx-auto grid gap-4  ">
          <dt className="text-[10px] font-medium leading-3 mb-[-10px]  mt-6">
            Emergency Contacts - Use Responsibly
          </dt>
          <a
            href={`tel:${data?.showEmbedJsa.police}`}
            className="cursor-pointer  ring ring-sky-950/90  block rounded-lg shadow-[#151515] shadow bg-[#75AAE6] py-4 pl-4 pr-5 text-sm leading-6"
          >
            <div className="flex items-center justify-between">
              <div className="flex min-w-0 flex-1 gap-2 text-center justify-center">
                <span className="truncate font-semibold text-gray-950">
                  Call Police Department
                </span>
                <FaPhoneAlt
                  size={16}
                  className="nav-linker text-gray-900 pt-1"
                />
              </div>
            </div>
          </a>

          <a
            href={`tel:${data?.showEmbedJsa.fire}`}
            className="cursor-pointer ring ring-amber-900/80   flex  items-center rounded-lg shadow-[#151515] shadow bg-amber-500 justify-between py-4 pl-4 pr-5 text-sm leading-6"
          >
            <div className="flex w-0 flex-1 items-center">
              <div className="ml-4 flex min-w-0 flex-1 gap-2 text-center justify-center">
                <span className="truncate font-semibold text-gray-900">
                  Call Fire Station
                </span>
                <FaPhoneAlt
                  size={16}
                  className="nav-linker text-gray-900 pt-1"
                />
              </div>
            </div>
          </a>

          <a
            href={`tel:${data?.showEmbedJsa.localHospital}`}
            className="cursor-pointer block rounded-lg  ring ring-lime-700/80  shadow-[#151515] shadow bg-[#9AE3A9] py-4 pl-4 pr-5 text-sm leading-6"
          >
            <div className="flex items-center justify-between">
              <div className="flex min-w-0 flex-1 gap-2 text-center justify-center">
                <span className="truncate font-semibold text-gray-900">
                  Call Local Hospital
                </span>
                <FaPhoneAlt
                  size={16}
                  className="nav-linker text-gray-900 pt-1"
                />
              </div>
            </div>
          </a>
          <a
            href={`tel:911`}
            className="cursor-pointer ring ring-red-900/80 flex  items-center rounded-lg shadow-[#151515] shadow bg-[#FC2833] justify-between py-4 pl-4 pr-5 text-sm leading-6"
          >
            <div className="flex w-0 flex-1 items-center">
              <div className="ml-4 flex min-w-0 flex-1 gap-2 text-center justify-center">
                <MdEmergency
                  size={17}
                  className="nav-linker text-gray-1000 pt-1"
                />
                <span className="truncate font-semibold ">CALL 911</span>
                <MdEmergency
                  size={17}
                  className="nav-linker text-gray-1000 pt-1"
                />
              </div>
            </div>
          </a>

          <div className="flex gap-3">
            <a
              href={`https://maps.apple.com/?q=${data?.showEmbedJsa.hospitalAddress}`}
              className="cursor-pointer  bg-[#0D0F13]  ring-2 ring-[#27292c] w-fit block rounded-md  text-gray-200   hover:bg-[#060606b1] py-3 pl-4 pr-5 text-sm leading-6"
            >
              <div className="flex items-center justify-between">
                <div className="flex min-w-0 justify-start  text-sm flex-1 gap-2 text-center ] ">
                  <span className="truncate font-semibold ">
                    Directions to Local Hospital
                  </span>
                  <FaMapLocationDot
                    size={17}
                    className="nav-linker text-gray-1000 pt-1"
                  />
                </div>
              </div>
            </a>
          </div>
        </ul>
      </dd>
    </div>
  );
};

export default IndiviualHomeJsa;
