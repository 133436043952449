import React from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "../style";
import { FaSquarePlus, FaUsersBetweenLines } from "react-icons/fa6";
import { PiGearBold } from "react-icons/pi";
import { QUERY_COMPANY_JSA_FILES } from "../utils/queries";
import { useQuery } from "@apollo/client";

const OneCompanyPage = () => {
  const id = window.location.pathname.split("Company/").pop();
  const { loading, data } = useQuery(QUERY_COMPANY_JSA_FILES, {
    variables: { ID: id },
  });
  const returnPage = () => {
    window.history.back();
  };
  return (
    <section className={`   mx-auto flex min-h-screen flex-col`}>
      <div className="col-span-4 bg-[#000206]    flex items-center w-full">
        <Link onClick={returnPage} className="icon md:ml-5 xl:ml-32 ">
          <FaLongArrowAltLeft size={20} className="icon  pb-1 hover:h-[.1%]" />
        </Link>
        <h1 className="   w-full "> </h1>
        <Link to={`/EditCompany/${id}`} className="mr-5 xl:mr-[13%] sm:mr-16">
          <PiGearBold className="" size={20} />
        </Link>
      </div>

      <div className="bg-[#000206] shadow-md shadow-[#070b0f]">
        <div className={`${styles.paddingX} ${styles.boxWidth} mx-auto mt-2`}>
          <div className="col-span-full cursor-default ">
            <div className="mt-2 flex items-center gap-x-3 text-gray-100">
              <div className=" bg-blue-950 border-2 rounded-full w-12 h-11 font-bold text-2xl">
                <p className="text-center  my-1">
                  {data
                    ? data.jsaFielsOfCompany.companyName
                        .toUpperCase()
                        .split("")[0]
                    : "?"}
                </p>
              </div>

              <p className=" w-full font-bold border-b-2 cursor-default">
                {data ? data.jsaFielsOfCompany.companyName : "loading"}{" "}
              </p>
            </div>
            <Link to={`/QuestionsJSA?company=${id}`} className="">
              <button className="text-center text-xs center my-5 font-semibold  bg-blue-600 px-2 w-full flex justify-center py-2 rounded">
                <FaSquarePlus className="mt-[1px] mr-1" /> Create new Folder{" "}
              </button>
            </Link>
          </div>
          <p className="text-[8px] text-gray-100 leading-[10px] my-2 mt-1">
            {" "}
            Below, you'll discover a list of all previous JSAs that have been
            generated.
            <br /> Alternatively, if you wish to create a new JSA, simply click
            the inviting orange button.
          </p>
        </div>

        <></>
      </div>

      <div
        className={` ${styles.paddingX} ${styles.boxWidth} mx-auto min-h-screen mt-5  text-md leading-10 `}
      >
        <ul className="  pb-2 grid gap-2">
          {!data ? (
            "loading..."
          ) : (
            <>
              <div className="h-[868px] ">
                <ul className="overflow-hidden px-4 rounded-lg grid gap-2 pb-5 text-center">
                  {data.jsaFielsOfCompany.JsaFile.map((item, i) => {
                    return (
                      <Link key={item._id} to={`/Jsa/${item._id}`}>
                        <li className="text-start cursor-pointer bg-zinc-950 border-[3px] border-black shadow-[#080808] py-1 px-4 shadow rounded-md flex">
                          <div className="flex flex-col col-span-2 md:col-span-1">
                            <h1 className="text-xs justify-end text-start font-bold">
                              {item.jobNumber}
                            </h1>
                            <p className="text-[10px] text-orange-500 truncate font-extralight leading-5">
                              {item.physicalAddress}
                            </p>
                            <p className="text-[10px] leading-5 text-blue-500 font-bold ">
                              created on: {item.creactionDate}
                            </p>
                          </div>
                        </li>
                      </Link>
                    );
                  })}
                </ul>
              </div>
            </>
          )}
          {/* {data === false
                    ? 'Loading...'
                    : Jsas.map((item, i) => {

                        return (<>

                            <Link to={`/one/${item._id}`}>
                                <li number={i} name={item._id} className="flex px-2 rounded-md cursor-pointer bg-[#0E0E0E] justify-between gap-x-6 py-2">
                                    <div className="flex min-w-0 gap-x-4">
                                        <img className="h-12 w-12 flex-none rounded-md shadow-md shadow-black bg-gray-50" src={newjsa} alt="jsa icon" />
                                        <div className="min-w-0 w-36 cursor-default">
                                            <p className="text-sm font-semibold leading-6 text-gray-100">created:</p>
                                            <p className="mt-1 truncate text-xs leading-5 text-gray-300">{item.date}</p>
                                        </div>
                                    </div>
                                    <div className=" shrink-0 flex flex-col items-end">
                                        <p className="text-sm  leading-6 text-gray-400">team</p>

                                        <div className="flex items-center gap-x-1.5">

                                            <StackedListUsers crewMembers={item.chosenCrewMembers} key={i} />
                                        </div>

                                    </div>
                                </li>
                            </Link>

                        </>
                        );
                    })} */}
        </ul>
      </div>
    </section>
  );
};

export default OneCompanyPage;
