import React, { useState, useRef } from "react";
import styles from "../style";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const form = useRef();

  const [data, setData] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_6rk91zp",
        "template_qurxtgv",
        form.current,
        "X2DVkigrjxSH4fLGY"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section className={`  min-h-screen  bg-[#00040F]`}>
      <div className="contactBg   ">
        <div className="loginbg2 flex flex-col items-center justify-center h-[220px] sm:h-[350px] md:h-[400px] lg:h-[400px] xl:h-[450px]">
          <h1 className="text-white mb-3 font-semibold text-3xl md:text-4xl lg:text-6xl">
            Contact Us
          </h1>
        </div>
      </div>
      <h2 className="text-center my-14 text-2xl font-semibold">
        Let’s Start a Conversation{" "}
      </h2>
      <section className={`  mx-auto `}>
        <section className={` grid md:grid-cols-2 mx-auto w-[80%]  `}>
          <div>
            <div className=" sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-100">
                Ask how can we help you:
              </h3>
              <p className="mt-1 px-2 max-w-2xl text-[16px] leading-6 text-gray-300 font-extralight">
                Personal details and application.
              </p>
            </div>
            <div className="mt-6 ">
              <dl className="cursor-default">
                <div className="py-3">
                  <dt className="text-[16px] font-medium leading-6 text-gray-100">
                    Experience Seamless Connectivity
                  </dt>
                  <dd className="mt-1 px-2 text-[16px] leading-6 text-gray-300 font-extralight md:col-span-2 md:mt-0">
                    Request a personalized demo of our telecom services and see
                    how we can enhance your communication and connectivity
                    solutions.
                  </dd>
                </div>
                <div className="py-3">
                  <dt className="text-[16px] font-medium leading-6 text-gray-100">
                    Elevate Your Telecom Infrastructure
                  </dt>
                  <dd className="mt-1 px-2 text-[16px] leading-6 text-gray-300 font-extralight md:col-span-2 md:mt-0">
                    At Above Ground, we specialize in crafting and upgrading
                    telecom infrastructure to meet the ever-growing demands of a
                    connected world. Explore how we can transform your
                    communication and connectivity through our exceptional
                    solutions and insights.
                  </dd>
                </div>
                <div className="py-3">
                  <dt className="text-[16px] font-medium leading-6 text-gray-100">
                    {" "}
                    Billing Inquiries
                  </dt>
                  <dd className="mt-1 px-2 text-[16px] leading-6 text-gray-300 font-extralight md:col-span-2 md:mt-0">
                    (626)-922-3000
                  </dd>
                </div>
                <div className="py-3 ">
                  <dt className="text-[16px] font-medium leading-6 text-gray-100">
                    About
                  </dt>
                  <dd className="mt-1 text-[16px] leading-6 text-gray-300 font-extralight md:col-span-2 md:mt-0">
                    At Above Ground, we are dedicated to building and upgrading
                    cutting-edge telecom infrastructure to connect the world
                    seamlessly. Discover how we're reshaping the future of
                    communication and connectivity.
                  </dd>
                </div>
                {/* <div className="px-4 py-6 md:grid md:grid-cols-3 md:gap-4 md:px-0">
                                    <dt className="text-[16px] font-medium leading-6 text-gray-100">Attachments</dt>
                                    <dd className="mt-2 text-[16px] text-gray-100 md:col-span-2 md:mt-0">
                                        <ul role="list" className=" rounded-md  -gray-300">
                                            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-[16px] leading-6">
                                                <div className="flex w-0 flex-1 items-center">
                                                    <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                        <span className="truncate font-medium">resume_back_end_developer.pdf</span>
                                                        <span className="flex-shrink-0 text-gray-400">2.4mb</span>
                                                    </div>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                    <a href="#" className="font-medium text-blue-600 hover:text-blue-500">
                                                        Download
                                                    </a>
                                                </div>
                                            </li>
                                            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-[16px] leading-6">
                                                <div className="flex w-0 flex-1 items-center">
                                                    <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                        <span className="truncate font-medium">safety_Page</span>
                                                        <span className="flex-shrink-0 text-gray-400">4.5mb</span>
                                                    </div>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                    <a href="#" className="font-medium text-blue-600 hover:text-blue-500">
                                                        Download
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </dd>
                                </div> */}
              </dl>
            </div>
          </div>
          <form
            ref={form}
            className=" w-full"
            method="post"
            onSubmit={handleSubmit}
          >
            <h3 className="text-base font-semibold leading-7 mb-5 text-gray-100">
              Please note: all fields are required.
            </h3>

            <h2 className="text-base font-semibold leading-6 text-gray-100 mt-2">
              Your Name
            </h2>
            <input
              type="text"
              name="user_name"
              placeholder=" "
              onChange={handleChange}
              value={data.name}
              className="inputStyle4 w-full bg-gray-100 "
            />
            <h2 className="text-base font-semibold leading-6 text-gray-100 mt-2">
              Your Email
            </h2>

            <input
              type="email"
              name="user_email"
              onChange={handleChange}
              value={data.email}
              className="inputStyle4 w-full bg-gray-100"
            />
            <h2 className="text-base font-semibold leading-6 text-gray-100 mt-2">
              Your Message
            </h2>

            <textarea
              type="text"
              onChange={handleChange}
              value={data.message}
              name="message"
              className=" w-full font-bold p-2 text-xs bg-gray-100 h-32 rounded-md text-black"
            />
            <button
              type="submit"
              value="Send"
              className="w-full font-semibold text-center bg-blue-600 rounded-md my-6 py-1"
            >
              {" "}
              Send Message
            </button>
          </form>
        </section>
      </section>
    </section>
  );
};

export default ContactUs;
