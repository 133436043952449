import React from "react";
import { logo } from "../assets";
import { useState } from "react";

const ForgotPassword = () => {
  const [isTrueForError, SetIsTrueForError] = useState(false);
  const [backgroundcolor, setBackGroundColor] = useState(
    " bg-gradient-to-b from-[#000000a9]   via-[#0d2043c8]  to-[#000206]"
  );

  const [btnSubmit, setBtnSubmit] = useState(false);

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(" ");

  const handleError = () => {
    SetIsTrueForError(true);
    setBtnSubmit(true);
    setTimeout(() => {
      SetIsTrueForError(false);
      setBtnSubmit(false);
      setBackGroundColor(
        "bg-gradient-to-b from-[#000000a9]   via-[#0d2043c8]  to-[#000206]"
      );
    }, 2000);
  };

  const sendEmail = async (event) => {
    event.preventDefault();
    const email = "mattshawty@gmail.com";
    try {
      const respone = await fetch("/forgot-Password", {
        method: "POST",
        body: JSON.stringify({
          email: forgotPasswordEmail,
        }),
        headers: {
          "content-type": "application/json",
        },
      });

      if (respone.status === 502) {
        handleError();
        return;
      }
      if (respone.ok) {
        setBackGroundColor(
          "bg-gradient-to-b from-[#000000a9]   via-[#2A914E]  to-[#000206]"
        );
        setBtnSubmit(true);
      } else {
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <>
      <section className={`${backgroundcolor}`}>
        <div
          className={`text-white px-6 py-1 border-0 rounded-b-sm text-center bg-red-700 shadow-red-950 shadow-md  ${
            !isTrueForError ? "invisible" : "block"
          }`}
        >
          <span className="text-lg inline-block ">
            <i className="fas fa-bell" />
          </span>
          <span className="inline-block  text-sm">
            <p className=" text-lg  inline-flex"></p> Unable To Find User With
            That email.
          </span>
          <button className="absolute  bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"></button>
        </div>
        <div className="flex  min-h-screen flex-1 flex-col  pt-32 px-6  lg:px-8">
          <div className="sm:mx-auto sm:w-full justify-center sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto"
              src={logo}
              alt="logo fo Company"
            />
            <h2 className=" mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-50">
              Forgot Password?
            </h2>
          </div>
          <div className="justify-center mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              className=" space-y-6 justify-center "
              action="#"
              method="POST"
            >
              <div>
                <label
                  htmlFor="email"
                  className="text-gray-50 text-center block text-sm font-medium leading-6 "
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    onChange={(e) => setForgotPasswordEmail(e.target.value)}
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="email"
                    className="inputStyle3  w-full  focus:outline-none  inputPlaceHolder flex  font-semibold  mt-3 "
                  />
                  <p className="text-[10px] pl-2 text-gray-300">
                    please write your email and check your email for
                    confirmation.
                  </p>
                </div>
              </div>
              <div>
                <button
                  onClick={sendEmail}
                  disabled={btnSubmit}
                  className="flex w-max mx-auto  justify-center rounded-md px-8 py-1.5 text-xs font-semibold leading-6 text-white shadow-sm bg-orange-600 hover:bg-orange-500 focus-visible:outline "
                >
                  {" "}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
