import React from "react";

const Button = ({ styles }) => {
  return (
    <button
      type="button"
      className={`rounded py-2 px-4 bg-blue-600 text-[14px] outline-none font-medium  hover:bg-blue-500  shadow-md text-primary ${styles}`}
    >
      Show me
    </button>
  );
};

export default Button;
