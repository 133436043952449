import React from "react";
import { BiSolidHardHat } from "react-icons/bi";
import {
  HomeModernIcon,
  ChartPieIcon,
  WifiIcon,
} from "@heroicons/react/20/solid";

import EstimateService from "./EstimateService";
const Services = () => {
  return (
    <section className=" py-10 ">
      <div className="mt-16 "></div>
      <h1 className="text-3xl font-bold tracking-tight  text-white  text-center">
        OUR EXPERTISE AND SERVICES
      </h1>
      <p className="mt-4 px-4 md:px-5 text-xl  text-gray-200 text-center leading-6">
        AboveGround Solutions is well-prepared to address the ever-evolving
        telecommunications industry.{" "}
      </p>

      <section className="grid  rounded-md grid-cols my-10 md:grid-cols-3 gap-5 md:gap-7 ">
        <div className=" hover:bg-blue-600/40 hover:ring-blue-500/80  rounded-md bg-white/5  ring-2 ring-white/10">
          <a href={"/civilWork"}>
            <div className="flex flex-col items-center text-center py-4 md:py-8">
              <div>
                <BiSolidHardHat
                  className="h-14 w-14 text-blue-600"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold md:text-2xl text-gray-50">
                Construction Service
              </dt>
              <dd className=" text-gray-100 px-2  text-[16px]  md:text-xl leading-[20px] font-medium">
                Tailored to your unique project requirements.
              </dd>
            </div>
          </a>
        </div>
        <div className="  hover:bg-blue-600/40 hover:ring-blue-500/80  rounded-md bg-white/5  ring-2 ring-white/10">
          <a href={"/towerService"}>
            <div className="flex flex-col items-center text-center py-4 md:py-8 ">
              <div className=" ">
                <WifiIcon
                  className="h-14 w-14 text-blue-600"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold md:text-2xl text-gray-50">
                Cell RF Services{" "}
              </dt>
              <dd className=" text-gray-100 px-2  text-[16px]  md:text-xl leading-[20px] font-medium">
                Installation maintenance decommissioning
              </dd>
            </div>
          </a>
        </div>
        <div className=" hover:bg-blue-600/40 hover:ring-blue-500/80  rounded-md bg-white/5  ring-2 ring-white/10">
          <a href={"/maintenanceService"}>
            <div className="flex flex-col items-center text-center py-4 md:py-8 ">
              <div>
                <HomeModernIcon
                  className="h-14 w-14 text-blue-600"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold md:text-2xl text-gray-50">
                Shelter Service
              </dt>
              <dd className=" text-gray-100 px-2  text-[16px]  md:text-xl leading-[20px] font-medium">
                Installation maintenance decommissioning
              </dd>
            </div>
          </a>
        </div>
      </section>

      <EstimateService />
    </section>
  );
};

export default Services;
