import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageNumber = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumber.push(i);
  }

  return (
    <nav className="grid grid-row ">
      <div className="relative bottom-0">
        <div className="flex  items-center  justify-between mx-4  rounded-md px-4 py-3 sm:px-6">
          <div className=" flex flex-1 items-center justify-between ">
            <div>
              <nav
                className="isolate cursor-pointer  inline-flex -space-x-px rounded-md border-[2px] shadow-md bg-zinc-950 shadow-neutral-900 border-black "
                aria-label="Pagination"
              >
                <a
                  onClick={() => {
                    if (currentPage - 1 === 0) {
                      return;
                    }
                    paginate(currentPage - 1);
                    setCurrentPage(currentPage - 1);
                  }}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 bg-zinc-950  focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon
                    className="h-4 w-4 text-white"
                    aria-hidden="true"
                  />
                </a>

                <ul className="flex ">
                  {pageNumber.map((number) => (
                    <li key={number}>
                      <a
                        onClick={() => {
                          setCurrentPage(number);
                          paginate(number);
                        }}
                        aria-current="page"
                        className={`relative z-10 border-x-black border-x-[1px] inline-flex ${
                          currentPage === number
                            ? "bg-[#1ba7deb3]"
                            : "bg-[#303131b3]"
                        } items-center  px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                      >
                        {number}
                      </a>
                    </li>
                  ))}
                </ul>

                <a
                  onClick={() => {
                    if (currentPage + 1 > pageNumber.length) {
                      return;
                    }
                    paginate(currentPage + 1);
                    setCurrentPage(currentPage + 1);
                  }}
                  className="relative inline-flex border-x-black items-center rounded-r-md px-2 py-2 text-gray-200  bg-zinc-950 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Pagination;
