import React from "react";
import styles, { layout } from "../../style";
import Button from ".././Button";
import { Link } from "react-router-dom";
const Billing = () => {
  return (
    <section
      id="product"
      className={` ${styles.paddingX} lg:w-[70%] mx-auto  `}
    >
      <div className={`${layout.sectionReverse}  px- `}>
        <div className="">
          <div className="absolute z-[3] -left-1/2 top-0 w-[50%] h-[50%] rounded-full  " />

          <div className={`${layout.sectionInfo} text-center  `}>
            <div className=" flex flex-row sm:mt-10  mt-6 flex-wrap"> </div>

            <div
              className={`  sm:flex-row flex-col mx-auto  rounded-lg my-[50px]`}
            >
              <div className="flex-1 flex flex-col">
                <h4 className=" text-[40px] leading-tight text-white  font-bold">
                  {" "}
                  Try Our JSA Creator:
                  <span className="hidden md:block">
                    {" "}
                    Your Ultimate Solution for Streamlined Job Safety Analysis!
                  </span>
                </h4>
                <p
                  className={` mb-5  text-gray-200 hidden md:block    mt-5   font-bold  text-lg`}
                >
                  Introducing our groundbreaking{" "}
                  <span className="text-gradient font-extrabold ">
                    {" "}
                    JSA Creator application!
                  </span>
                  As a web development company in the telecom industry, we
                  understand the importance of safety and efficiency in your
                  workplace. Our JSA Creator is a powerful tool designed to
                  streamline the process of creating comprehensive Job Safety
                  Analyses
                </p>
                <p
                  className={` mb-5  text-gray-200 md:hidden   mt-5   font-bold  text-lg`}
                >
                  Our JSA Creator is a powerful tool designed to streamline the
                  process of creating comprehensive Job Safety Analyses. We
                  understand the importance of safety and efficiency in your
                  workplace.
                </p>
                <div className={`${styles.flexCenter} `}>
                  <Link to={`/features`}>
                    <Button />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Billing;
