import React, { useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useMutation, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import Style from "../userPage.module.css";
import { FcBriefcase } from "react-icons/fc";
import { MdLibraryAddCheck } from "react-icons/md";
import { BsPersonFillAdd } from "react-icons/bs";
import { QUERY_COMPANY_JSA_FILES, QUERY_USER_FRIENDS } from "../utils/queries";
import { FaUsersRays } from "react-icons/fa6";
import { UPDATE_COMPANY } from "../utils/mutations";

const EditCompanyPage = () => {
  const id = window.location.pathname.split("EditCompany/").pop();
  const [companyNewName, setCompanyNewName] = useState("");
  const { loading: loadingCurrentUsers, data: dataCurrentUsers } = useQuery(
    QUERY_COMPANY_JSA_FILES,
    {
      variables: { ID: id },
    }
  );
  const [updateCompany, {}] = useMutation(UPDATE_COMPANY);

  const { loading, data } = useQuery(QUERY_USER_FRIENDS, {
    variables: { email: "email." },
  });
  const [newCompany, setNewCompany] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [crewMembers, setCrewMembers] = useState([]);
  const [chosenCrewMembers, SetCrewMembers] = useState([]);

  useEffect(() => {
    if (dataCurrentUsers) {
      setCompanyNewName(dataCurrentUsers.jsaFielsOfCompany.companyName);
      SetCrewMembers(dataCurrentUsers.jsaFielsOfCompany.users);
      setCurrentIndex(
        currentIndex + dataCurrentUsers.jsaFielsOfCompany.users.length
      );
    }
  }, [loading, data]);
  useEffect(() => {
    if (data) {
      setCrewMembers(data.oneUser.friends);
    }
  }, [loading, data]);
  const submitUpdatedCompany = async (e) => {
    e.preventDefault();

    try {
      const arrayOfEmails = chosenCrewMembers.map((obj) => obj._id);

      const { data } = await updateCompany({
        variables: {
          users: arrayOfEmails,
          name: companyNewName,
          companyID: id,
        },
      });

      if (data) {
        window.history.back();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCLick = async (e) => {
    e.preventDefault();

    const data = e.currentTarget;
    const email = data.getAttribute("email");
    const name = data.getAttribute("name");
    const _id = data.getAttribute("id");
    const updatedData = [...chosenCrewMembers];

    if (updatedData.some((item) => item.email === email)) {
      const clearedData = updatedData
        .filter((item) => item.email !== email) // Remove the clicked item
        .concat({
          name: " ",
          email: " ",
          joined: false,
          company: " ",
          cprFirstAid: false,
          certifiedClimber: false,
        }); // Add the clicked item to the end

      SetCrewMembers(clearedData);
      setCurrentIndex(currentIndex - 1);
      return;
    }
    if (currentIndex === 7) {
      return;
    }

    updatedData[currentIndex] = {
      username: name,
      email: email,
      _id: _id,
    };

    SetCrewMembers(updatedData);
    setCurrentIndex(currentIndex + 1);
  };
  const returnPage = () => {
    window.history.back();
  };

  return (
    <section className="1f] cursor-default ">
      <div className="col-span-4 bg-[#000206] shadow-md shadow-[#070b0f] flex items-center w-full">
        <Link onClick={returnPage} className="icon  md:ml-5 xl:ml-32 ">
          <FaLongArrowAltLeft size={20} className="icon pb-1 hover:h-[.1%]" />
        </Link>
      </div>
      <div className={` min-h-screen `}>
        <div className={Style.account_info}>
          <p className="pt-5">
            You can set preferred display name, create your profile URL and
            manage other personal settings.
          </p>
        </div>
        <div className={` cursor-default ${Style.account_box}`}>
          <div className={`cursor-default ${Style.account_box_img}`}>
            <FaUsersRays size={60} className="icon pb-1 hover:h-[.1%]" />
          </div>
          <div className={Style.account_box_from}>
            <div className={Style.Form}>
              <div className={Style.Form_box}>
                <form className="mt-[-10px]">
                  <div className={Style.Form_box_input}>
                    <label htmlFor="Company">Edit Company Name</label>
                    <div className={Style.Form_box_input_box}>
                      <div className={Style.Form_box_input_box_icon}>
                        <FcBriefcase />
                      </div>
                      <input
                        onChange={(e) => setCompanyNewName(e.target.value)}
                        className={Style.Form_box_input_userName}
                        value={companyNewName}
                        type="string"
                        placeholder="Company"
                      />
                    </div>
                  </div>

                  <label
                    htmlFor="AddUsers"
                    className="text-sm font-semibold ml-2"
                  >
                    Add Users
                  </label>
                  <ul className="grid grid-cols-1  gap-2    ">
                    {data === false
                      ? "Loading..."
                      : crewMembers.map((item, i) => {
                          const isAlreadyAdded = chosenCrewMembers.some(
                            (member) => item.email === member.email
                          );
                          return (
                            <li
                              htmlFor={`${item.email}-option`}
                              className={` flex px-2 rounded-md bg-[#050505]  justify-between gap-x-6 py-2 ${
                                isAlreadyAdded
                                  ? "bg-blue-600 shadow-md"
                                  : "bg-[#0E0E0E] rounded-lg cursor-pointer  peer-checked:shadow-blue-950"
                              }  rounded-md  border-[#62626228]  shadow-lg  `}
                              cprFirstAid={item.cprFirstAid.toString()}
                              certifiedClimber={item.certifiedClimber.toString()}
                              number={i}
                              onClick={handleCLick}
                              email={item.email}
                              id={item._id}
                              name={item.username}
                              company={item.company}
                            >
                              <div className="flex min-w-0 gap-x-4 ">
                                <img
                                  className="h-10 w-10 flex-none rounded-full bg-gray-50"
                                  src={item.profilePicture}
                                  alt="profile picture"
                                />
                                <div className="min-w-0 w-36">
                                  <p className="text-sm font-semibold leading-5 text-gray-100">
                                    {item.username}
                                  </p>
                                  <p className="py-1 truncate text-xs leading-3 text-gray-300">
                                    {item.email}
                                  </p>
                                </div>
                              </div>
                              <div className=" shrink-0 flex flex-col items-end">
                                <p className="text-sm invisible leading-6 text-gray-400">
                                  .
                                </p>

                                <div className=" flex items-center ">
                                  {isAlreadyAdded ? (
                                    <MdLibraryAddCheck />
                                  ) : (
                                    <BsPersonFillAdd
                                      key={i} // Assuming 'i' is defined elsewhere in your component
                                      onClick={handleCLick}
                                      id={item._id}
                                      email={item.email}
                                    />
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                  </ul>
                  <button
                    btnName="Upload profile"
                    onClick={submitUpdatedCompany}
                    className="bg-blue-700 font-semibold w-full flex text-center rounded justify-center my-9 mb-14"
                  >
                    {" "}
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <button
                btnName="Upload profile"
                onClick={submitUpdatedCompany}

                className="bg-red-600 font-semibold w-fit px-2 flex text-center rounded ml-[60%] my-9 mb-14"
            >not functional Delete</button> */}
    </section>
  );
};

export default EditCompanyPage;
