import React from "react";

import NWSA from "../../assets/trace.svg";
import SAFETYLMS from "../../assets/safetyLMS.svg";

const CertifiedClouds = () => {
  return (
    <div className="  py-10 pt-10rounded-md">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-2xl px-5 leading-6 font-bold tracking-tight text-white  text-center">
          Certifications Held to Industry Standards of Trust
        </h2>
        <div className="mx-auto rounded-lg mt-5 grid   grid-cols-2 sm:grid-cols-4 items-center gap-3 ">
          <img src={NWSA} className="h-8 justify-self-center" />
          <img src={SAFETYLMS} className="h-14 justify-self-center pt-2" />

          <svg
            className=" justify-self-center"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            width="100px"
            height="30px"
            viewBox="0 0 740.002 207.213"
            enable-background="new 0 0 720.002 207.213"
          >
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          fill="#e6e6e6e0"
                          d="M314.039,48.588c-1.656-13.246-2.76-26.492-2.76-39.465c-8.557-1.102-32.014-4.139-39.738-4.139        c-35.602,0-60.988,21.25-60.988,57.127c0,66.51,81.131,51.332,81.131,100.18c0,19.594-15.178,31.461-33.943,31.461        c-26.217,0-39.463-20.422-42.773-43.879l-7.729,1.654c1.381,14.074,3.314,27.875,4.691,41.949        c13.523,6.9,28.426,12.42,43.881,12.42c35.322,0,66.783-19.873,66.783-57.955c0-66.236-82.789-52.436-82.789-98.248        c0-21.805,12.143-34.773,33.941-34.773c19.043,0,30.635,17.111,32.564,35.049"
                        />
                        <path
                          fill="#e6e6e6e0"
                          d="M372.752,109.027h100.176v68.719c0,14.904-11.592,15.73-21.523,15.73h-5.246v8.279        c12.143,0,29.805-1.104,44.707-1.104c14.074,0,30.633,1.104,41.67,1.104v-8.279h-3.861c-9.936,0-20.422-1.381-20.422-15.73        V33.133c0-14.348,10.486-15.729,20.422-15.729h3.861V9.123c-11.037,0-27.596,1.105-41.67,1.105        c-14.902,0-32.564-1.105-44.707-1.105v8.281h5.246c9.932,0,21.523,0.826,21.523,15.729v60.439H372.752V33.133        c0-14.902,11.592-15.729,21.525-15.729h5.244V9.123c-12.143,0-29.805,1.105-44.709,1.105c-14.072,0-30.629-1.105-41.668-1.105        v8.281h3.861c9.936,0,20.422,1.381,20.422,15.729v144.613c0,14.35-10.486,15.73-20.422,15.73h-3.861v8.279        c11.039,0,27.596-1.104,41.668-1.104c14.904,0,32.566,1.104,44.709,1.104v-8.279h-5.244c-9.934,0-21.525-0.826-21.525-15.73"
                        />
                        <path
                          fill="#e6e6e6e0"
                          d="M581.742,133.59h62.645l10.762,32.012c3.037,8.832,5.52,16.283,5.52,19.873c0,6.898-10.762,8.002-17.109,8.002h-3.035        v8.279c14.076-0.551,27.875-1.104,41.395-1.104c13.246,0,25.664,0.553,38.084,1.104v-8.279h-1.656        c-9.105,0-14.9-3.311-18.213-11.039c-3.588-8.279-6.898-18.49-10.211-28.15L639.145,9.951        c-0.83-2.207-1.656-4.691-2.484-6.898c-0.551-0.83-1.104-0.83-1.932-0.83s-1.379,0.279-2.207,0.553        c-5.244,3.312-16.283,8.832-25.113,11.867c-1.654,10.213-6.623,24.012-10.211,34.223l-44.156,126.674        c-4.139,11.59-13.244,17.938-24.008,17.938h-1.654v8.279c9.934-0.551,19.867-1.104,29.805-1.104        c11.035,0,22.352,0.553,33.391,1.104v-8.279h-3.035c-9.109,0-20.699-1.381-20.699-9.66c0-4.967,3.312-12.143,6.07-21.525         M638.592,118.135h-51.605l25.664-78.102h0.555L638.592,118.135z"
                        />
                        <path
                          fill="#808080"
                          d="M56.324,71.451c10.143-14.01,26.633-23.123,45.25-23.123c30.836,0,55.832,24.996,55.832,55.834        c0,30.836-24.996,55.832-55.832,55.832c-22.824,0-42.449-13.695-51.105-33.32c6.953,7.992,17.197,13.043,28.623,13.043        c20.947,0,37.928-16.982,37.928-37.93s-16.98-37.928-37.928-37.928C70.549,63.859,62.666,66.686,56.324,71.451"
                        />
                        <path
                          fill="#068AD2"
                          d="M0,103.941C0,60.33,35.352,24.975,78.963,24.975c28.4,0,53.299,14.996,67.213,37.502        c-11.141-11.916-27.002-19.363-44.602-19.363c-33.713,0-61.045,27.332-61.045,61.049s27.332,61.049,61.045,61.049        c17.168,0,32.68-7.086,43.771-18.494c-14.057,21.775-38.537,36.189-66.383,36.189C35.352,182.906,0,147.553,0,103.941"
                        />
                        <path
                          fill="#e6e6e6e0"
                          d="M5.836,61.889C21.92,25.688,58.189,0.443,100.357,0.443c57.094,0,103.379,46.285,103.379,103.383        c0,57.1-46.285,103.387-103.379,103.387c-41.775,0-77.764-24.781-94.068-60.441c14.674,24.846,41.727,41.512,72.674,41.512        c46.58,0,84.34-37.762,84.34-84.342c0-46.584-37.76-84.344-84.34-84.344C47.695,19.598,20.4,36.611,5.836,61.889"
                        />
                        {/* <path  fill="#fff" d="M664.98,19.18C664.98,8.592,673.572,0,684.262,0c10.639,0,19.18,8.592,19.18,19.18        c0,10.689-8.541,19.281-19.18,19.281C673.572,38.461,664.98,29.869,664.98,19.18 M700.064,19.18        c0-8.695-7.059-15.805-15.803-15.805c-8.797,0-15.908,7.109-15.908,15.805c0,8.797,7.111,15.906,15.908,15.906        C693.006,35.086,700.064,27.977,700.064,19.18z M680.17,20.457v9.771h-3.529V7.621h7.314c4.141,0,8.438,1.123,8.438,6.24        c0,2.607-1.584,4.652-4.604,5.266v0.104c3.121,0.613,3.479,1.994,3.838,4.449c0.307,2.146,0.562,4.502,1.328,6.549h-4.502        c-0.254-1.279-0.613-2.713-0.768-4.041c-0.252-1.945-0.252-3.736-1.277-4.809c-0.867-0.922-2.045-0.818-3.273-0.922H680.17z         M683.9,16.93c3.326-0.104,4.092-1.484,4.092-3.223c0-1.689-0.766-2.557-3.578-2.557h-4.244v5.779H683.9z" /> */}
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <img
            className="h-[35px] w-[107px] justify-self-center my-2 "
            src="https://natehome.com/wp-content/themes/nate-2018/images/nate_logo_large.png"
          ></img>
        </div>
      </div>
      <div className="flex"></div>
    </div>
  );
};

export default CertifiedClouds;
