import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { QUERY_BELONGS_USER, QUERY_JSA_PDFDATAURL } from "../utils/queries";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import styles from "../style";
import createNewJsa from "../utils/createPDF";
import sendEmailHandler from "../utils/sendEmail";
import { UPDATE_JSA_1 } from "../utils/mutations";
import { BiSolidLockAlt } from "react-icons/bi";
import IndiviualHomeJsa from "./IndiviualHomeJsa";
import IndiviualDetailsJsa from "./IndiviualDetailsJsa";
import ImageSlider from "./ImageSlider";

const IndiviualJSA = () => {
  const [changePage, setChangePage] = useState("Page");
  const [pageView, setPageView] = useState("");
  const id = window.location.pathname.split("one/").pop();
  const [createJsa, { error }] = useMutation(UPDATE_JSA_1);
  const [jobTasks, setJobTasks] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [txtInvalidEmail, setTxtInvalidEmail] = useState("");
  const [locked, setLocked] = useState(false);
  const {
    loading: UserLoading,
    data: UserData,
    refetch,
  } = useQuery(QUERY_BELONGS_USER, {
    variables: { email: id },
  });
  const {
    loading,
    data,
    refetch: refetchPdf,
  } = useQuery(QUERY_JSA_PDFDATAURL, {
    variables: { jsaPageId: id },
  });
  const setChange = (page) => {
    setChangePage(page);
  };
  // useEffect(() => {
  //     window.scrollTo(0, 0);
  //     if (pageView === 'Details') {

  //         setChangePage('Details')
  //         return

  //     };
  //     if (pageView === 'Photos') {

  //         setChangePage('Photos')
  //         return

  //     };
  //     setChangePage('Page');
  //     const storedLockStatus = localStorage.getItem('isActiveJSA');
  //     if (!storedLockStatus) {
  //         return;
  //     }
  //     setLocked(true)

  // }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const param1 = queryParams.get("page");
    setPageView(param1);
    if (pageView === "Details") {
      setChangePage("Details");
      return;
    }
    if (pageView === "Photos") {
      setChangePage("Photos");
      return;
    }
    if (pageView === "Page") {
      setChangePage("Page");
      return;
    }
  }, [changePage]);
  const updateQueryParameter = (page) => {
    // Get the current URL
    const currentURL = window.location.href;
    const url = new URL(currentURL);

    // page === 'Page' ? url.searchParams.set('page', 'home') : page === "Photos" ? url.searchParams.set('page', 'photos') : url.searchParams.set('page', 'details');
    page === "Page"
      ? setChange(page)
      : page === "Details"
      ? setChange(page)
      : setChange(page);

    window.history.replaceState({}, "", url.href);
  };
  const refreshMainState = () => {
    refetch();
  };
  const [jsaData, setjsaData] = useState({
    creatorName: " ",
    creatorID: " ",
    creatorFirstAid: false,
    creatorClimber: false,
    jobName: " .",
    jobNumber: " .",
    physicalAddress: " .",
    date: " .",
    longitude: " .",
    supervisor: " .",
    latitude: " .",
    ambulance: " .",
    fire: " .",
    police: " .",
    localHospital: " .",
    telephoneCo: " .",
    utilityCo: " .",
    hospitalAddress: "",
    evacuationPoint: " .",
    jobTasks: " .",
    potentialHazards: " .",
    preventiveMeasures: " .",
    fallsFromElevationsCheckBoxList: false,
    firstAidKitCheckBoxList: false,
    rescueBagTowerCheckBoxList: false,
    towerErectionCheckboxList: false,
    elericalCheckBoxList: false,
    civilConcreteCheckBoxList: false,
    lineAtennasCheckBoxList: false,
    blueNoticeSignCheckBoxList: false,
    yellowCautionSignCheckBox: false,
    redWarningSignCheckBoxList: false,
    redDangerSignCheckBoxList: false,
    electricalHazardsCheckBoxList: false,
    silicaExposureCheckBox: false,
    coldStressCheckBoxList: false,
    chemicalExposure: false,
    liftingHazards: false,
    hardHatCheckBoxList: false,
    hearingCheckBoxList: false,
    glovesCheckBoxList: false,
    rfmonitorsCheckBoxList: false,
    tailgateMeetingCheckBoxList: false,
    lockoutTagoutCheckBoxList: false,
    fourInOneSignCheckBoxList: false,
    excavationLogCheckBoxList: false,
    heatStressCheckBoxList: false,
    elecricalCheckBoxList: false,
    LineAntennasCheckBoxList: false,
    describeTypeDepthExcavation: " .",
    slopingCheckBoxList: false,
    benchingCheckBoxList: false,
    shoringCheckBoxList: false,
    trenchCheckboxList: false,
    shieldBoxCheckBoxList: false,
    laddertrench4FeetCheckBoxList: false,
    elevationSiteTerrainEnivromentalConcersHazards: " .",
    hazardsWithSiteVehicleStorage: " .",
    describeTypeElectricalCocernsOrHazards: " .",
    typeTowerOrRoofTop: " .",
    typeAntennaBoom: " .",
    exitManLiftMoPCheckBoxList: false,
    fullBodyHarnessCheckBoxList: false,
    ropeGrabCheckBoxList: false,
    cableGrabCheckBoxList: false,
    doubleLegTwoLanyardsCheckBoxList: false,
    retractableLifeLineChecBoxList: false,
    anchorageStrapsCheckBoxList: false,
    fallProtectionRopeCheckBoxList: false,
    inspectedFallProtectionEquipmentCheckBoxList: false,
    describeFallProtectionSystemUsedAccessAntenna: " .",
    craneBoomTruckCheckBoxList: false,
    otherJobSafetyCheckboxList: " .",
    towerRescueOtherCheckBox: " .",
    vehicleTrafficCheckBoxList: false,
    suspenedPersonnelPlatformChechBoxList: false,
    undergroundUtilitiesCheckBoxList: false,
    highNoise85DBaCheckBoxList: false,
    slipsTripsOrFallsCheckBoxList: false,
    emeAndRfCheckBoxList: false,
    chemicalExposureCheckBoxList: false,
    liftingHazardsCheckBoxList: false,
    safetyGlassesCheckBoxList: false,
    rfSuitsCheckBoxList: false,
    toolsEquipmentCheckBoxList: false,
    housekeepingCheckBoxList: false,
    ginPolesCheckBoxList: false,
    riggingCheckBoxList: false,
    do911SystemsWorkCheckBoxList: false,
    tagLinesCheckBoxList: false,
    groundFaultProtectionCheckBoxList: false,
    hoistsCheckBoxList: false,
    towerginPoleCheckBoxList: false,
    fallProtectionCheckBoxList: false,
    descendersCheckBoxList: false,
    fallProtectionRopeCheckBoxList: false,
    permitSystemCheckBoxList: false,
    fallsFromElevationsCheckedBoxList: false,
    elevationSiteTerrainCheckBoxList: false,
    //mpe readings
    blueNoticeFirstMpeReading: " .",
    blueNoticeSecondMpeReading: " .",
    yellowCautionFirstMpeReading: " .",
    yellowCautionSecondMpeReading: " .",
    redWarningFirstMpeReading: " .",
    redWarningSecondMpeReading: " .",
    //others
    otherPhysicalHazards: " .",
    otherHealthHazards: " .",
  });
  const [crewMembers, setCrewMembers] = useState([
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
  ]);
  const [addressencoded, setAddressEncoded] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");

  const addUserToJSA = () => {
    refetch();
  };
  useEffect(() => {
    if (data) {
      const encodedAddress = encodeURIComponent(
        data.showEmbedJsa.physicalAddress
      );
      setAddressEncoded(encodedAddress);
      setjsaData(data.showEmbedJsa);
      setCrewMembers(data.showEmbedJsa.chosenCrewMembers);
      setJobTasks(data.showEmbedJsa.jobTasks.split(/\d+/));
    }
  }, [data, loading]);
  const isValidEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(email);
  };
  const addNewUser = async () => {
    if (isValidEmail(newUserEmail) === false) {
      handleError("*invalid email*");
      return;
    }
    if (
      !!jsaData.chosenCrewMembers.find(
        (item) => item.crewEmail === newUserEmail
      )
    ) {
      handleError("*user is already added*");
      return;
    }

    const crewMemberIndexToUpdate = jsaData.chosenCrewMembers.findIndex(
      (item) => item.name === " "
    );
    if (crewMemberIndexToUpdate === -1) {
      handleError("*list is full*");
      return;
    }

    const name = newUserEmail.split("@")[0];
    const updatedCrew = [...jsaData.chosenCrewMembers]; // Create a copy of the array
    updatedCrew[crewMemberIndexToUpdate] = {
      name: name,
      crewEmail: newUserEmail,
      company: "AboveGround",
      cprFirstAid: true,
      certifiedClimber: true,
      joined: false,
    };

    setjsaData({
      ...jsaData,
      chosenCrewMembers: updatedCrew,
    });

    createNewJsa(jsaData)
      .then(async (response) => {
        try {
          const usersWithoutAge = updatedCrew.map(
            ({ __typename, ...rest }) => rest
          );
          const { data } = await createJsa({
            variables: {
              updatedpdfDataUrl: response,
              updatedCrewMembers: usersWithoutAge,
              jsaPageId: id,
            },
          });
          await sendEmailsToClickedMembers(name, "AboveGround", newUserEmail);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
        return;
      });
    return;
  };

  const sendEmailsToClickedMembers = async (name, company, email) => {
    try {
      await sendEmailHandler(name, company, email, id);
      setNewUserEmail("");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    refetchPdf();
  });
  const handleError = (text) => {
    setInvalidEmail(true);
    setTxtInvalidEmail(text);
    setTimeout(() => {
      setInvalidEmail(false);
      setTxtInvalidEmail("");
    }, 5000);
  };

  return (
    <section className="felx min-h-screen   cursor-default">
      <nav className="bg-[#000206] pb-1">
        <div className="col-span-4  flex items-center w-full">
          <Link
            onClick={() => window.history.back()}
            className="icon md:ml-5 xl:ml-32 "
          >
            <FaLongArrowAltLeft size={20} className="icon  hover:h-[.1%]" />
          </Link>
        </div>
        <div className="col-span-3  justify-around flex text-xs border-b-[1px] w-[90%] mx-auto border-stone-800 text-gray-100">
          <p
            onClick={() => {
              updateQueryParameter("Page");
            }}
            className={`${
              changePage === "Page"
                ? " font-semibold border-b-[3px] border-blue-500 "
                : ""
            }cursor-pointer roundedmd leading-8 `}
          >
            Home
          </p>
          <p
            onClick={() => {
              updateQueryParameter("Details");
            }}
            className={`${
              changePage === "Details"
                ? "font-semibold border-b-[3px] border-blue-500 "
                : " "
            }cursor-pointer roundedmd leading-8 `}
          >
            Details
          </p>
          <p
            onClick={() => {
              updateQueryParameter("Photos");
            }}
            className={`${
              changePage === "Photos"
                ? "font-semibold border-b-[3px] border-blue-500 "
                : " "
            }cursor-pointer roundedmd leading-8 `}
          >
            Photos
          </p>
        </div>
      </nav>

      <div className="bg-[#000206] shadow-md shadow-[#000206]  ">
        <div className={` ${styles.boxWidth} ${styles.paddingX}  mx-auto `}>
          <div>
            <div className=" sm:px-0 pt-2 flex">
              <h3 className="text-base font-semibold leading-6 ">
                Job Safety Analysis{" "}
              </h3>
              <BiSolidLockAlt className={`${locked ? "" : "invisible"} pl-1`} />
            </div>
            <dl className=" pl-1 text-gray-200">
              <div className=" grid grid-cols-3 gap-4 px-0">
                <dt className="text-xs font-medium leading-6   ">Location</dt>

                <a
                  href={`https://maps.apple.com/?q=${addressencoded}`}
                  className=" underline under text-xs leading-6 truncate w-44 sm:w-full text-blue-600 col-span-2 mt-0"
                >
                  {data?.showEmbedJsa.physicalAddress || "loading"}
                </a>
              </div>

              <div className=" grid grid-cols-3 gap-4 px-0">
                <dt className="text-xs font-medium leading-6 ">Supervisor</dt>
                <dd className=" text-xs leading-6 text-gray-200 col-span-2 mt-0">
                  {data?.showEmbedJsa.supervisor || "loading"}
                </dd>
              </div>

              <div className=" grid grid-cols-3 gap-4  pb-2 px-0">
                <dt className="text-xs font-medium leading-6 ">Date</dt>
                <dd className=" text-xs leading-6 text-gray-200 col-span-2 mt-0">
                  {data?.showEmbedJsa.date || "loading"}
                </dd>
              </div>
            </dl>
            <p className="pl-1 max-w-2xl text-[8px] mt-[-8px]  text-gray-200">
              Access Information and PDF Document.
            </p>
          </div>
        </div>
      </div>
      <>
        {changePage === "Page" ? (
          <div className={` ${styles.boxWidth} ${styles.paddingX}  mx-auto`}>
            <IndiviualHomeJsa data={data} />
          </div>
        ) : changePage === "Details" ? (
          <IndiviualDetailsJsa
            data={data}
            loading={loading}
            addNewUser={addNewUser}
            setNewUserEmail={setNewUserEmail}
            addUserToJSA={addUserToJSA}
            UserData={UserData}
            txtInvalidEmail={txtInvalidEmail}
            newUserEmail={newUserEmail}
            invalidEmail={invalidEmail}
            id={id}
            locked={locked}
          />
        ) : (
          <div className="bg-[#00040fd6]">
            <div
              className={` ${styles.boxWidth} ${styles.paddingX}  mx-auto  `}
            >
              <ImageSlider
                refetchPdf={refetchPdf}
                type={"Pre"}
                imageUrls={
                  data.showEmbedJsa.prePhotos.length === 0
                    ? [
                        "https://t4.ftcdn.net/jpg/05/65/22/41/360_F_565224180_QNRiRQkf9Fw0dKRoZGwUknmmfk51SuSS.jpg",
                      ]
                    : data.showEmbedJsa.prePhotos
                }
                jsaPageId={id}
              />
              <ImageSlider
                refetchPdf={refetchPdf}
                type={"Post"}
                imageUrls={
                  data.showEmbedJsa.postPhotos.length === 0
                    ? [
                        "https://t4.ftcdn.net/jpg/05/65/22/41/360_F_565224180_QNRiRQkf9Fw0dKRoZGwUknmmfk51SuSS.jpg",
                      ]
                    : data.showEmbedJsa.postPhotos
                }
                jsaPageId={id}
              />
            </div>
          </div>
        )}
      </>
    </section>
  );
};

export default IndiviualJSA;
