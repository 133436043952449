import {
  CloudArrowUpIcon,
  CheckBadgeIcon,
  HeartIcon,
  ArchiveBoxIcon,
  RocketLaunc,
  HeartIconhIcon,
  RocketLaunchIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";
import styles from "../style";
import Services from "./frontPage/Services";
import cellTower from "../assets/towerService1.jpg";
import cellTower2 from "../assets/towerService2.jpg";

export default function TowerServicePage() {
  return (
    <section
      className={`bg-[#00040F]  ${styles.paddingX}  ${styles.flexStart} `}
    >
      <section className={`   ${styles.boxWidth}  `}>
        <div className="relative isolate overflow-hidden  bg-[#00040F] text-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
          <div className="absolute inset-0 -z-10 overflow-hidden"></div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-12 lg:mx-0 lg:max-w-none md:grid-cols-2 lg:items-start lg:gap-y-10">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <p className="text-base font-semibold leading-5 text-orange-600">
                    Cell RF Service
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
                    {" "}
                    Building RF Confidence
                  </h1>
                  <p className="mt-6 leading-6 text-gray-200">
                    AboveGround Solutions is your trusted partner for a wide
                    range of cell RF services, including network optimization
                    and tower installation. With a team of experienced
                    professionals and a commitment to excellence, we pride
                    ourselves on delivering outstanding results.
                  </p>
                </div>
              </div>
            </div>
            <div className=" rounded-md mt-  lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 ">
              <img className=" rounded-md" src={cellTower} alt="antennas" />
            </div>
            <strong className="w-full my-[-10px]  md:col-span-2 text-center">
              Fueling RF Success by Preparing for the Unknown—Drawing from Our
              Journey of Expertise.
            </strong>
            <div className="grid md:col-span-2   xl:w-[70%] mx-auto w-full  ">
              <ul className=" grid grid-cols-2 col-span-2 text-xs ">
                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-l-md ">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Antenna Installation{" "}
                </li>
                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-r-md ">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Antenna Alignment
                </li>
                <li className="flex items-center gap-x-3 p-2">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Cabling and Wiring
                </li>
                <li className="flex items-center gap-x-3 p-2">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Signal Testing
                </li>
                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-l-md ">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Structural Reinforcement
                </li>
                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-r-md ">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600  "
                    aria-hidden="true"
                  />
                  Equipment Maintenance
                </li>
                <li className="flex items-center gap-x-3 p-2">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Compliance and Regulations{" "}
                </li>
                <li className="flex items-center gap-x-3 p-2">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  RF Interference Mitigation
                </li>
                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-l-md ">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Weatherproofing
                </li>
                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-r-md ">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Compliance and Regulations
                </li>
              </ul>
            </div>
            <div className="md:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="text-base  max-w-xl leading-7 text-gray-200 lg:max-w-lg">
                  <img
                    className="rounded-md"
                    alt="antennas"
                    src={
                      "https://images.unsplash.com/photo-1604528867882-6fcd9fe896eb?auto=format&fit=crop&q=80&w=1740&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />

                  <ul role="list" className="mt-8 space-y-2 text-gray-200">
                    <li className="flex gap-x-3 bg-slate-900 p-2 rounded-md ">
                      <HeartIcon
                        className="mt-1 h-5 w-5 flex-none text-orange-600"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-100">
                          Safe
                        </strong>{" "}
                        cell RF services are our top priority, ensuring your
                        project is executed with the highest level of care and
                        protection. Our commitment to safety is unwavering, so
                        you can trust in a secure and successful experience with
                        your RF services.
                      </span>
                    </li>
                    <li className="flex gap-x-3 p-2">
                      <CheckBadgeIcon
                        className="mt-1 h-5 w-5 flex-none text-orange-600"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-100">
                          Quality
                        </strong>{" "}
                        is the cornerstone of our cell RF services at
                        AboveGround Solutions. We prioritize delivering
                        top-quality and precise RF services, ensuring that we
                        turn your project goals into a seamless reality.
                      </span>
                    </li>
                    <li className="flex gap-x-3 bg-slate-900 p-2 rounded-md">
                      <RocketLaunchIcon
                        className="mt-1 h-5 w-5 flex-none text-orange-600"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-100">
                          Speed
                        </strong>{" "}
                        and efficiency define our approach to cell RF services
                        at AboveGround Solutions. We take pride in our ability
                        to consistently deliver projects on time and with
                        unparalleled precision.
                      </span>
                    </li>
                  </ul>
                  <p className="mt-8">
                    Our experienced team at AboveGround Solutions is
                    well-prepared to foresee and address unforeseen RF
                    challenges, leveraging our extensive hands-on experience in
                    the field. We've encountered the hurdles, so you can
                    navigate smoothly. Our expertise guarantees a seamless
                    progression for your RF project, even in the presence of
                    unexpected obstacles.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-10">
          <Services />
        </div>
      </section>
    </section>
  );
}
