import React from "react";
import users from "../assets/user.jpg";

const StackedListUsers = (props) => {
  const { crewMembers } = props;
  const filteredCrewMembers = crewMembers.filter(
    (member) => member.joined === true
  );
  return (
    <>
      <div className="flex truncate -space-x-1  overflow-hidden  ">
        {filteredCrewMembers.map((person) => (
          <img
            class="inline-block h-4 w-4 rounded-full ring-2  ring-[#0E0E0E]"
            src={users}
            alt="silhouette of user"
          />
        ))}
      </div>
    </>
  );
};

export default StackedListUsers;
