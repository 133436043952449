import React, { useEffect, useState } from "react";
import FriendsList from "./FriendsList";
import styles from "../style";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { PiGearBold } from "react-icons/pi";
import { QUERY_FOR_PROFILE } from "../utils/queries";
import { useQuery } from "@apollo/client";
import Button from "./Button";

const ProfilePage = () => {
  const { loading, data, refetch } = useQuery(QUERY_FOR_PROFILE, {
    variables: { email: "email." },
  });
  const refreshMainState = () => {
    refetch();
  };
  useEffect(() => {
    refetch();
  });
  const returnPage = () => {
    window.history.back();
  };

  return (
    <section className=" min-h-screen  mt-[-4px]">
      <div className="col-span-4 bg-[#000206]    flex items-center w-full">
        <Link onClick={returnPage} className="icon md:ml-5 xl:ml-32 ">
          <FaLongArrowAltLeft size={20} className="icon  pb-1 hover:h-[.1%]" />
        </Link>
        <h1 className="   w-full "> </h1>
        <Link to={"/user"} className="mr-5 xl:mr-[13%] sm:mr-16">
          <PiGearBold className="" size={20} />
        </Link>
      </div>
      <>
        <div className="bg-[#000206] ">
          <div className={`bg-[#000206] ${styles.boxWidth} mx-auto  `}>
            <div className={`   ${styles.paddingX}`}>
              <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full cursor-default ">
                  <div className="mt-2 flex items-center gap-x-3">
                    <img
                      src={
                        !loading ? data.forProfile.profilePicture : "loading..."
                      }
                      alt="account upload"
                      width={100}
                      height={100}
                      className={` bg-white  lg:h-[10%] lg:w-[10%]  rounded-full`}
                    />

                    <p className=" w-full font-bold border-b cursor-default">
                      AboveGround solutions{" "}
                    </p>
                  </div>
                  <h1 className="text-[11px] text-gray-200 font-semibold leading-3 mt-2">
                    {!loading ? data.forProfile.username : "loading..."}
                  </h1>
                  <p className="text-[10px] text-gray-200">
                    {" "}
                    {!loading ? data.forProfile.email : "loading..."}
                  </p>
                  <Link to={"/user"}></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-[#000206] relative  h-5  shadow-md shadow-[#000206]"></div>

        <section className=" h-full  pt-10">
          <div className={` ${styles.boxWidth} mx-auto `}>
            <div className={`   ${styles.paddingX}`}>
              <div className="border-b border-gray-400/10 pb-12">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-100"
                >
                  FriendsList
                </label>
                {!loading ? (
                  <FriendsList
                    friendsList={data.forProfile.friends}
                    refreshFunction={refreshMainState}
                  />
                ) : (
                  "Loading..."
                )}
              </div>

              <div className="border-b bottom-0 bottom cursor-default border-gray-400/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-400">
                  Notifications
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-300">
                  We'll always let you know about important changes, but you can
                  connect with us for support anytime.
                </p>
              </div>
            </div>
          </div>
          {/* <Link className="bg-blue-600 rounded-md " to={'/certs/user'}>
                        View Certs
                    </Link> */}
        </section>
      </>
    </section>
  );
};
export default ProfilePage;
