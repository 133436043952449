import React from "react";
import { logo } from "../assets";
import { useState } from "react";
import { useEffect } from "react";

const ResetPassword = () => {
  const [newPasswords, setNewPasswords] = useState({
    newPassword: "",
    retypedNewPassword: "",
  });
  const [isTrueForError, SetIsTrueForError] = useState(false);
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [backgroundcolor, setBackGroundColor] = useState(
    " bg-gradient-to-b from-[#000000a9]   via-[#0d2043c8]  to-[#000206]"
  );

  const handleError = () => {
    SetIsTrueForError(true);
    setTimeout(() => {
      SetIsTrueForError(false);
      setBackGroundColor(
        "bg-gradient-to-b from-[#000000a9]   via-[#0d2043c8]  to-[#000206]"
      );
    }, 5000);
  };

  useEffect(() => {
    const isUserTrue = async () => {};
    isUserTrue();
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPasswords({ ...newPasswords, [name]: value });
  };
  const resetNewPassword = async (e) => {
    e.preventDefault();
    if (
      newPasswords.newPassword === "" ||
      newPasswords.retypedNewPassword === ""
    ) {
      handleError();

      return;
    }
    if (newPasswords.newPassword !== newPasswords.retypedNewPassword) {
      handleError();
      return;
    }
    try {
      const pathParams = window.location.pathname
        .split("reset-password/")[1]
        .split("/");

      const token = pathParams[1];
      const id = pathParams[0];

      const response = await fetch(`reset-password/${id}/${token}`, {
        method: "POST",
        body: JSON.stringify({
          password: newPasswords.newPassword,
        }),
        headers: {
          "content-type": "application/json",
        },
      });

      if (!response.ok) {
        handleError();

        throw new Error("Failed to fetch data");
      }
      setNewPasswords({
        newPassword: " ",
        retypedNewPassword: " ",
      });
      const data = await response.json();
      setBackGroundColor(
        "bg-gradient-to-b from-[#000000a9]   via-[#2A914E]  to-[#000206]"
      );
    } catch (error) {
      handleError();

      setBackGroundColor(
        "bg-gradient-to-b from-[#000000a9]   via-[#fff]  to-[#000206]"
      );
    }
  };

  return (
    <>
      <section className={`${backgroundcolor}`}>
        <div
          className={`text-white px-6 py-1 border-0 rounded-b-sm  mb-3 text-center bg-red-700 shadow-red-950 shadow-md  ${
            !isTrueForError ? "invisible" : "block"
          }`}
        >
          <span className="text-lg inline-block ">
            <i className="fas fa-bell" />
          </span>
          <span className="inline-block  text-sm">
            <p className=" text-lg inline-flex"></p> unable to change password.
          </span>
          <button className="absolute  bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"></button>
        </div>
        <div className="flex  min-h-screen flex-1 flex-col  pt-32 px-6  lg:px-8">
          <div className="sm:mx-auto sm:w-full justify-center sm:max-w-sm">
            <img className="mx-auto h-10 w-auto" src={logo} alt="Company" />
            <h2 className=" mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-50">
              Reset Your Password
            </h2>
          </div>
          <div className="justify-center mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              className=" space-y-6 justify-center "
              action="#"
              method="POST"
            >
              <div>
                <p className="text-[8px] pl-2 text-gray-300 leading-none mt-2 mb-[3px]">
                  strong and secure password.{" "}
                </p>

                <input
                  onChange={handleInputChange}
                  id="password"
                  name="newPassword"
                  type="password"
                  autoComplete="password"
                  required
                  placeholder="password"
                  className="inputStyle3  w-full  focus:outline-none  inputPlaceHolder flex  font-semibold  "
                />
                <p className="text-[8px] pl-2 text-gray-300 leading-none mt-2 mb-[3px]">
                  Re-enter your new password.{" "}
                </p>

                <input
                  onChange={handleInputChange}
                  id="secondPassword"
                  name="retypedNewPassword"
                  type="password"
                  autoComplete="password"
                  required
                  placeholder="Re-enter Password"
                  className="inputStyle3  w-full  focus:outline-none  inputPlaceHolder flex  font-semibold  "
                />

                <p className="text-[10px] pl-2 text-gray-300">
                  Please enter your new password twice for confirmation.
                </p>
              </div>

              <div>
                <button
                  onClick={resetNewPassword}
                  disabled={btnSubmit}
                  className="flex w-full mx-auto  justify-center rounded-md px-8 py-1.5 text-xs font-semibold leading-6 text-white shadow-sm bg-orange-600 hover:bg-orange-500 focus-visible:outline "
                >
                  {" "}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
