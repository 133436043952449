import React from "react";
import { features } from "../../constants";
import styles, { layout } from "../../style";
import Button from "../Button";
import { Link } from "react-router-dom";

const FeatureCard = ({ icon, title, content, index }) => (
  <div
    className={`flex flex-row p-6 rounded-[20px]   ${
      index !== features.length - 1 ? "mb-6" : "mb-0"
    } feature-card`}
  >
    <div
      className={`w-[64px] h-[64px] rounded-full  ${styles.flexCenter}  rounded-md bg-white/5 a ring-1 ring-white/10 `}
    >
      <img src={icon} alt="icon" className="w-[50%] h-[50%] object-contain " />
    </div>
    <div className=" flex-1 flex flex-col ml-3">
      <h4 className="  text-[18px] leading-[23px] font-semibold text- ">
        {title}
      </h4>
      <p className=" text-dimWhite  text-[16px] leading-[23px] font-normal">
        {content}
      </p>
    </div>
  </div>
);
const Business = () => {
  return (
    <>
      <section id="features" className={layout.section}>
        <div className={layout.sectionInfo}>
          <h2
            className={`leading-tight text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl  `}
          >
            {" "}
            Welcome to Above Ground Solutions,{" "}
            <br className=" sm:block hidden" />
          </h2>
          <p
            className={`${styles.paragraph} text-gray-200 max-w-[470px] mt-5]`}
          >
            We{" "}
            <span className=" text-[#FF5D00] font-semibold ">prioritize</span>{" "}
            understanding the unique needs of our clients, ensuring that our
            telecom solutions are{" "}
            <span className=" text-[#FF5D00] font-semibold ">tailored</span> to
            their specific requirements. Whether it's network upgrades,
            installations, or maintenance, we are committed to delivering{" "}
            <span className=" text-[#FF5D00] font-semibold ">reliable</span> and
            efficient services that exceed expectations.
          </p>
        </div>
        <div className={`${layout.sectionImg} flex-col`}>
          {features.map((feature, index) => (
            <FeatureCard key={feature.id} {...feature} index={index} />
          ))}
        </div>
      </section>
    </>
  );
};

export default Business;
