import React, { useEffect } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import styles from "../style";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_SIGNED_JSA } from "../utils/queries";
import StackedListUsers from "./StackedListUsers";
import { FaFileContract } from "react-icons/fa6";

const SignedJSA = () => {
  const { data, loading, refetch } = useQuery(QUERY_SIGNED_JSA, {
    variables: { email: "email." },
  });
  useEffect(() => {
    refetch();
  });
  return (
    <>
      <section className={`   mx-auto flex min-h-screen flex-col`}>
        <div className="col-span-4 bg-[#000206] flex items-center w-full">
          <Link to={"/jsaCreator"} className="icon  md:ml-5 xl:ml-32 ">
            <FaLongArrowAltLeft size={20} className="icon pb-1 hover:h-[.1%]" />
          </Link>
        </div>

        <div className="bg-[#000206] shadow-md shadow-[#070b0f]">
          <div className={`${styles.paddingX} ${styles.boxWidth} mx-auto mt-2`}>
            <div className=" sm:px-0 pt-2 ">
              <h3 className="text-base font-semibold leading-6 ">
                All Signed Forms{" "}
              </h3>
            </div>

            <dl className=" pl-1 text-gray-200">
              <div className=" grid grid-cols-3 invisible gap-4 px-0">
                <dt className="text-xs font-medium leading-6 text-gray-200  ">
                  you have signed: 17 forms
                </dt>
              </div>

              <div className=" grid grid-cols-3  px-0"></div>
            </dl>
            <p className="text-[8px] text-gray-100 leading-[10px] my-2 mt-10">
              {" "}
              Below, you'll discover a list of all previous JSAs that have been
              signed.
              <br /> Alternatively, if you wish to create a new JSA, simply
              click the inviting orange button on the jsaCreator page.
            </p>
          </div>

          <></>
        </div>

        <div
          className={` ${styles.paddingX} ${styles.boxWidth} mx-auto  mt-5  text-md leading-10 `}
        >
          <ul className="  pb-2 grid gap-2">
            {!data
              ? "Loading..."
              : data.querysignedJSA.signedJSA.map((item, i) => {
                  return (
                    <>
                      <Link to={`/one/${item._id}`}>
                        <li
                          number={i}
                          name={item._id}
                          className="flex px-2 border-[3px] border-black rounded-md bg-[#0E0E0E] justify-between gap-x-6 py-2"
                        >
                          <div className="flex min-w-0 gap-x-4">
                            <FaFileContract
                              className="h-12 w-12 flex-none  shadow-black "
                              alt=" signed document"
                            />
                            <div className="min-w-0 w-36 cursor-default">
                              <p className="text-sm font-semibold leading-6 text-gray-100  w-fit  rounded-md ">
                                Created
                              </p>
                              <p className="mt-1 truncate text-xs leading-5 text-gray-300">
                                {item.date}
                              </p>
                            </div>
                          </div>
                          <div className=" shrink-0 flex flex-col items-end">
                            <p className="text-sm  leading-6 text-gray-400">
                              team
                            </p>

                            <div className="flex items-center my-auto gap-x-1.5 ">
                              <StackedListUsers
                                crewMembers={item.chosenCrewMembers}
                                key={i}
                              />
                            </div>
                          </div>
                        </li>
                      </Link>
                    </>
                  );
                })}
          </ul>
        </div>
      </section>
    </>
  );
};

export default SignedJSA;
