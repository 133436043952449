import React, { useEffect } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import styles from "./style";
import "./index.css";
import JsaPage from "./pages/JsaPage";
import { Navbar, Footer, Hero, Login, CreateJSA } from "./components";
import ErrorPage from "./pages/ErrorPage";
import QuestionsForJsa from "./components/QuestionsForJsa";
import Modals from "./components/Modals";
import FriendsSearch from "./components/FriendsSearch";
import IndiviualJsa from "./components/IndiviualJSA";
import ForgotPassword from "./components/forgotPassword";
import JsaEmailVerify from "./components/JsaEmailVerify";
import ProductFeatures from "./components/ProductFeatures";
import ResetPassword from "./components/resetPassword";
import UserPage from "./components/UserPage";
import ProfilePage from "./components/ProfilePage";
import SignedJSA from "./components/SignedJSA";
import JobBoard from "./components/Jobs/JobBoard";
import CreateNewCompany from "./components/CreateNewCompany";
import OneCompanyPage from "./components/OneCompanyPage";
import EditCompanyPage from "./components/EditCompanypage";
import ContactUs from "./components/ContactUs";
import CultureAndValues from "./components/CultureAndValues";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import CivilWorkPage from "./components/CivilWorkPage";
import TowerServicePage from "./components/TowerService";
import MaintenanceService from "./components/MaintenaceService";
import UserCerts from "./components/UserCerts";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "./scenes/index.css";
import ClockIn from "./components/clockIn";
import JobDescription from "./components/Jobs/TopHand";
import JobForm from "./components/Jobs/JobForm";
import Apprentice from "./components/Jobs/Apprentice";

// import forgotPassword from "./components/forgotPassword";

const httpLink = createHttpLink({
  uri: "/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("id_token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const App = () => {
  const [theme, colorMode] = useMode();

  useEffect(() => {
    const storedLockStatus = localStorage.getItem("isActiveJSA");
    if (!storedLockStatus) {
      return;
    }

    // Check if the current pathname already includes the storedLockStatus
    if (!window.location.pathname.includes(`one/${storedLockStatus}`)) {
      window.location.href = `one/${storedLockStatus}`;
    }
  }, []);
  return (
    <ApolloProvider client={client}>
      <Router>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {/* <div className="app">
              <SideBar />
              <main className="content">
                <TopBar />
                <Routes>
                  <Route path="/" element={<DashBoard />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/invoices" element={<Invoices />} />

                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/line" element={<Line />} />

                  <Route path="/calendar" element={<Calendar />} />
                </Routes>
              </main>
            </div> */}
            <div className=" w-full text-lg overflow-hidden  ">
              <div
                className={`${styles.paddingX} ${styles.flexCenter}  bg-[#000206] shadow-black  `}
              >
                <div className={`${styles.boxWidth}  `}>
                  <Navbar />
                </div>
              </div>
              <Routes>
              <Route path="/:position/JobForm" element={<JobForm />} />

                <Route path="/clockIn" element={<ClockIn />} />
                <Route path="/termsOfService" element={<TermsOfUse />} />
                <Route path="certs/user" element={<UserCerts />} />
                <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/features" element={<ProductFeatures />} />
                <Route path="/" element={<Hero />} />
                <Route path="contactUs" element={<ContactUs />} />
                <Route exact path="/404" element={<ErrorPage />} />
                <Route path="Login" element={<Login />} />
                <Route path="/jsaCreator" element={<JsaPage />} />
                <Route path="jsa/:id" element={<CreateJSA />} />
                <Route path="questionsJSA" element={<QuestionsForJsa />} />
                <Route path="/modal" element={<Modals />} />
                <Route path="/Tech/JobDescription" element={<Apprentice />} />

                <Route path="/TopHand/JobDescription" element={<JobDescription />} />
                <Route path="/search/friends" element={<FriendsSearch />} />
                <Route path="one/:id" element={<IndiviualJsa />} />
                <Route path="/verify/:id" element={<JsaEmailVerify />} />
                <Route path="/user" element={<UserPage />} />
                <Route path="/forgot" element={<ForgotPassword />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="SignedJSA" element={<SignedJSA />} />
                <Route path="/jobOpportunities" element={<JobBoard />} />
                <Route path="/*" element={<Hero />} />
                <Route path="/Company/:id" element={<OneCompanyPage />} />
                <Route path="/civilWork" element={<CivilWorkPage />} />
                <Route path="/towerService" element={<TowerServicePage />} />
                <Route
                  path="/reset-password/:id/:token"
                  element={<ResetPassword />}
                />
                <Route
                  path="/createNewCompany"
                  element={<CreateNewCompany />}
                />
                <Route path="/EditCompany/:id" element={<EditCompanyPage />} />
                <Route
                  path="/cultureAndValues"
                  element={<CultureAndValues />}
                />
                <Route
                  path="/maintenanceService"
                  element={<MaintenanceService />}
                />
              </Routes>

              <Footer />
            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </Router>
    </ApolloProvider>
  );
};

export default App;
