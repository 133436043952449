import React, { useEffect } from "react";
import photo1 from "../assets/IMG14.jpg";
import photo2 from "../assets/photo3.PNG";
import photo3 from "../assets/IMG12.png";
import photo4 from "../assets/IMG13.jpg";

const ProductFeatures = () => {
  const people = [
    {
      name: "Jocelyn  Garcia",
      role: "Co-Founder / CEO",
      imageUrl:
        "https://media.licdn.com/dms/image/D5603AQEh83BFjtB0yQ/profile-displayphoto-shrink_200_200/0/1665421484257?e=1704326400&v=beta&t=eDxdWHpHcHG4JV-OiBe3I9v0ACu1J6qsQLQpLezHf7U",
    },
    {
      name: "Antonio  Garcia",
      role: "Co-Founder / CEO",
      imageUrl:
        "https://images.unsplash.com/photo-1676938778746-ece60f8b04cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
    },
    {
      name: "Carlos Garcia",
      role: "Founder / CEO",
      imageUrl:
        "https://media.licdn.com/dms/image/C5603AQGTTU-Mh9Sfxg/profile-displayphoto-shrink_200_200/0/1601425283555?e=1704326400&v=beta&t=VXwBhXJX84RBOkWIk8fyglSKyVn4QG6RK5xRmjNfShE",
    },
    {
      name: "Matthew Garica",
      role: "Web Dev.",
      imageUrl:
        "https://media.licdn.com/dms/image/D5603AQFwQYjvmXlO7g/profile-displayphoto-shrink_200_200/0/1697788692404?e=1703116800&v=beta&t=8ixhpRfdIbqB0rUHg_wCrAaEJQnlpJ2Muoh8ozYsN00",
    },
    // More people...
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <section className="pb-20  cursor-default  bg-[#00040F]  v">
          <div className="mx-auto  grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-4 sm:px-6 md:py-255 sm:py-22 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div className="mt-5 ">
              <h2 className="mt-[100px]  text-3xl font-bold tracking-tight sm:text-4xl">
                AGS BETA{" "}
              </h2>
              <p className="mt-4 text-gray-200 leading-6">
                Empower Your Workplace Safety with Above Ground Solutions' JSA
                Application - Streamline JSA Creation and Enhance Workplace
                Safety Effortlessly.{" "}
              </p>
              <dl className=" mt-10 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-2 sm:gap-y-5 lg:gap-x-8">
                <div className="border-t border-gray-300 pt-4">
                  <dt className="font-medium text-gray-200"> Includes</dt>
                  <dd className="mt-2 text-sm text-gray-300">
                    {" "}
                    comes with 500 free Jsa pages
                  </dd>
                </div>
                <div className=" border-t border-gray-400 pt-4">
                  <dt className="font-medium text-gray-200"> Download</dt>
                  <dd className="mt-2 text-sm text-gray-300">
                    Download JSAs with a Single Click!{" "}
                  </dd>
                </div>

                <div class="border-t border-gray-200 pt-4">
                  <dt class="font-medium text-gray-200">Emergency Ready</dt>
                  <dd class="mt-2 text-sm text-gray-300">
                    {" "}
                    No Need to Hunt for Documents When You Need Them Most!
                  </dd>
                </div>
                <div class="border-t border-gray-200 pt-4">
                  <dt class="font-medium text-gray-200">Origin</dt>
                  <dd class="mt-2 text-sm text-gray-300">
                    {" "}
                    Designed by Above Ground Solutions, Inc{" "}
                  </dd>
                </div>
                <div class="border-t border-gray-200 pt-4">
                  <dt class="font-medium text-gray-200">Considerations</dt>
                  <dd class="mt-2 text-sm text-gray-300">
                    No worries about it getting lost or misplaced!
                  </dd>
                </div>
                <div class="border-t border-gray-200 pt-4">
                  <dt class="font-medium text-gray-200">Considerations</dt>
                  <dd class="mt-2 text-sm text-gray-300">
                    Say Goodbye to Pen and Paper - Never Worry About Forgetting
                    Them Again!
                  </dd>
                </div>
              </dl>
            </div>
            <div className="grid lg:mt-[100px]  grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
              <img
                src={photo1}
                alt="img of what the company offers"
                class="rounded-lg bg-slate-800 "
              />
              <img
                src={photo2}
                alt="img of what the company offers"
                class="rounded-lg bg-slate-800"
              />
              <img
                src={photo3}
                alt="img of what the company offers"
                class="rounded-lg bg-slate-800"
              />
              <img
                src={photo4}
                alt="img of what the company offers"
                class="rounded-lg bg-slate-800"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProductFeatures;
