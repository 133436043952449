import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import "../loader.css";
export default function LoaderModal() {
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  return <div class="loader w-full mx-auto"></div>;
}
