import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// try {

//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker
//     .register('../sw.js')
//     .then((register) => console.log({ register }));
//   }
// } catch (error) {
//   console.log(console.error());
// }
