import React from "react";
import styles, { layout } from "../../style";
import Button from "../Button";
import { Link } from "react-router-dom";
const EmployeeTrainingBanner = () => {
  return (
    <section className="h-[110px] w-full xs:h-[120px] sm:h-[200px] md:h-[240px] lg:h-[270px] xl:h-[330px] flex items-center justify-center ">
      <div>
        <div className="absolute z-[3] w-[50%] rounded-full" />
        <div className={`text-center`}>
          <div className="flex flex-row sm:mt-10 mt-6 flex-wrap"></div>
          <div className={``}>
            <div className="flex-1 flex flex-col">
              <h2 className="text-2xl md:text-4xl text leading-tight  font-bold">
                Employee Training
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmployeeTrainingBanner;
