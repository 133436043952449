import {
  BanknotesIcon,
  ChartPieIcon,
  BoltIcon,
  ClockIcon,
} from "@heroicons/react/24/solid";

export default function EstimateService() {
  return (
    <div className="relative isolate overflow-hidden py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-7 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Personalize and Create Estimates
            </h2>
            <p className="mt-4 text-xl  text-gray-200">
              We understand the importance of clear estimates in the telecom
              industry. Our team is dedicated to providing you with customized
              estimates.
            </p>
            <div className="mt-6 flex max-w-md gap-x-4">
              {/* <a
                               href='/estimate'
                                className="flex-none rounded-md bg-blue-600 px-4 py-3 text-center text-xs font-medium text-white shadow-sm hover:bg-blue-500 "
                            >
                                Start Estimate
                            </a> */}
            </div>
          </div>
          <dl className="grid grid-cols-2 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <BanknotesIcon
                  className="h-6 w-6 text-orange-500"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold text-white">
                Cost Calculations
              </dt>
              <dd className=" text-dimWhite  text-[16px] leading-[23px] font-normal">
                Tailored to your unique project requirements.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <ClockIcon
                  className="h-6 w-6 text-orange-500"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold text-white">
                Project Timeline{" "}
              </dt>
              <dd className=" text-dimWhite  text-[16px] leading-[23px] font-normal">
                Anticipated Completion Times.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <BoltIcon
                  className="h-6 w-6 text-orange-500"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold text-white">Efficiency</dt>
              <dd className=" text-dimWhite  text-[16px] leading-[23px] font-normal">
                Estimating Made Accessible for devices.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <ChartPieIcon
                  className="h-6 w-6 text-orange-500"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold text-white">Product Details</dt>
              <dd className=" text-dimWhite  text-[16px] leading-[23px] font-normal">
                Comprehensive Breakdown, Pricing Included.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
