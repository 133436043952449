import React, { useState } from "react";
import { close, menu } from "../assets";
import logomatthew2 from "../assets/logomatthew2.png";

import { Link } from "react-router-dom";
import Auth from "../utils/auth";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const handleLogout = (mobile) => {
    Auth.logout();
  };
  const handleLogoClick = () => ({});

  return (
    <nav className=" text-[16px]   w-full py-3 sm:py-5 flex z-60 justify-between   items-center   ">
      <a href={"/"}>
        <img
          onClick={handleLogoClick}
          src={logomatthew2}
          alt="aboveGround Logo"
          className="w-[32px] h-[30px] lg:w-[42px] lg:h-[40px] my-[-9px] "
        ></img>
      </a>
      <ul className="list-none sm:flex hidden  justify-end items-center flex-1">
        <Link to={`/features`} className={`mr-10  `}>
          Features
        </Link>

        <Link to={`/jsaCreator`} className={`mr-10`}>
          Jsa Creator
        </Link>
        <Link to={`/contactUs`} className={`mr-10`}>
          Contact Us
        </Link>

        {Auth.loggedIn() ? (
          <>
            <Link to={`/timeTrack`} className={`mr-10`}>
              Time Track
            </Link>
            <Link
              onClick={() => {
                handleLogout();
              }}
              className={`    `}
            >
              Logout
            </Link>
          </>
        ) : (
          <>
            <Link to={"/Login"} className={`  `}>
              Login
            </Link>
          </>
        )}
      </ul>
      <div className=" sm:hidden   flex flex-1 justify-end items-center">
        <img
          src={toggle ? close : menu}
          alt="menu"
          className="w-[20px] z-50 h-[20px] object-contain "
          onClick={() => setToggle((prev) => !prev)}
        />
        <div
          className={`${
            toggle ? " " : "hidden"
          } bg-black/60  border-b-3 mt-[-80px] z-40  absolute top-20 right-0  min-h-screen w-[50%]  rounded-xl  `}
        >
          <ul className="list-none flex flex-col     text-center  rounded-xl p-5 pt-16   justify-end items-center flex-1">
            <Link
              to={`/features`}
              onClick={() => setToggle((prev) => !prev)}
              className={`  mb-10  `}
            >
              Features
            </Link>
            <Link
              to={`/jsaCreator`}
              onClick={() => setToggle((prev) => !prev)}
              className={` w-full  mb-10  `}
            >
              Jsa Creator
            </Link>
            <Link
              to={`/contactUs`}
              onClick={() => setToggle((prev) => !prev)}
              className={` w-full  mb-10  `}
            >
              Contact Us
            </Link>

            {Auth.loggedIn() ? (
              <>
                <Link
                  to={`/timeTracker`}
                  onClick={() => setToggle((prev) => !prev)}
                  className={` w-full  mb-10  `}
                >
                  Time Tracker
                </Link>

                <Link
                  onClick={() => {
                    handleLogout();
                  }}
                  className="align-bottom -scroll-mt-36"
                >
                  Logout
                </Link>
              </>
            ) : (
              <>
                <Link onClick={() => setToggle((prev) => !prev)} to={"/Login"}>
                  Login
                </Link>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
