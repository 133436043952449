import { send, shield, star } from "../assets";

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Quality",
    content:
      "Our team is committed to delivering top-notch telecommunications solutions in a fast-paced industry.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Safety",
    content:
      "Safety and well-being of telecom workers are paramount through proactive measures and best practices.",
  },

  {
    id: "feature-3",
    icon: send,
    title: "fast",
    content:
      "Our commitment to rapid deployment sets us apart in the telecommunications industry.",
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "Southern California(SoCal)",
    value: "AREA",
  },
  {
    id: "stats-2",
    title: "Telecommunications",
    value: "JOB",
  },
  {
    id: "stats-3",
    title: "Year 2020",
    value: "SINCE",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Content",
        link: "https://www.hoobank.com/content/",
      },
      {
        name: "How it Works",
        link: "https://www.hoobank.com/how-it-works/",
      },
      {
        name: "Create",
        link: "https://www.hoobank.com/create/",
      },
      {
        name: "Explore",
        link: "https://www.hoobank.com/explore/",
      },
      {
        name: "Terms & Services",
        link: "https://www.hoobank.com/terms-and-services/",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Help Center",
        link: "https://www.hoobank.com/help-center/",
      },
      {
        name: "Partners",
        link: "https://www.hoobank.com/partners/",
      },
      {
        name: "Suggestions",
        link: "https://www.hoobank.com/suggestions/",
      },
      {
        name: "Blog",
        link: "https://www.hoobank.com/blog/",
      },
      {
        name: "Newsletters",
        link: "https://www.hoobank.com/newsletters/",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "https://www.hoobank.com/our-partner/",
      },
      {
        name: "Become a Partner",
        link: "https://www.hoobank.com/become-a-partner/",
      },
    ],
  },
];
