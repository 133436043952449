import { useEffect, useRef, useState } from "react";
import TimeTable from "./TimeTable";
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import Timer from "./Timer";

const StopWatch = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [startTimeLabel, setStartTimeLabel] = useState(null);
  const [lapTimeLabel, setLapTImeLabel] = useState(null);
  const [breaks, setBreaks] = useState([

  ]);
  const [stopTimeLabel, setStopTimeLabel] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const intervalIdRef = useRef(null);

  const startTimeRef = useRef(0);
const [color,setColor]= useState()
useEffect(() => {
  const checkReload = () => {
    const reloaded = localStorage.getItem('reloaded');
    if (reloaded && localStorage.getItem('s')) {
      // alert('The page was reloaded');
      const keu = localStorage.getItem('s')
      const clockIn = localStorage.getItem('start')
      setStartTimeLabel(clockIn)
      setElapsedTime(keu)

      localStorage.removeItem('reloaded');
    }
  };

  // Set the flag before the page unloads
  const handleBeforeUnload = () => {
    localStorage.setItem('reloaded', 'true');
  };

  // Check if the page was reloaded on component mount
  checkReload();

  // Add event listener for beforeunload event
  window.addEventListener('beforeunload', handleBeforeUnload);

  // Cleanup event listener on component unmount
  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, []);
useEffect(()=>{

  localStorage.setItem('s',JSON.stringify(elapsedTime));
  const hi = localStorage.getItem('s')
  
  if(hi === elapsedTime){
    console.log('hi');
  }
},[elapsedTime])

  function start() {
    setIsRunning(true);
    const date = new Date();
    let hours = String(date.getHours());
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, "0");
    const formattedDate = `${formattedHours}:${minutes}${ampm}`;
    setStartTimeLabel(formattedDate);
    startTimeRef.current = Date.now() - elapsedTime;
    const newBreak = { title: 'Clocked in: ', time: `${formattedDate}` };
    if(!localStorage.getItem('start')){

      localStorage.setItem('start',JSON.stringify(elapsedTime));
    }

    // Update the breaks state
    setBreaks(prevBreaks => [...prevBreaks, newBreak]); 
   }
  
  function stop() {
    const date = new Date();
    let hours = String(date.getHours());
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";

    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, "0");

    const formattedDate = `${formattedHours}:${minutes}${ampm}`;
    setLapTImeLabel(formatTime());
    setStopTimeLabel(formattedDate);
    setIsRunning(false);
    const newBreak = { title: 'Break: ', time: `${formattedDate}` };
    
    // Update the breaks state
    setBreaks(prevBreaks => [...prevBreaks, newBreak]);  
  }
  

  async function reset() {
    alert("you worked for this long:", formatTime());
    setElapsedTime(0);
    setIsRunning(false);

  }

  function formatTime() {
    let hours = Math.floor(elapsedTime / (1000 * 60 * 60));
    let minutes = Math.floor((elapsedTime / (1000 * 60)) % 60);
    let seconds = Math.floor((elapsedTime / 1000) % 60);
    hours = String(hours).padStart(2, "0");
    minutes = String(minutes).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }
  return (
    <div className="w-full  ">
      <Box
        backgroundColor={colors.primary[400]}
        // borderBottom={`3px  solid ${colors.primary[600]}`}
        className={`  p-10 text-sm text-center content-center mx-auto  h-40 `}
      >
        <Timer formatTime={formatTime} isRunning={isRunning} setElapsedTime={setElapsedTime} intervalIdRef={intervalIdRef} startTimeRef={startTimeRef}/>
      </Box>

      <TimeTable
        startTimeLabel={startTimeLabel}
        stopTimeLabel={stopTimeLabel}
        lapTimeLabel={lapTimeLabel}
        timeLine ={breaks}
      />
      <Box


        className="gap-5 w-full p-10  absolute bottom-0 grid grid-cols-1 grid-rows-2 py-  justify-center font-bold px-5"
        backgroundColor={colors.primary[400]}
      >{elapsedTime}
        {!isRunning ? (
          <button
            onClick={start}
            className=" bg-blue-500 border-blue-600 border-2 px-4 py-2 rounded-lg"
          >
            Clock in
          </button>
        ) : (
          <button
            onClick={stop}
            className={` bg-yellow-500 border-yellow-600 border-2 px-4 py-2 rounded-lg`}
          >
            Start Break
          </button>
        )}

        <button
          onClick={elapsedTime ? reset : null}
          className={`${
            elapsedTime
              ? "bg-red-500 border-red-600 "
              : "bg-red-300 border-red-400 "
          }  border-red-600  border-2 px-4 py-2 rounded-lg "`}
        >
          {/* Reset */}
          Clock Out
        </button>
      </Box>
    </div>
  )
}
export default StopWatch;
