import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaSeedling } from "react-icons/fa6";
import { useMutation } from "@apollo/client";
import { DELETE_JSA_FILE } from "../utils/mutations";
import { useNavigate } from "react-router-dom";

export default function Modals({ jsaID, title, ToggleView }) {
  const [mutationDeleteFile, { error, loading }] = useMutation(DELETE_JSA_FILE);
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  const history = useNavigate();

  const deleteJsaFile = async () => {
    try {
      const { data } = await mutationDeleteFile({
        variables: { jsaPageId: jsaID },
      });
      console.log(data);
      history("/jsaCreator");
    } catch (error) {}

    ToggleView();
  };

  return (
    <div show={open} as={Fragment}>
      <div
        as="div"
        className="relative z-10 bg"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-900 bg-opacity-75 transition-opacity" />
        </div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative transform overflow-hidden rounded-lg shadow-black text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-[#000206] px-4 pb-4 shadow-black pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div
                        as="h3"
                        className="text-base font-bold leading-6 text-red-600"
                      >
                        {title}
                      </div>
                      <div className="mt-2">
                        <p className="text-sm text-[#C9C9C9]">
                          <span className="font-semibold ">
                            Warning: Deleting this file is permanent!
                          </span>
                          <br></br>
                          Once you confirm the deletion, the file will be
                          permanently removed from the system, and there is no
                          way to recover it.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-[#000206]  px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600/90 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      deleteJsaFile();
                    }}
                  >
                    DELETE
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white/95 hover:bg-white/80 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={ToggleView}
                    ref={cancelButtonRef}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
