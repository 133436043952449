import React, { useEffect, useState } from "react";
import styles from "../style";

import { FaCheck, FaPersonCirclePlus } from "react-icons/fa6";
import { IoSend } from "react-icons/io5";
import LoaderModal from "./LoaderModal";
import { FaStarHalfAlt, FaTimes } from "react-icons/fa";
import { useMutation } from "@apollo/client";
import { UPDATE_CLOCK_OUT, UPDATE_JSA_TASK_PROGRESS } from "../utils/mutations";

const IndiviualDetailsJsa = ({
  loading,
  data,
  addNewUser,
  setNewUserEmail,
  UserData,
  addUserToJSA,
  txtInvalidEmail,
  id,
  newUserEmail,
  invalidEmail,
}) => {
  const [updateClockOut, { loading: Loading }] = useMutation(UPDATE_CLOCK_OUT);
  const [setJobTasks, setSetJobTasks] = useState([]);
  const [dataLoad, setDtatLoad] = useState(0);
  const [updateJsaProgress, { error }] = useMutation(UPDATE_JSA_TASK_PROGRESS);
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    const storedLockStatus = localStorage.getItem("isActiveJSA");
    if (!storedLockStatus) {
      return;
    }
    setLocked(true);
  }, []);

  useEffect(() => {
    if (!data) {
      setDtatLoad(dataLoad + 1);
      return;
    }
    if (setJobTasks.length > 0) {
      return;
    }
    if (data) {
      setSetJobTasks(data.showEmbedJsa.JobProgress);
    }
  }, [dataLoad]);

  const handleClick = async (key) => {
    if (key >= 0 && key < setJobTasks.length) {
      const updatedTasks = setJobTasks.map((task, i) => {
        if (i === key) {
          const newProgress = (parseInt(task.progress) + 1) % 3;
          return { ...task, progress: newProgress.toString() };
        }
        return task;
      });

      setSetJobTasks(updatedTasks);
      return;
    } else {
      console.error(`Invalid key: ${key}`);
      return;
    }
  };
  const handleClockOut = async () => {
    try {
      const { data } = await updateClockOut({
        variables: { jsaPageId: id, clockOut: "cleanedJobTasks" },
      });
      localStorage.removeItem("isActiveJSA");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (setJobTasks.length === 0) {
      return;
    }
    const newupdated = setJobTasks;
    const fetchData = async () => {
      const newupdated = setJobTasks;
      const cleanedJobTasks = setJobTasks.map(
        ({ __typename, ...rest }) => rest
      );

      try {
        const { data } = await updateJsaProgress({
          variables: { jsaPageId: id, JobProgress: cleanedJobTasks },
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [setJobTasks]);

  return (
    <>
      {!loading ? (
        <section className="rounded-sm bg-[#00040fd6]  mx-auto py-5  grid md:grid-cols-2 ">
          <>
            <div
              className={` ${styles.boxWidth} ${styles.paddingX} col-span-2 mx-auto`}
            >
              <div
                className={` ${styles.boxWidth} ${styles.paddingX} bg- col-span-2   mx-auto`}
              >
                <h5 className="pl-1 text-[12px] font-semibold leading-3">
                  {" "}
                  Job Task Progress{" "}
                </h5>
                <ul role="list" className="   grid gap-4 rounded p-3">
                  {setJobTasks.map((person, key) => (
                    <li
                      data-name={key}
                      onClick={() => handleClick(key)}
                      className={`flex px-2 ${
                        person.progress == "2"
                          ? "bg-green-300 ring-green-700"
                          : person.progress === "0"
                          ? "bg-red-300 ring-red-700"
                          : "bg-yellow-300 ring-yellow-700"
                      }  rounded-lg ring-2  justify-between  py-2`}
                    >
                      <div name="hiiiii" className={`flex min-w-0 gap-x-4 `}>
                        <div className="flex-col pt-1">
                          <div
                            className={`h-7 w-7 border-transparent border-4 rounded-full  ${
                              person.progress === "2"
                                ? " bg-green-600"
                                : person.progress === "0"
                                ? "bg-red-600"
                                : "bg-yellow-600"
                            }  flex  items-center justify-center`}
                          >
                            {person.progress === "2" ? (
                              <FaCheck className="font-bold h-[18px] w-[18px]" />
                            ) : person.progress === "0" ? (
                              <FaTimes className="font-bold h-[18px] w-[18px]" />
                            ) : (
                              <FaStarHalfAlt className="font-bold h-[18px] w-[18px]" />
                            )}{" "}
                          </div>
                        </div>
                        <div className="flex flex-wrap w-450 cursor-default">
                          <p
                            className={`text-sm font-semibold  leading-6 truncate w-full text-green-600 ${
                              person.progress === "2"
                                ? " text-green-600"
                                : person.progress === "0"
                                ? "text-red-600"
                                : "text-yellow-600"
                            } `}
                          >
                            {person.progress === "2"
                              ? "Complete"
                              : person.progress === "0"
                              ? "Incomplete"
                              : "Partically"}
                          </p>
                          <p className="  text-xs leading-5 text-gray-800  font-semibold">
                            {person.task}
                          </p>
                        </div>
                      </div>
                      <div className=" shrink-0 flex flex-col items-end">
                        <p className="mt-1 truncate text-xs leading-5 text-gray-200 font-semibold"></p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
          <div
            className={` col-span-2 md:col-span-1 mt-5 ${styles.boxWidth} ${styles.paddingX}  mx-auto md:mx-[0]`}
          >
            {!UserData ? (
              <div
                className={`h-[39px] invisible bg-[#070707fa] rounded-lg  text-gray flex items-center px-2  mb-`}
              ></div>
            ) : (
              <>
                <dt className="text-[12px]   font-medium leading-5 ml-2">
                  Project Personnel
                </dt>
                <p className="text-red-600 text-[10px] ml-2 leading-3 h-2">
                  {" "}
                  {txtInvalidEmail}
                </p>
                <div
                  className={` ' bg-white/5 ${
                    invalidEmail ? " ring-2 ring-red-500" : " "
                  }  rounded-lg  text-gray flex pl-2  my-2`}
                >
                  <div
                    onClick={() => {
                      addUserToJSA();
                    }}
                    className="flex py-3"
                  >
                    <FaPersonCirclePlus
                      color="#c9c9c9"
                      size={15}
                      className="mx-1 "
                    />
                  </div>
                  <input
                    onChange={(e) => setNewUserEmail(e.target.value)}
                    value={newUserEmail}
                    placeholder="type email..."
                    className={` ' ml-2  w-[100%] font-medium bg-transparent text-white/90 text-xs focus:outline-none`}
                  />
                  <div
                    className="flex py-3 w-fit bg-white/5 justify-self-end self-end pl-[2px] pr-2 rounded-r-md "
                    onClick={addNewUser}
                  >
                    <IoSend
                      color="#c9c9c9"
                      size={15}
                      className={`flex cursor-pointer ml-2  bg-transparent text-[#919191] text-sm focus:outline-none`}
                    />
                  </div>
                </div>
              </>
            )}
            <ul role="list" className="  grid">
              {data.showEmbedJsa.chosenCrewMembers.map((person) =>
                person.crewEmail !== " " ? (
                  <li
                    key={person.email}
                    className="flex px-2 border-b border-gray-400 justify-between  py-2"
                  >
                    <div className="flex min-w-0 gap-x-4">
                      <div className="min-w-0 w-40 sm:w-80 cursor-default">
                        <p className="text-sm font-semibold  leading-6 truncate text-gray-100">
                          {person.crewEmail}
                        </p>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-200  font-semibold">
                          <span className="text-green-500">joined:</span>{" "}
                          {person.dateJoined ?? "waiting..."}
                        </p>
                      </div>
                    </div>
                    <div className=" shrink-0 flex flex-col items-end">
                      <p className="text-sm invisible  leading-6 text-gray-400">
                        3
                      </p>

                      <p className="mt-1 truncate text-xs leading-5 text-gray-200 font-semibold">
                        <span className="text-red-500"> out:</span>{" "}
                        {person.dateOut}
                      </p>
                    </div>
                  </li>
                ) : (
                  <li className="flex px-2 h-[65px] items-center border-b border-gray-400   py-2">
                    <p className=" m-auto font-semibold text-gray-200 ">+</p>
                  </li>
                )
              )}
              <dt className="text-[12px]   font-semibold mt-2 ml-2">
                Creator :
              </dt>
              <li className="flex px-2   rounded-md justify-between  pl-1">
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 w-36 cursor-default">
                    <p className="text-sm font-semibold  leading-5 text-gray-100">
                      {data.showEmbedJsa.creatorName}{" "}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5  font-semibold">
                      <span className="text-green-500">created </span>
                    </p>
                  </div>
                </div>
                <div className=" shrink-0 flex flex-col items-end">
                  <p className="text-sm invisible  leading-6 text-gray-400">
                    3
                  </p>
                </div>
              </li>
            </ul>
            <button
              onClick={handleClockOut}
              className={`cursor-pointer ${
                locked ? "" : "invisible"
              }  col-span-4 bg-red-600 mt-10 ring-2 ring-red-900 b rounded-xl w-full text-center content-center text-gray-200   py-2 pl-4 pr-5 text-sm leading-6`}
            >
              <div className="flex items-center justify-between">
                <div className="flex min-w-0 justify-center  text-sm flex-1 gap-2 text-center ] ">
                  <span className="truncate font-bold text-xl ">CLOCK OUT</span>
                </div>
              </div>
            </button>
          </div>
          <iframe
            src={data?.showEmbedJsa.pdfDataUrl}
            width="100%"
            height="760"
            className="mt-5  none mx-auto hidden md:flex  "
          ></iframe>
        </section>
      ) : (
        <LoaderModal />
      )}
    </>
  );
};

export default IndiviualDetailsJsa;
