import React from "react";
import { BsPersonVideo3 } from "react-icons/bs";
import { BsFolderPlus, BsPersonPlusFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const YourPageNav = () => {
  return (
    <div className=" w-[80%] xl:w-[60%] mx-auto">
      <Link to={"/profile"}>
        <div className="mt-4 bg-blue-600    py-3 px-4  rounded-lg  grid grid-cols-2 ">
          <div className="flex flex-col ">
            <h1 className=" text-sm font-bold">Profile</h1>
            <p className="text-xs  font-extralight">Look at your profile</p>
          </div>
          <div className="justify-self-end hover:animate-pulse">
            <BsPersonVideo3 size={30} className=" " />
          </div>
        </div>
      </Link>
      <Link
        to={`/QuestionsJSA`}
        className="mt-4 bg-blue-600    py-3 px-4 rounded-lg  grid grid-cols-2 "
      >
        <div className="flex flex-col">
          <h1 className=" text-sm font-bold">NEW JSA</h1>
          <p className="text-xs  font-extralight">Create a new jsa</p>
        </div>
        <div className=" justify-self-end hover:animate-pulse">
          <BsFolderPlus size={30} className=" " />
        </div>
      </Link>

      <Link
        to={`/signedJSA`}
        className="mt-3 ml-1   text-stone-100  pb-1 w-fit flex px-2   rounded-md bg-white/5  ring-2 ring-white/10 "
      >
        <div className="flex flex-col ">
          <h1 className=" text-[10px] sm:text-[12px]  font-semibold">
            Signed JSAs{" "}
          </h1>

          <p className="text-[8px]  sm:text-[10px] leading-[3px] pb-1  font-semibold">
            view forms you have signed.
          </p>
        </div>
      </Link>
      <div className="pb-2"></div>
    </div>
  );
};

export default YourPageNav;
