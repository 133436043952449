import React from "react";
import JobPositions from "./JobPositions";

const FeaturedJobs = () => {
  return (
    <section>
      <h2 className="text-center text-3xl fonst font-semibold my-12 ">Featured Jobs</h2>
      <JobPositions />
      <p className="py-2 px-4 leading-3 text-gray-300 text-[10px]">
        If you'd like to express your interest in any of the positions listed or
        have questions, please feel free to contact us.
      </p>
    </section>
  );
};

export default FeaturedJobs;
