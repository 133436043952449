import {
  CheckBadgeIcon,
  HeartIcon,
  RocketLaunchIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";
import styles from "../style";
import Services from "./frontPage/Services";

export default function CivilWorkPage() {
  return (
    <section
      className={`bg-[#00040F]  ${styles.paddingX}  ${styles.flexStart}`}
    >
      <section className={`   ${styles.boxWidth}  `}>
        <div className="relative isolate overflow-hidden    bg-[#00040F] text-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
          <div className="absolute inset-0 -z-10   overflow-hidden"></div>
          <div className="mx-auto  grid max-w-2xl grid-cols-1 gap-x-8 gap-y-12 lg:mx-0 lg:max-w-none md:grid-cols-2 lg:items-start lg:gap-y-10">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <p className="text-base font-semibold leading-5 text-orange-600">
                    Civil Contruction Service
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
                    Crafting Quality, Building Trust
                  </h1>
                  <p className="mt-6 leading-6 text-gray-200">
                    AboveGround Solutions is your trusted partner for a wide
                    range of civil construction services, including foundation
                    laying and structure demolition. With a team of experienced
                    professionals and a commitment to excellence, we pride
                    ourselves on delivering outstanding results.
                  </p>
                </div>
              </div>
            </div>
            <div className=" rounded-md mt-  lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 ">
              <img
                className=" rounded-md"
                src={
                  "https://images.unsplash.com/photo-1685464196387-854858ce0f4f?auto=format&fit=crop&q=80&w=987&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
                alt="contruction worker"
              />
            </div>
            <strong className="w-full my-[-10px]  md:col-span-2 text-center">
              Safeguarding Connectivity, Building for Reliability—Drawing from
              Our Journey of Expertise.
            </strong>

            <div className="grid md:col-span-2   xl:w-[70%] mx-auto w-full  ">
              <ul className=" grid grid-cols-2 col-span-2 text-xs ">
                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-l-md ">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Foundation Installation{" "}
                </li>
                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-r-md ">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Tower Erection{" "}
                </li>
                <li className="flex items-center gap-x-3 p-2">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Shelter Construction{" "}
                </li>
                <li className="flex items-center gap-x-3 p-2">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Access Road Construction{" "}
                </li>
                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-l-md ">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Security Measures{" "}
                </li>
                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-r-md ">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600  "
                    aria-hidden="true"
                  />
                  Demolition and Site Cleanup{" "}
                </li>
                <li className="flex items-center gap-x-3 p-2">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Equipment Removal
                </li>
                <li className="flex items-center gap-x-3 p-2">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                  Budget and Project Management{" "}
                </li>
              </ul>
            </div>
            <div className="md:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="max-w-xl text-base leading-7 text-gray-200 lg:max-w-lg">
                  <img
                    className="rounded-md"
                    alt="worker walking"
                    src={
                      "https://images.unsplash.com/photo-1685464197144-790d716b8649?auto=format&fit=crop&q=80&w=1171&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                  <ul role="list" className="mt-8 space-y-2 text-gray-200">
                    <li className="flex gap-x-3 bg-slate-900 p-2 rounded-md ">
                      <HeartIcon
                        className="mt-1 h-5 w-5 flex-none text-orange-600"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-100">
                          Safe
                        </strong>{" "}
                        construction is our top priority, ensuring your project
                        is executed with the highest level of care and
                        protection.Our commitment to safety is unwavering, so
                        you can trust in a secure and successful construction
                        experience.
                      </span>
                    </li>
                    <li className="flex gap-x-3 p-2">
                      <CheckBadgeIcon
                        className="mt-1 h-5 w-5 flex-none text-orange-600"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-100">
                          Quality
                        </strong>{" "}
                        is the cornerstone of our construction services at
                        AboveGround Solutions.We focus on delivering top-quality
                        and precise construction, making sure that we turn your
                        project goals into a smooth reality.
                      </span>
                    </li>
                    <li className="flex gap-x-3 bg-slate-900 p-2 rounded-md">
                      <RocketLaunchIcon
                        className="mt-1 h-5 w-5 flex-none text-orange-600"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-100">
                          Speed
                        </strong>{" "}
                        and efficient construction is our hallmark at
                        AboveGround Solutions.We excel in delivering projects on
                        time.
                      </span>
                    </li>
                  </ul>
                  <p className="mt-8">
                    Our experienced team at AboveGround Solutions is
                    well-equipped to anticipate and prepare for the unforeseen,
                    drawing from our wealth of hands-on experience in the field.
                    We've encountered the challenges, so you don't have to. Our
                    expertise ensures that your project proceeds smoothly, even
                    in the face of unexpected obstacles.
                  </p>
                  {/* <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-100">No server? No problem.</h2>
                                    <p className="mt-6">
                                        Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh.
                                        Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed
                                        tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam
                                        turpis ipsum eu a sed convallis diam.
                                    </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Services />
      </section>
    </section>
  );
}
