import React from "react";

import { FaHouseChimneyMedical, FaUsersBetweenLines } from "react-icons/fa6";
import { Link } from "react-router-dom";

const CompanyNav = () => {
  return (
    <div className=" w-[80%] xl:w-[60%] mx-auto pb-4 ">
      {/* <ProfilePage/> */}
      <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="col-span-full cursor-default ">
          <div className="mt-2 flex items-center gap-x-3 text-gray-100">
            <FaUsersBetweenLines size={40} className="mt-3" />

            <p className=" w-full font-bold border-b-2 mt-1 cursor-default">
              Company Pages{" "}
            </p>
          </div>
          <p className="text-[10px] text-gray-200">
            create a company invite users!
          </p>
          <Link to={"/user"}> </Link>
        </div>
      </div>

      <Link
        to={`/createNewCompany`}
        className="mt-4 bg-blue-600    py-3 px-4  rounded-lg  grid grid-cols-2 "
      >
        <div className="flex flex-col">
          <h1 className=" text-sm font-bold">NEW COMPANY</h1>
          <p className="text-xs  font-extralight">Create new Comp</p>
        </div>
        <div className=" justify-self-end hover:animate-pulse">
          <FaHouseChimneyMedical size={30} className=" " />
        </div>
      </Link>

      <p className="text-[8px]  md:text-[10px] leading-  py-2 pl-2">
        {/* Create New Companies and invite users or join Companies */}
      </p>
    </div>
  );
};

export default CompanyNav;
