import React from "react";
import { FaGithub, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className=" cursor-default mx-auto pt-3 px-4 grid lg:grid-cols-3 gap- text-gray-200 z-10 bg-[#000206]">
      <h1 className="w-full text-xl md:text-2xl font-bold m-2  ">
        Above Ground Solutions
      </h1>
      <div className="md:flex grid text-base font-extralight gap-4 w-full col-span-3  leading-10 m items-center text-stone-300    ">
        <a
          href="https://www.aboveground.solutions/privacyPolicy"
          className="pt-1 text-sm pl-4 "
        >
          Privacy Policy
        </a>
        <a
          href="https://www.aboveground.solutions/termsOfService"
          className="pt-1 text-sm pl-4  "
        >
          Terms of Use{" "}
        </a>

        <a
          href="https://www.aboveground.solutions/jobOpportunities"
          className="pt-1 text-sm pl-4  "
        >
          Careers{" "}
        </a>
        <a
          href="https://www.aboveground.solutions/cultureAndValues"
          className="pt-1 text-sm pl-4  "
        >
          Culture and Values{" "}
        </a>
      </div>
      <div>
        <div className="flex text-[#2d4f67]  gap-5   mt-4 ml-4 ">
          <Link
            aria-label="Linkedin"
            to={`https://www.linkedin.com/in/abovegroundsolutions/`}
          >
            <FaLinkedinIn size={25} alt="Linkedin" />
          </Link>
          <FaTwitter size={25} alt="Twitter" />
          <Link aria-label="Github" to={"https://github.com/heav3n21"}>
            <FaGithub size={25} alt="Git Hub" />
          </Link>
        </div>
        <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span class="text-sm text-gray-300 sm:text-center ">
            © 2023{" "}
            <a
              href="https://www.aboveground.solutions/"
              alt="Above Ground Website"
              class="hover:underline"
            >
              Above Ground™
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
