import React, { useEffect, useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaLongArrowAltLeft } from "react-icons/fa";

import { Link } from "react-router-dom";
import styles from "../style";
import { QUERY_SEARCH_USER } from "../utils/queries";
import { ADD_FRIENDS } from "../utils/mutations";
import { QUERY_USER_FRIENDS } from "../utils/queries";
import Auth from "../utils/auth";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import ErrorPage from "../pages/ErrorPage";
import { MdLibraryAddCheck } from "react-icons/md";
import { BsPersonFillAdd } from "react-icons/bs";
const FriendsSearch = () => {
  const [crewMembers, setCrewMembers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [
    fetchedSearchedInput,
    { loading: searchedLoading, data: searchedData },
  ] = useLazyQuery(QUERY_SEARCH_USER);

  useEffect(() => {
    if (searchInput !== "") {
      fetchedSearchedInput({ variables: { searchInput } });
    }
  }, [searchInput]);

  const [addFriend, { error }] = useMutation(ADD_FRIENDS);
  const friendsID = "64c09b66d0bd163c81973291";

  const {
    loading: friendsLoading,
    data: friendsData,
    refetch,
  } = useQuery(QUERY_USER_FRIENDS, {
    variables: { email: "email." },
  });
  useEffect(() => {
    if (!friendsLoading) {
      setCrewMembers(friendsData.oneUser.friends);
    }
  }, [friendsLoading, friendsData]);
  const handleCLick = async (e) => {
    e.preventDefault();
    const data = e.currentTarget;
    const email = data.getAttribute("email");
    try {
      const { data } = await addFriend({
        variables: {
          friendsID,
          email,
        },
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {!Auth.loggedIn() ? (
        <ErrorPage />
      ) : (
        <section className=" min-h-screen">
          <div className="col-span-4  flex items-center bg-[#000206] ">
            <Link
              to={"/jsaCreator"}
              className="icon  border-[#]   md:ml-5 xl:ml-32 "
            >
              <FaLongArrowAltLeft
                size={20}
                className="icon pb-1 hover:h-[.1%]"
              />
            </Link>
          </div>
          <section className="bg-[#000206] cursor-default">
            <section
              className={`${styles.paddingX} ${styles.boxWidth} mx-auto relative    pb-5 `}
            >
              <h3 className=" col-span-4 font-semibold mb-3 pt-2 text-sm ">
                Add friends
              </h3>
              <div className=" bg-[#070707fa]  rounded-lg text-gray flex items-center px-2 w-full ">
                <div className="flex py-3">
                  <FaMagnifyingGlass
                    color="#c9c9c9"
                    size={12}
                    className="icon text-red"
                  />
                </div>
                <input
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder="Search"
                  className="ml-2 flex-1 inputStyle2 flex bg-transparent inputPlaceHolder text-[#919191] text-sm font-semibold focus:outline-none"
                />
              </div>
            </section>
          </section>

          <div className="w-full bg-[#000206] relative  h-6 mt-[-20px]  shadow-lg shadow-[#000206]"></div>
          <div className=" pt-5 cursor-default">
            <section
              className={`${styles.paddingX} ${styles.boxWidth} mx-auto pt-2    `}
            >
              <div className="col-span-4  min-h-screen">
                <ul className="grid grid-cols-1  gap-2  sm:grid-cols-2  ">
                  {searchedData === undefined ? (
                    <p className="text-xs">
                      Search for user by name or email...{" "}
                    </p>
                  ) : (
                    searchedData.searchForUser.map((item, i) => {
                      const isAlreadyAdded = crewMembers.some(
                        (member) => item.email === member.email
                      );

                      return (
                        <li
                          className={` flex px-2 cursor-default rounded-md bg-[#0E0E0E] justify-between gap-x-6 py-2 ${
                            isAlreadyAdded
                              ? "bg-blue-600 shadow-md"
                              : "bg-[#050505] rounded-lg cursor-pointer  peer-checked:shadow-blue-950"
                          }  rounded-md  border-[#62626228]  shadow-lg  `}
                        >
                          <input
                            type="checkbox"
                            id={`${item.email}-option`}
                            value=""
                            className="hidden peer"
                            required=""
                          />
                          <div className="flex min-w-0 gap-x-4">
                            <img
                              className="h-12 w-12 flex-none rounded-full bg-gray-50"
                              src={item.profilePicture}
                              alt="profile picture"
                            />
                            <div className="min-w-0 w-36">
                              <p className="text-sm font-semibold leading-6 text-gray-100">
                                {item.username}
                              </p>
                              <p className="mt-1 truncate text-xs leading-5 text-gray-300">
                                {item.email}
                              </p>
                            </div>
                          </div>
                          <div className=" shrink-0 flex flex-col items-end">
                            <p className="text-sm invisible leading-6 text-gray-400">
                              .
                            </p>

                            <div className="mt-1 flex items-center gap-x-1.5">
                              {isAlreadyAdded ? (
                                <MdLibraryAddCheck />
                              ) : (
                                <BsPersonFillAdd
                                  key={i} // Assuming 'i' is defined elsewhere in your component
                                  onClick={handleCLick}
                                  name={item.username}
                                  className="hover:w-[20px] hover:h-[20px]"
                                  email={item.email}
                                />
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </section>
          </div>
        </section>
      )}
    </>
  );
};

export default FriendsSearch;
