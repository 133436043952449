import React from "react";
import styles from "../../style";
import FeaturedJobs from "./FeaturedJobs";

const JobBoard = () => {
  return (
    <section className="min-h-screen bg-[#00040F]">
      <div className="jobBoardBg   ">
        <div className="loginbg2 flex flex-col items-center justify-center h-[250px] sm:h-[350px] md:h-[400px] lg:h-[450px] xl:h-[550px]">
          <h1 className="text-white mb-3 font-bold text-3xl md:text-4xl lg:text-6xl">
            Job Opportunities
          </h1>
          <p className="text-gray-100 font-medium">
            Your job career starts here with us.
          </p>
        </div>
      </div>
      <div className={` ${styles.paddingX} ${styles.flexStart} `}>
        <div className={`${styles.boxWidth}`}>
          <FeaturedJobs />
        </div>
      </div>
    </section>
  );
};

export default JobBoard;
