import React, { useEffect, useState } from "react";
import { BsFolderPlus, BsPersonPlusFill } from "react-icons/bs";
import SearchOldJsa from "../components/SearchOldJsa";
import { Link } from "react-router-dom";
import Auth from "../utils/auth";
import ErrorPage from "./ErrorPage";
import { QUERY_USER } from "../utils/queries";
import { useQuery } from "@apollo/client";
import YourPageNav from "../components/YourPageNav";
import CompanyNav from "../components/CompanyNav";
import CompanyBodyPage from "../components/CompanyBodyPage";

const JsaPage = () => {
  // const pageView = window.location.pathname.split('?').pop();
  const [pageView, setPageView] = useState("");

  const [changePage, setChangePage] = useState(false);

  const setChange = (page) => {
    if (page === "Page") {
      setChangePage(true);
      return;
    }

    setChangePage(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pageView === "Page") {
      setChangePage(false);
      return;
    }
    setChangePage(true);
  }, []);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const param1 = queryParams.get("page");
    setPageView(param1);
    if (param1 === "company") {
      setChangePage(false);
      return;
    }
    setChangePage(true);
  }, [changePage]);
  const { loading, data } = useQuery(QUERY_USER, {
    variables: { email: "email." },
  });

  const updateQueryParameter = (page) => {
    // Get the current URL
    const currentURL = window.location.href;
    const url = new URL(currentURL);

    // Set the "page" query parameter to either "home" or "company"
    page === "Page"
      ? url.searchParams.set("page", "home")
      : url.searchParams.set("page", "company");
    page === "Page" ? setChange("Page") : setChange("Company");
    // if (page === 'Page') {

    //     setChangePage(false)
    //     return

    // };
    // setChangePage(true);

    // Replace the current URL with the updated one
    window.history.replaceState({}, "", url.href);
  };

  return (
    <>
      {!Auth.loggedIn() ? (
        <ErrorPage />
      ) : (
        <>
          <section className={`  mt-[-20px]  min-h-screen`}>
            <div className={`w-full   `}>
              <section className="  ">
                {/*  this is the div for username and pfp */}
                <div className=" bg-[#000206]  pt-4 shadow-[#000206] shadow-md">
                  <div className=" mt-2 grid grid-cols-3 cursor-default xl:w-[60%] w-[90%] mx-auto">
                    <h1 className=" text-gray-100 font-bold text-xl col-span-2">
                      {loading ? "----" : data.oneUser.username}

                      <p className="text-xs text-orange-500 pl-1">
                        Above Ground Solutions
                      </p>
                    </h1>
                    <Link to={"/search/friends"} className="justify-self-end ">
                      <BsPersonPlusFill size={22} className="text-[#f3f2f2]" />
                    </Link>
                    <div className="col-span-3 justify-around flex text-xs border-b-[1px] border-stone-800 mt-1 text-gray-100">
                      <p
                        onClick={() => {
                          updateQueryParameter("Page");
                        }}
                        className={`${
                          changePage
                            ? " font-semibold border-b-[3px] border-blue-500 "
                            : ""
                        } cursor-pointer roundedmd leading-8 `}
                      >
                        Your page
                      </p>
                      <p
                        onClick={() => {
                          updateQueryParameter("Company");
                        }}
                        className={`${
                          changePage
                            ? " "
                            : "font-semibold border-b-[3px] border-blue-500 "
                        } cursor-pointer roundedmd leading-8 `}
                      >
                        Company
                      </p>
                    </div>
                  </div>
                  {/* the choices  */}
                  {changePage ? <YourPageNav /> : <CompanyNav />}
                </div>

                <div className="   rounded-md ">
                  {changePage ? <SearchOldJsa /> : <CompanyBodyPage />}
                </div>
              </section>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default JsaPage;
