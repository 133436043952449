import StopWatch from "./StopWatch";

const ClockIn = () => {
  return (
    <div className="min-h-[calc(100vh-43px)]">
      <StopWatch />
      
    </div >
  );
};

export default ClockIn;
