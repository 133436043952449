import React from "react";

function ErrorPage({ errorStatus, heading, description, href }) {
  return (
    <section className="herobg grid  h-[100vh]  px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center mt-12">
        <p className="text-6xl font-bold text-orange-600"></p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-200-500 sm:text-5xl">
          Login to View
        </h1>
        <p className="mt-6 text-lg font-semibold leading-7 text-gray-300">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-5 flex  justify-center">
          <a
            href="/Login"
            className="rounded-md loginbg border px-3.5 py-2.5  font-bold text-xl  shadow-black text-white shadow-2xl focus-visible:outline-2 "
          >
            Login to View
          </a>
        </div>
      </div>
    </section>
  );
}
export default ErrorPage;
