import React, { useCallback, useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { PiMedalFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { GiHealthNormal, GiMountainClimbing } from "react-icons/gi";
import { FcBriefcase } from "react-icons/fc";
import northernLights from "../assets/northernLights.jpg";
import blueFlower from "../assets/blueFlower.jpg";
import yellowBrickWindow from "../assets/yellowBrickWindow.jpg";
import mountainClouds from "../assets/mountainClouds.jpg";
import Style from "../userPage.module.css";
import { QUERY_FOR_PROFILE } from "../utils/queries";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_PROFILE } from "../utils/mutations";
const UserPage = () => {
  const [newProfileIMG, setNewProfileIMG] = useState("");
  const [newName, setNewName] = useState("");
  const [newCompany, setNewCompany] = useState("");
  const [isCertClimber, setIsCertClimber] = useState(null);
  const [isCertCPRFirstAid, SetIsCertFirstAid] = useState(null);
  const [updateProfile, { error }] = useMutation(UPDATE_PROFILE);

  const { loading, data } = useQuery(QUERY_FOR_PROFILE, {
    variables: { email: "email." },
  });

  useEffect(() => {
    if (data) {
      setNewCompany(data.forProfile.company);
      setNewName(data.forProfile.username);
      setNewProfileIMG(data.forProfile.profilePicture);
      setIsCertClimber(data.forProfile.certifiedClimber);
      SetIsCertFirstAid(data.forProfile.cprFirstAid);
    }
  }, [loading, data]);
  const UpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const { data } = await updateProfile({
        variables: {
          company: newCompany,
          username: newName,
          profilePicture: newProfileIMG,
          cprFirstAid: isCertCPRFirstAid,
          certifiedClimber: isCertClimber,
        },
      });
      window.location.href = "/profile";
    } catch (error) {
      alert(error);
    }
  };

  return (
    <section className="bg-[#16191f] cursor-default ">
      <div className="col-span-4 bg-[#000206] shadow-lg shadow-[#070b0f] flex items-center w-full">
        <Link to={"/profile"} className="icon  md:ml-5 xl:ml-32 ">
          <FaLongArrowAltLeft size={20} className="icon pb-1 hover:h-[.1%]" />
        </Link>
      </div>
      <div className={` min-h-screen `}>
        <div className={Style.account_info}>
          <p className="pt-5">
            You can set preferred display name, create your profile URL and
            manage other personal settings.
          </p>
        </div>

        <div className={` cursor-default ${Style.account_box}`}>
          <div className={`cursor-default ${Style.account_box_img}`}>
            <img
              src={newProfileIMG}
              alt="profile picture"
              width={100}
              height={100}
              className={`${Style.account_box_img_img} lg:h-[130%] cursor-default  shadow-md bg-gray-600 shadow-black lg:w-[130%]  `}
            />
          </div>
          <div className={Style.account_box_from}>
            <h1 className="w-full  text-sm font-semibold pl-2 cursor-default pb-1 col-span-12 ">
              Change Profile Picture
            </h1>

            <div
              className={
                "grid grid-flow-col  cursor-pointer gap-4 py-3 bg-[#0a0a0a] rounded-md justify-center"
              }
            >
              <div className={"rounded-full bg-white"}>
                <img
                  alt="profile img"
                  onClick={() =>
                    setNewProfileIMG(
                      "http://www.aboveground.solutions/static/media/mountainClouds.91d3bea2fc0537fd82d7.jpg"
                    )
                  }
                  className="rounded-full border-2 border-gray-300 shadow-md shadow-black h-12 sm:h-16 lg:h-20 xl:h-24 w-12 sm:w-16 lg:w-20 xl:w-24 first-letter:hover:p-1"
                  src={mountainClouds}
                />
              </div>
              <div className={"rounded-full "}>
                <img
                  alt="profile img"
                  onClick={() =>
                    setNewProfileIMG(
                      "http://www.aboveground.solutions/static/media/yellowBrickWindow.5e3bd8aa91bc1a8abe06.jpg"
                    )
                  }
                  className="rounded-full border-2 border-gray-300  shadow-md shadow-black  h-12 sm:h-16 lg:h-20 xl:h-24 w-12 sm:w-16 lg:w-20 xl:w-24 first-letter:hover:p-1"
                  src={yellowBrickWindow}
                />
              </div>
              <div className={"rounded-full "}>
                <img
                  alt="profile img"
                  onClick={() =>
                    setNewProfileIMG(
                      "http://www.aboveground.solutions/static/media/blueFlower.51656fbaeab751296728.jpg"
                    )
                  }
                  className="rounded-full border-2   shadow-md shadow-black  h-12 sm:h-16 lg:h-20 xl:h-24 w-12 sm:w-16 lg:w-20 xl:w-24 first-letter:hover:p-1"
                  src={blueFlower}
                />
              </div>
              <div className={"rounded-full "}>
                <img
                  alt="profile img"
                  onClick={() =>
                    setNewProfileIMG(
                      "http://www.aboveground.solutions/static/media/northernLights.4b4876264c373d56b9a7.jpg"
                    )
                  }
                  className="rounded-full border-2 border-gray-300  shadow-md shadow-black  h-12 sm:h-16 lg:h-20 xl:h-24 w-12 sm:w-16 lg:w-20 xl:w-24 first-letter:hover:p-1"
                  src={northernLights}
                />
              </div>
            </div>
            <div className={Style.Form}>
              <div className={Style.Form_box}>
                <form className="mt-[-10px]" onSubmit={UpdateProfile}>
                  <div className={Style.Form_box_input}>
                    <label htmlFor="name">First & Last Name</label>
                    <input
                      onChange={(e) => setNewName(e.target.value)}
                      value={newName}
                      type="text"
                      placeholder="Coraline Jones"
                      className={Style.Form_box_input_userName}
                    />
                  </div>

                  <div className={Style.Form_box_input}>
                    <label htmlFor="Company">Company</label>
                    <div className={Style.Form_box_input_box}>
                      <div className={Style.Form_box_input_box_icon}>
                        <FcBriefcase />
                      </div>
                      <input
                        onChange={(e) => setNewCompany(e.target.value)}
                        className={Style.Form_box_input_userName}
                        value={newCompany}
                        type="string"
                        placeholder="Company"
                      />
                    </div>
                  </div>
                  <div className="flex mt-2   ml-2">
                    <label className="w-fit text-sm leading-7 font-semibold ">
                      Certified Personnel{" "}
                    </label>
                    <PiMedalFill
                      size={16}
                      className=" justify-self-start ml-1  self-center"
                    />
                  </div>
                  <div className="  w-[80%] grid grid-cols-2 gap-4 center ">
                    <div
                      onClick={() => setIsCertClimber((prev) => !prev)}
                      className={` col-span-2 lg:col-span-1 ${
                        isCertClimber ? "bg-emerald-600" : ""
                      } cursor-pointer    hover:text-     bg-[#0a0a0a] py-3 px-4 shadow-lg rounded-lg  grid grid-cols-2 `}
                    >
                      <h1 className=" text-sm font-bold ">Climber</h1>
                      <div className=" justify-self-end ">
                        <GiMountainClimbing
                          size={25}
                          className=" 0 mt-[-3px]"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => SetIsCertFirstAid((prev) => !prev)}
                      className={` col-span-2 lg:col-span-1  ${
                        isCertCPRFirstAid ? "bg-red-600" : ""
                      }    cursor-pointer  hover:text- bg-[#0a0a0a] py-3 px-4 shadow-lg rounded-lg  grid grid-cols-2 `}
                    >
                      <h1 className=" text-sm font-bold">CPR / First Aid</h1>
                      <div className=" justify-self-end ">
                        <GiHealthNormal size={25} className=" mt-[-3px]" />
                      </div>
                    </div>
                  </div>
                  <button
                    btnName="Upload profile"
                    // onSubmit={UpdateProfile}
                    className="bg-blue-700 font-semibold w-full flex text-center rounded justify-center my-9 mb-14"
                  >
                    {" "}
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserPage;
