import React from "react";
import styles from "../style";
import { useQuery } from "@apollo/client";
import { QUERY_USER_COMPANIES } from "../utils/queries";
import { Link } from "react-router-dom";

const CompanyBodyPage = () => {
  const { loading, data } = useQuery(QUERY_USER_COMPANIES);
  return (
    <section
      className={` ${styles.paddingX} ${styles.boxWidth} mx-auto   mt-5  text-md  `}
    >
      {data ? (
        data.userCompanies.map((item, i) => {
          return (
            <Link key={item.id} to={`/Company/${item.id}`}>
              <div className=" w-full border-stone-800 border-t center p-2">
                <div className="flex gap-1 rounded-md cursor-pointer hover:bg-[#00000034] p-1">
                  <div className=" bg-black rounded-full px-2 font-semibold">
                    {item.companyName.toUpperCase().split("")[0]}
                  </div>
                  <h3 className="text-xs leading-3 mt-[2px] font-medium font ">
                    {item.companyName}
                    <br />
                    <span className="text-[10px] pl-1 font-light">
                      {item.creator.username}
                    </span>
                  </h3>
                </div>

                {item.JsaFile.length === 0
                  ? ""
                  : item.JsaFile.map((item, i) => {
                      return (
                        <ul className="w-[] ml-3 mt-1  flex flex-col gap-2">
                          <li className="text-start cursor-pointer bg-zinc-950 border-[3px] border-black shadow-[#080808] py-1 px-4 shadow rounded-md flex">
                            <div className="flex flex-col col-span-2 md:col-span-1">
                              <h1 className="text-xs justify-end text-start font-bold">
                                {item.jobNumber}
                              </h1>
                              <p className="text-[10px] text-orange-500 truncate font-extralight leading-5">
                                {item.physicalAddress}
                              </p>
                              <p className="text-[10px] leading-5 text-blue-500 font-bold ">
                                created on:{item.creactionDate}
                              </p>
                            </div>
                          </li>
                        </ul>
                      );
                    })}
              </div>
            </Link>
          );
        })
      ) : (
        <>loading</>
      )}
    </section>
  );
};

export default CompanyBodyPage;
