

import { useEffect, useRef, useState } from "react";



const Timer=({isRunning, formatTime,setElapsedTime, startTimeRef, intervalIdRef})=>{





  useEffect(() => {
    if (isRunning) {
      intervalIdRef.current = setInterval(() => {
        setElapsedTime(Date.now() - startTimeRef.current);
      }, 10);
    }

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [isRunning]);



return(
    <div className="text-5xl font-bold">



{formatTime()}
    </div>
)
}


export default Timer