import styles from "../style";
import Auth from "../utils/auth";
import { useQuery } from "@apollo/client";
import ErrorPage from "../pages/ErrorPage";
import createNewJsa from "../utils/createPDF";
import { CREATE_JSA } from "../utils/mutations";
import sendEmailHandler from "../utils/sendEmail";
import { Link, useLocation } from "react-router-dom";
import { QUERY_USER_FRIENDS } from "../utils/queries";
import { useMutation } from "@apollo/client";
import { FaCircleChevronDown, FaPersonCirclePlus } from "react-icons/fa6";
import { FaEdit, FaLongArrowAltLeft } from "react-icons/fa";
import { QUERY_JSA_FOR_UPDATE } from "../utils/queries";
import React, { useState, useEffect, useRef } from "react";
import { MdLibraryAddCheck } from "react-icons/md";
import { BsPersonFillAdd } from "react-icons/bs";
import LoaderModal from "./LoaderModal";
import { IoSend } from "react-icons/io5";
// import { platform } from "process";
// import { usePlacesService } from '@ubilabs/google-maps-react-hooks';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const QuestionsForJsa = () => {
  const { loading, data } = useQuery(QUERY_USER_FRIENDS, {
    variables: { email: "email." },
  });
  const [selectedDate, setSelectedDate] = useState(null);

  const autocompleteRef = useRef(null);
  const [showJsa, SetShowJsa] = useState(true);
  const [towerWork, setTowerWork] = useState(true);
  const [civilWork, setCivilWork] = useState(true);
  const [crewMembers, setCrewMembers] = useState([]);
  const [isTrueForError, SetIsTrueForError] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newJobTask, setNewJobTask] = useState("");
  const [newHazards, setNewHazards] = useState("");
  const [newPreventiveMeasures, setNewPreventiveMeasures] = useState("");
  const [jobTaskDescription, setJobTaskDescription] = useState([]);
  const [preventiveMeasuresDescription, setPreventiveMeasuresDescription] =
    useState([]);
  const [potentialHazardsDescription, setNewPotentialHazardsDescription] =
    useState([]);
  const location = useLocation();
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [txtInvalidEmail, setTxtInvalidEmail] = useState("");

  const id = new URLSearchParams(location.search).get("one");
  const companyID = new URLSearchParams(location.search).get("company");
  const [BTN_Start, setBTN_Start] = useState(true);
  const { loading: loadingGetOneJsaData, data: dataGetOneJsaData } = useQuery(
    QUERY_JSA_FOR_UPDATE,
    {
      variables: { jsaPageId: id },
    }
  );
  const [userJsa, setUserJsa] = useState({
    hospitalAddress: "",
    jobName: "",
    jobNumber: "",
    physicalAddress: "",
    date: "",
    longitude: " ",
    supervisor: "",
    latitude: " ",
    ambulance: "",
    fire: "",
    police: "",
    localHospital: "",
    telephoneCo: "",
    utilityCo: "",
    evacuationPoint: "",
    jobTasks: " ",
    potentialHazards: " ",
    preventiveMeasures: " ",
    fallsFromElevationsCheckBoxList: false,
    firstAidKitCheckBoxList: false,
    rescueBagTowerCheckBoxList: false,
    towerErectionCheckboxList: false,
    elericalCheckBoxList: false,
    civilConcreteCheckBoxList: false,
    lineAtennasCheckBoxList: false,
    blueNoticeSignCheckBoxList: false,
    yellowCautionSignCheckBox: false,
    redWarningSignCheckBoxList: false,
    redDangerSignCheckBoxList: false,
    electricalHazardsCheckBoxList: false,
    silicaExposureCheckBox: false,
    coldStressCheckBoxList: false,
    chemicalExposure: false,
    liftingHazards: false,
    hardHatCheckBoxList: false,
    hearingCheckBoxList: false,
    glovesCheckBoxList: false,
    rfmonitorsCheckBoxList: false,
    tailgateMeetingCheckBoxList: false,
    lockoutTagoutCheckBoxList: false,
    fourInOneSignCheckBoxList: false,
    excavationLogCheckBoxList: false,
    heatStressCheckBoxList: false,
    elecricalCheckBoxList: false,
    LineAntennasCheckBoxList: false,
    describeTypeDepthExcavation: " ",
    slopingCheckBoxList: false,
    benchingCheckBoxList: false,
    shoringCheckBoxList: false,
    trenchCheckboxList: false,
    shieldBoxCheckBoxList: false,
    laddertrench4FeetCheckBoxList: false,
    elevationSiteTerrainEnivromentalConcersHazards: " ",
    hazardsWithSiteVehicleStorage: " ",
    describeTypeElectricalCocernsOrHazards: " ",
    typeTowerOrRoofTop: " ",
    typeAntennaBoom: " ",
    exitManLiftMoPCheckBoxList: false,
    fullBodyHarnessCheckBoxList: false,
    ropeGrabCheckBoxList: false,
    cableGrabCheckBoxList: false,
    doubleLegTwoLanyardsCheckBoxList: false,
    retractableLifeLineChecBoxList: false,
    anchorageStrapsCheckBoxList: false,
    fallProtectionRopeCheckBoxList: false,
    inspectedFallProtectionEquipmentCheckBoxList: false,
    describeFallProtectionSystemUsedAccessAntenna: " ",
    craneBoomTruckCheckBoxList: false,
    otherJobSafetyCheckboxList: " ",
    towerRescueOtherCheckBox: " ",
    vehicleTrafficCheckBoxList: false,
    suspenedPersonnelPlatformChechBoxList: false,
    undergroundUtilitiesCheckBoxList: false,
    highNoise85DBaCheckBoxList: false,
    slipsTripsOrFallsCheckBoxList: false,
    emeAndRfCheckBoxList: false,
    chemicalExposureCheckBoxList: false,
    liftingHazardsCheckBoxList: false,
    safetyGlassesCheckBoxList: false,
    rfSuitsCheckBoxList: false,
    toolsEquipmentCheckBoxList: false,
    housekeepingCheckBoxList: false,
    ginPolesCheckBoxList: false,
    riggingCheckBoxList: false,
    do911SystemsWorkCheckBoxList: false,
    tagLinesCheckBoxList: false,
    groundFaultProtectionCheckBoxList: false,
    hoistsCheckBoxList: false,
    towerginPoleCheckBoxList: false,
    fallProtectionCheckBoxList: false,
    descendersCheckBoxList: false,
    permitSystemCheckBoxList: false,
    fallsFromElevationsCheckedBoxList: false,
    elevationSiteTerrainCheckBoxList: false,
    //mpe readings
    blueNoticeFirstMpeReading: " ",
    blueNoticeSecondMpeReading: " ",
    yellowCautionFirstMpeReading: " ",
    yellowCautionSecondMpeReading: " ",
    redWarningFirstMpeReading: " ",
    redWarningSecondMpeReading: " ",
    //others
    otherPhysicalHazards: " ",
    otherHealthHazards: " ",
  });
  const [chosenCrewMembers, SetCrewMembers] = useState([
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
    {
      name: " ",
      crewEmail: " ",
      joined: false,
      company: " ",
      cprFirstAid: false,
      certifiedClimber: false,
    },
  ]);
  // const [latorLng, setLatOrLng] = useState({ lat: '', lng: '' })
  // const [physicalAddress, setPhysicalAddress] = useState({ physicalAddress: 'dfd' })
  // const [placeIds, setPlaceIds] = useState({ hospital: '', police: '', fire_station: '' })

  // const [phoneNumber, setPhoneNumber] = useState({ hospital: '', police: '', fire_station: '' })
  const [createJsa, { error }] = useMutation(CREATE_JSA);
  useEffect(() => {
    if (dataGetOneJsaData === null || dataGetOneJsaData === undefined) {
      return;
    }
    if (dataGetOneJsaData.showJsaForUpdate === null) {
      return;
    }
    if (dataGetOneJsaData === null) {
      return;
    }
    if (dataGetOneJsaData.showJsaForUpdate === null) {
      return;
    }
    setUserJsa(dataGetOneJsaData.showJsaForUpdate);
  }, [loadingGetOneJsaData]);
  useEffect(() => {
    if (data) {
      setCrewMembers(data.oneUser.friends);
    }
  }, [loading, data]);
  const CreateJsa = async () => {
    if (
      userJsa.hospitalAddress === "" ||
      userJsa.jobName === "" ||
      userJsa.jobNumber === "" ||
      userJsa.police === "" ||
      userJsa.localHospital === "" ||
      userJsa.fire === "" ||
      userJsa.utilityCo === "" ||
      userJsa.supervisor === "" ||
      userJsa.evacuationPoint === "" ||
      userJsa.longitude === "" ||
      userJsa.latitude === "" ||
      userJsa.date === "" ||
      userJsa.physicalAddress === "" ||
      userJsa.ambulance === "" ||
      userJsa.telephoneCo === ""
    ) {
      handleError();
      window.scrollTo(0, 0);
      return;
    }
    const regex = /^(\(\d{3}\) ?\d{3}-\d{4}|\d{10}|\d{3})$/;

    if (
      !regex.test(userJsa.police) ||
      !regex.test(userJsa.localHospital) ||
      !regex.test(userJsa.fire) ||
      !regex.test(userJsa.utilityCo) ||
      !regex.test(userJsa.ambulance) ||
      !regex.test(userJsa.telephoneCo)
    ) {
      handleError();
      window.scrollTo(0, 0);
      return;
    }

    const creatorName = data.oneUser.username;
    const creatorFirstAid = data.oneUser.cprFirstAid;
    const creatorClimber = data.oneUser.certifiedClimber;
    const creatorID = data.oneUser._id.toString();
    const company = data.oneUser.company;
    let JobProgress = jobTaskDescription.map((task) => ({
      task,
      progress: "0",
    }));
    const JSA = {
      ...userJsa,
      JobProgress,
      creatorName,
      chosenCrewMembers,
      company,
      creatorClimber,
      creatorFirstAid,
      creatorID,
      jobTasks: jobTaskDescription.join(" "),
      potentialHazards: potentialHazardsDescription.join(" "),
      preventiveMeasures: preventiveMeasuresDescription.join(" "),
    };
    createNewJsa(JSA)
      .then(async (response) => {
        try {
          const JSA = {
            ...userJsa,
            JobProgress,
            creatorName,
            chosenCrewMembers,
            company,
            creatorFirstAid,
            creatorClimber,
            creatorID,
            companyID,
          };
          const { data } = await createJsa({
            variables: { ...JSA, pdfDataUrl: response },
          });

          await sendEmailsToClickedMembers(data);
        } catch (error) {
          console.log(error, "hello");
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const sendEmailsToClickedMembers = async (data1) => {
    for (const member of chosenCrewMembers) {
      if (member.crewEmail === " " || member.name === " ") {
        continue;
      }
      await sendEmailHandler(
        member.name,
        data.oneUser.username,
        member.crewEmail,
        data1.createJsa._id
      );
    }
    window.location.href = "/jsaCreator";
  };
  const handleCLick = async (e) => {
    e.preventDefault();

    const data = e.currentTarget;
    const firstAid = data.getAttribute("cprFirstAid") === "true";
    const certifiedClimber = data.getAttribute("certifiedClimber") === "true";
    const name = data.getAttribute("name");
    const updatedData = [...chosenCrewMembers];
    if (updatedData.some((item) => item.name === name)) {
      const clearedData = updatedData
        .filter((item) => item.name !== name) // Remove the clicked item
        .concat({
          name: " ",
          crewEmail: " ",
          joined: false,
          company: " ",
          cprFirstAid: false,
          certifiedClimber: false,
        }); // Add the clicked item to the end

      SetCrewMembers(clearedData);
      setCurrentIndex(currentIndex - 1);
      return;
    }
    if (currentIndex === 7) {
      return;
    }
    const company = data.getAttribute("company");
    const crewEmail = data.getAttribute("email");

    updatedData[currentIndex] = {
      name: name,
      crewEmail: crewEmail,
      company: company,
      joined: false,
      cprFirstAid: firstAid,
      certifiedClimber: certifiedClimber,
    };

    SetCrewMembers(updatedData);
    setCurrentIndex(currentIndex + 1);
  };
  const handleError = () => {
    SetIsTrueForError(true);
    setTimeout(() => {
      SetIsTrueForError(false);
    }, 5000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserJsa((prevUserJsa) => ({ ...prevUserJsa, [name]: value }));
  };
  const isBoxChecked = (name) => {
    setUserJsa((prevUserJsa) => ({
      ...prevUserJsa,
      [name]: !prevUserJsa[name],
    }));
  };

  const checkLatnLng = async () => {
    async function getData(address) {
      try {
        const URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${userJsa.physicalAddress}&key=AIzaSyCKucUGIOQxhDibwftLqh9dBihOD2cQgf8`;
        const response = await fetch(URL);
        const data = await response.json();
        if (data.status === "INVALID_REQUEST") {
          return;
        }
        if (data.status === "ZERO_RESULTS") {
          return;
        }
        setUserJsa((prevUserJsa) => ({
          ...prevUserJsa,
          latitude: data.results[0].geometry.location.lat.toString(),
          longitude: data.results[0].geometry.location.lng.toString(),
        }));
      } catch (error) {
        setUserJsa((prevUserJsa) => ({
          ...prevUserJsa,
          latitude: "resultgeometrylocationlat",
          longitude: `dataresults[0]geometrylocation.lng`,
        }));
      }
    }
    await getData();
  };
  const ToggleView = (property, e) => {
    switch (property) {
      case "towerWork":
        setTowerWork((prevTowerWork) => !prevTowerWork);
        break;
      case "civilWork":
        setCivilWork((prevCsetCivilWork) => !prevCsetCivilWork);
        break;
      case "showJsa":
        if (
          userJsa.jobName === "" ||
          userJsa.jobNumber === "" ||
          userJsa.physicalAddress === "" ||
          userJsa.supervisor === "" ||
          userJsa.date === ""
        ) {
          handleError();
          return;
        }
        SetShowJsa((prev) => !prev);
        setBTN_Start(false);
        checkLatnLng();
        break;
      case "fallsFromElevationsCheckedBoxList":
        setUserJsa((prevUserJsa) => ({
          ...prevUserJsa,
          fallsFromElevationsCheckBoxList:
            !prevUserJsa.fallsFromElevationsCheckBoxList,
        }));
        break;
      case "firstAidKitCheckBoxList":
        setUserJsa((prevUserJsa) => ({
          ...prevUserJsa,
          firstAidKitCheckBoxList: !prevUserJsa.firstAidKitCheckBoxList,
        }));
        break;
      case "rescueBagTowerCheckBoxList":
        setUserJsa((prevUserJsa) => ({
          ...prevUserJsa,
          rescueBagTowerCheckBoxList: !prevUserJsa.rescueBagTowerCheckBoxList,
        }));
        break;
      case "towerErectionCheckboxList":
        setUserJsa((prevUserJsa) => ({
          ...prevUserJsa,
          towerErectionCheckboxList: !prevUserJsa.towerErectionCheckboxList,
        }));
        break;
      case "elericalCheckBoxList":
        setUserJsa((prevUserJsa) => ({
          ...prevUserJsa,
          elericalCheckBoxList: !prevUserJsa.elericalCheckBoxList,
        }));
        break;
      default:
        break;
    }
  };
  const isValidEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(email);
  };

  const addNewUser = () => {
    if (isValidEmail(newUserEmail) === false) {
      handleAddUserError("*invalid email*");

      return;
    }
    if (crewMembers.find((item) => item.email === newUserEmail)) {
      handleAddUserError("*user already added*");

      return;
    }
    setNewUserEmail("");
    const name = newUserEmail.split("@")[0];
    const updatedCrewMembers = [
      ...crewMembers,
      {
        __typename: "User",
        username: name,
        email: newUserEmail,
        profilePicture:
          "https://nelsonsigns.co.nz/wp-content/uploads/2015/06/temporary-sign.jpg",
        company: "AboveGround ",
        cprFirstAid: true,
        certifiedClimber: true,
      },
    ];

    setCrewMembers(updatedCrewMembers);
  };
  const handleAddUserError = (text) => {
    setInvalidEmail(true);
    setTxtInvalidEmail(text);
    setTimeout(() => {
      setInvalidEmail(false);
      setTxtInvalidEmail("");
    }, 5000);
  };

  // useEffect(() => {
  //     if (userJsa.latitude !== '' && userJsa.longitude !== '') {
  //         twofindNearestFireStations();
  //     }
  // }, [userJsa.latitude]);
  // useEffect(() => {
  //     if (latorLng.lat !== '' && latorLng.lng !== '') {
  //         fetchPlaceDetails();

  //     }
  // }, [placeIds.fire_station]);

  // const twofindNearestFireStations = async (latitude, longitude) => {
  //     const radius = 25000; // Radius in meters
  //     const locations = ['hospital', 'police', 'fire_station']
  //     for (const location of locations) {
  //         try {
  //             const response = await fetch(`/api/places?latitude=${userJsa.latitude},${userJsa.longitude}&radius=${radius}&location=${location}`);
  //             const data = await response.json();
  //             // Process the data to get the closest fire stations
  //             console.log(data.results[0].place_id);
  //             // const nameLocation = `${location} da`
  //             setPlaceIds((prevUserJsa) => ({
  //                 ...prevUserJsa,
  //                 [location]: ` ${data.results[0].place_id}`
  //             }));

  //         } catch (error) {
  //             console.log('Error:', error.message);
  //         }
  //     }
  // };
  // const fetchPlaceDetails = async (placeId) => {
  //     const { fire_station, hospital, police } = placeIds

  //     const theFireStation = fire_station.split(':').pop().trim();
  //     const thePoliceStation = police.split(':').pop().trim();
  //     const theHospital = hospital.split(':').pop().trim();
  //     const theLocations = [theFireStation, thePoliceStation, theHospital]

  //     for (const ID of theLocations) {
  //         try {
  //             const response = await fetch(`/api/place-details?placeId=${ID}&`);
  //             const data = await response.json();
  //             const phoneNumber = data.result.formatted_phone_number;
  //             switch (ID) {
  //                 case theFireStation:

  //                     setUserJsa(prevState => ({ ...prevState, fire: phoneNumber }));

  //                     break;
  //                 case thePoliceStation:
  //                     setUserJsa(prevState => ({ ...prevState, police: phoneNumber }));

  //                     break;
  //                 case theHospital:
  //                     setUserJsa(prevState => ({ ...prevState, localHospital: phoneNumber }));
  //                     break;
  //                 default:
  //                     break;
  //             }
  //         } catch (error) {
  //             console.log('Error:', error.message);
  //         }
  //     }
  // };

  const initAutocomplete = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteRef.current,
      {
        types: ["address"],
        componentRestrictions: { country: ["us"] },
      }
    );
  };

  const loadScript = () => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCKucUGIOQxhDibwftLqh9dBihOD2cQgf8&libraries=places&callback=handleScriptLoad`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  };

  // Callback function for the Google Maps API script loading
  window.handleScriptLoad = () => {
    initAutocomplete();
  };

  useEffect(() => {
    loadScript();
    return () => {
      // Cleanup: Remove the Google Maps API script when component unmounts
      document.head.removeChild(
        document.querySelector('script[src*="maps.googleapis.com"]')
      );
    };
  }, []);

  const dynamicInputs = document.querySelectorAll(".dynamic-validation");

  dynamicInputs.forEach((input) => {
    input.addEventListener("input", () => {
      validateInput(input);
    });
  });
  function validateInput(input) {
    const pattern = input.getAttribute("pattern");
    if (pattern && !new RegExp(pattern).test(input.value)) {
      input.classList.remove("valid-input");
      input.classList.add("invalid-input");
    } else {
      input.classList.remove("invalid-input");
      input.classList.add("valid-input");
    }
  }
  const addtask = (e) => {
    const data = e.currentTarget;
    const name = data.getAttribute("name");
    if (
      name === "PotentialHazards" &&
      potentialHazardsDescription.length < 4 &&
      newHazards.length > 0
    ) {
      setNewPotentialHazardsDescription([
        ...potentialHazardsDescription,
        newHazards,
      ]);
      setNewHazards("");
    }
    if (
      name === "preventiveMeasure" &&
      preventiveMeasuresDescription.length < 4 &&
      newPreventiveMeasures.length > 0
    ) {
      setPreventiveMeasuresDescription([
        ...preventiveMeasuresDescription,
        newPreventiveMeasures,
      ]);
      setNewPreventiveMeasures("");
    }
    if (
      name === "jobTasks" &&
      jobTaskDescription.length < 4 &&
      newJobTask.length > 0
    ) {
      setNewJobTask("");
      setJobTaskDescription([...jobTaskDescription, newJobTask]);
      setUserJsa((prevUserJsa) => ({
        ...prevUserJsa,
        ["jobTasks"]: jobTaskDescription.join(" "),
      }));
    }
  };
  return (
    <>
      {!Auth.loggedIn() ? (
        <ErrorPage />
      ) : (
        <div className="">
          <div className="col-span-4 bg-[#000206] z-50   relative shadow-md shadow-[#070b0f] flex items-center w-full">
            <Link to={"/jsaCreator"} className="icon md:ml-5 xl:ml-32 ">
              <FaLongArrowAltLeft
                size={20}
                className="icon pb-1 hover:h-[.1%]"
              />
            </Link>
            <h1 className="  pl-5 pb-1 text-sm w-full "> CREATE JSA</h1>
          </div>
          <div
            className={`text-white px-6 py-1 border-0 z-50 rounded-b-sm  mb-3 text-center bg-red-700 shadow-red-950 shadow-md  ${
              !isTrueForError ? "invisible" : "block"
            }`}
          >
            <span className="text-lg inline-block ">
              <i className="fas fa-bell" />
            </span>
            <span className="inline-block  text-sm">
              <p className=" text-lg  inline-flex"></p> Please fill in all
              mandatory fields.
            </span>
            <button className="absolute  bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"></button>
          </div>
          <section
            autoComplete="off"
            className={` ${styles.boxWidth} mb-[-20px] min-h-screen mx-auto`}
            onChange={handleInputChange}
          >
            <form autoComplete="off">
              <div
                onChange={handleInputChange}
                className={` gap-1 grid grid-cols-4 text-  ${styles.paddingX}`}
              >
                <div className="col-span-2  gap-2">
                  <h3 className="text-xs pl-1 ">
                    Job Name: <span className="text-red-600">*</span>{" "}
                  </h3>
                  <input
                    value={userJsa.jobName}
                    placeholder="name"
                    name="jobName"
                    className=" w-full inputStyle text-xs flex focus:outline-none inputPlaceHolder  font-medium  "
                  ></input>
                </div>
                <div className="col-span-2  ">
                  <h3 className="text-xs pl-1">
                    Job Number: <span className="text-red-600">*</span>{" "}
                  </h3>
                  <input
                    value={userJsa.jobNumber}
                    type="text"
                    placeholder="CLV093432"
                    name="jobNumber"
                    className="inputStyle  text-xs w-full focus:outline-none inputPlaceHolder flex  font-medium  "
                  ></input>
                </div>
                <div className="col-span-4 mt- text ">
                  <h3 className="text-xs pl-1 ">
                    Physical address: <span className="text-red-600">*</span>{" "}
                  </h3>
                  <input
                    name="physicalAddress"
                    value={userJsa.physicalAddress}
                    onBlur={handleInputChange}
                    ref={autocompleteRef}
                    placeholder="123 Main St, Apt 4B"
                    className="w-full   flex-wrap border-none flex inputStyle bg-transparent text-xs focus:outline-none inputPlaceHolder font-medium  "
                  ></input>
                </div>
                <div className="col-span-4"></div>
                <div className="col-span-2 text- gap-2">
                  <h3 className="text-xs pl-1 ">
                    supervisor <span className="text-red-600">*</span>{" "}
                  </h3>
                  <input
                    value={userJsa.supervisor}
                    placeholder="name"
                    autoComplete="off"
                    name="supervisor"
                    className="  w-full inputStyle text-xs flex focus:outline-none inputPlaceHolder  font-medium  "
                  ></input>
                </div>

                <div className="col-span-2  ">
                  <h3 className="text-xs mb-[-2px]  pl-1">
                    Date: <span className="text-red-600">*</span>{" "}
                  </h3>
                  <input
                    placeholder="10/12/25"
                    name="date"
                    type="date"
                    className="inputStyle text-xs w-full focus:outline-none  inputPlaceHolder flex  font-medium  "
                  ></input>
                </div>
                <div
                  onClick={() => ToggleView("showJsa")}
                  className={` w-[50%] ${
                    BTN_Start ? "block" : "hidden"
                  } my-4 mx-auto text-center rounded-md hover:bg-blue-600 hover:shadow-blue-950  bg-[#ff5e00e5]     shadow-black col-span-4 mb-4`}
                >
                  <button className="text-center py-[-10px] justify-center   text-xs ">
                    {" "}
                    Start
                  </button>
                </div>
              </div>
            </form>
            <div className=" grid grid-cols-3 gap-3 text leading-4"></div>

            {/* rest of questions after start */}
            <section
              className={`mb-5 ${
                showJsa ? "hidden" : "grid"
              } mt-2  self-center items-center justify-center mx-auto   grid-cols-4 ${
                styles.paddingX
              } gap-1  ${styles.boxWidth} `}
            >
              <div className="col-span-2  ">
                <h3 className="text-xs pl-1">
                  Longitude: <span className="text-red-600">*</span>{" "}
                </h3>
                <input
                  placeholder="51.5072"
                  value={userJsa.longitude}
                  onChange={handleInputChange}
                  name="longitude"
                  className="inputStyle flex  w-full bg-transparent text-xs inputPlaceHolder focus:outline-none    font-medium  "
                ></input>
              </div>
              <div className="col-span-2">
                <h3 className="text-xs pl-1">
                  Latitude: <span className="text-red-600">*</span>{" "}
                </h3>
                <input
                  placeholder="0.1276"
                  value={userJsa.latitude}
                  onChange={handleInputChange}
                  name="latitude"
                  className="inputStyle flex w-full bg-transparent text-xs inputPlaceHolder focus:outline-none    font-medium  "
                ></input>
              </div>

              <p class="text-[8px] md:text-[10px] leading-3  col-span-4 text-orange-600">
                Please enter phone number in the format (XXX)XXX-XXXX.
              </p>

              <div className="col-span-2 ">
                <h3 className="text-xs pl-1 pb-[2px]">
                  police: <span className="text-red-600">*</span>{" "}
                </h3>
                <input
                  type="text"
                  placeholder="(xxx) xxx-xxxx"
                  pattern="^(\(\d{3}\) ?\d{3}-\d{4}|\d{10}|\d{3})$"
                  required
                  value={userJsa.police}
                  onChange={handleInputChange}
                  name="police"
                  className=" dynamic-validation flex inputStyle mt-[-2px] text-sm  pl-1 w-full bg-transparent focus:outline-none inputPlaceHolder font-medium  "
                ></input>
              </div>

              <div
                className="flex items-center pl-3 col-span-2 p-1"
                onChange={() => isBoxChecked("do911SystemsWorkCheckBoxList")}
              >
                <input
                  id="do911SystemsWork-checkbox-list"
                  type="checkbox"
                  checked={userJsa.do911SystemsWorkCheckBoxList}
                  className="w-4 h-4 icon accent-orange-600 rounded"
                />
                <label
                  htmlFor="do911SystemsWork-checkbox-list"
                  className="pl-2 text-[10px] leading-3 "
                >
                  911 systems work with cell phones?:{" "}
                  <span className="text-red-600">*</span>{" "}
                </label>
              </div>

              <div className="col-span-2 p-1 ">
                <label className="text-xs pl-1 mb-[-2px]">
                  Ambulance: <span className="text-red-600">*</span>{" "}
                </label>
                <input
                  value={userJsa.ambulance}
                  pattern="^(\(\d{3}\) ?\d{3}-\d{4}|\d{10}|\d{3})$"
                  required
                  placeholder="(xxx) xxx-xxxx"
                  onChange={handleInputChange}
                  name="ambulance"
                  className=" dynamic-validation flex inputStyle mt-[-2px] text-sm  pl-1 w-full bg-transparent focus:outline-none inputPlaceHolder font-medium  "
                ></input>
              </div>
              <div className="col-span-2 p-1">
                <h3 className="text-xs pl-1">
                  Fire: <span className="text-red-600">*</span>{" "}
                </h3>
                <input
                  value={userJsa.fire}
                  pattern="^(\(\d{3}\) ?\d{3}-\d{4}|\d{10}|\d{3})$"
                  required
                  placeholder="(xxx) xxx-xxxx"
                  onChange={handleInputChange}
                  name="fire"
                  className="  dynamic-validation flex inputStyle text-sm  pl-1 w-full bg-transparent focus:outline-none inputPlaceHolder font-medium  "
                ></input>
              </div>
              <div className="col-span-2 p-1 ">
                <h3 className="text-xs pl-1">
                  Local Hospital: <span className="text-red-600">*</span>{" "}
                </h3>
                <input
                  value={userJsa.localHospital}
                  pattern="^(\(\d{3}\) ?\d{3}-\d{4}|\d{10}|\d{3})$"
                  required
                  placeholder="(xxx) xxx-xxxx"
                  onChange={handleInputChange}
                  name="localHospital"
                  className="dynamic-validation flex inputStyle text-sm  pl-1 w-full bg-transparent focus:outline-none inputPlaceHolder font-medium  "
                ></input>
              </div>
              <div className="col-span-2 p-1">
                <h3 className="text-xs pl-1">
                  Evacuation: <span className="text-red-600">*</span>{" "}
                </h3>
                <input
                  value={userJsa.evacuationPoint}
                  placeholder="evacuationPoint"
                  name="evacuationPoint"
                  className="flex inputStyle w-full text-sm bg-transparent focus:outline-none inputPlaceHolder font-medium  "
                ></input>
              </div>
              <div className="col-span-2 p-1 ">
                <h3 className="text-xs pl-1">
                  {" "}
                  telephone Co: <span className="text-red-600">*</span>{" "}
                </h3>
                <input
                  value={userJsa.telephoneCo}
                  pattern="^(\(\d{3}\) ?\d{3}-\d{4}|\d{10}|\d{3})$"
                  required
                  placeholder="(xxx) xxx-xxxx"
                  onChange={handleInputChange}
                  name="telephoneCo"
                  className=" dynamic-validation flex inputStyle text-sm  pl-1 w-full bg-transparent focus:outline-none inputPlaceHolder font-medium  "
                ></input>
              </div>
              <div className="col-span-2 p-1">
                <h3 className="text-xs pl-1">
                  utility Co: <span className="text-red-600">*</span>{" "}
                </h3>
                <input
                  value={userJsa.utilityCo}
                  pattern="^(\(\d{3}\) ?\d{3}-\d{4}|\d{10}|\d{3})$"
                  required
                  placeholder="911"
                  name="utilityCo"
                  className="dynamic-validation flex inputStyle text-sm  pl-1 w-full bg-transparent focus:outline-none inputPlaceHolder font-medium  "
                ></input>
              </div>
              <div className="col-span-4 mt- text ">
                <h3 className="text-xs pl-1  ">
                  Hospital address: <span className="text-red-600">*</span>{" "}
                </h3>
                <input
                  name="hospitalAddress"
                  value={userJsa.hospitalAddress}
                  onBlur={handleInputChange}
                  placeholder="123 Main St, Apt 4B"
                  className="w-full flex-wrap border-none flex inputStyle bg-transparent text-xs focus:outline-none inputPlaceHolder font-medium  "
                ></input>
              </div>
              {/*  */}

              <h3 className=" col-span-4 font-semibold mt-3 text-sm    ">
                Choose Crew:{" "}
              </h3>

              <div className={`col-span-4 `}>
                <p className="text-[10px] pl-1 leading-4 font text-orange-600">
                  {currentIndex} of 7
                </p>
                <ul className="grid grid-cols-1  gap-2  sm:grid-cols-2  ">
                  {data === false ? (
                    <LoaderModal />
                  ) : (
                    crewMembers.map((item, i) => {
                      const isAlreadyAdded = chosenCrewMembers.some(
                        (member) => item.email === member.crewEmail
                      );

                      return (
                        <li
                          htmlFor={`${item.email}-option`}
                          className={` flex px-2 rounded-md bg-[#050505] justify-between gap-x-6 py-2 ${
                            isAlreadyAdded
                              ? "bg-blue-600 shadow-md"
                              : "bg-[#0E0E0E] rounded-lg cursor-pointer  peer-checked:shadow-blue-950"
                          }  rounded-md  border-[#62626228]  shadow-lg  `}
                          cprFirstAid={item.cprFirstAid.toString()}
                          certifiedClimber={item.certifiedClimber.toString()}
                          number={i}
                          onClick={handleCLick}
                          email={item.email}
                          name={item.username}
                          company={item.company}
                        >
                          <div className="flex min-w-0 gap-x-4 ">
                            <img
                              className="h-12 w-12 flex-none rounded-full bg-gray-50"
                              src={item.profilePicture}
                              alt="profile picture"
                            />
                            <div className="min-w-0 w-36">
                              <p className="text-sm font-semibold leading-6 text-gray-100">
                                {item.username}
                              </p>
                              <p className="mt-1 truncate text-xs leading-5 text-gray-300">
                                {item.email}
                              </p>
                            </div>
                          </div>
                          <div className=" shrink-0 flex flex-col items-end">
                            <p className="text-sm invisible leading-6 text-gray-400">
                              .
                            </p>

                            <div className="mt-1 flex items-center gap-x-1.5">
                              {isAlreadyAdded ? (
                                <MdLibraryAddCheck />
                              ) : (
                                <BsPersonFillAdd
                                  key={i} // Assuming 'i' is defined elsewhere in your component
                                  onClick={handleCLick}
                                  name={item.username}
                                  cprFirstAid={item.cprFirstAid.toString()}
                                  certifiedClimber={item.certifiedClimber.toString()}
                                  email={item.email}
                                />
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>

              {/* another questions */}
              <div className="col-span-4 mt-2  ">
                <h3 className="flex  text-xs mx-auto pl-1 font-medium">
                  Add new Member:{" "}
                  <span className="text-[10px] ml-2 md:text-xs  text-red-500">
                    {" "}
                    {txtInvalidEmail}
                  </span>{" "}
                </h3>
                <div
                  className={` col-span-4 bg-[#0D0D0D] w-[94%] sm:w-[50%] md:w-[50%]  ${
                    invalidEmail ? " ring-2 ring-red-500" : " "
                  } rounded-lg  text-gray flex items-center pl-2  mt-1`}
                >
                  <div className="flex py-3">
                    <FaPersonCirclePlus
                      color="#c9c9c9"
                      size={15}
                      className="mx-1 "
                    />
                  </div>
                  <input
                    onChange={(e) => setNewUserEmail(e.target.value)}
                    id="helper-checkbox"
                    value={newUserEmail}
                    placeholder="<user>@email.com"
                    autoComplete="off"
                    className={` 'flex' ml-2  w-full font-medium bg-transparent text-white/90 text-xs focus:outline-none`}
                  />
                  <div
                    className="flex py-3 bg-white/5 justify-self-end pl-[2px] pr-2 rounded-r-md "
                    onClick={addNewUser}
                  >
                    <IoSend
                      color="#c9c9c9"
                      size={15}
                      className={`flex cursor-pointer ml-2 justify-end bg-transparent text-[#919191] text-sm focus:outline-none`}
                    />
                  </div>
                </div>
              </div>

              {/* job Safety */}

              <h3 className="col-span-4  font-semibold   text-sm  mt-2">
                Tower Rescue procedures to be used:{" "}
              </h3>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg   ">
                <li className="w-full col-span-2  ">
                  <div
                    className="flex items-center "
                    onChange={() => isBoxChecked("towerRescueFireDeparment")}
                  >
                    <input
                      id="twerRescueFireDeparment0-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4  accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="twerRescueFireDeparment0-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Fire Department
                    </label>
                  </div>
                </li>
              </ul>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg   ">
                <li className="w-full col-span-2  ">
                  <div
                    className="flex items-center "
                    onChange={() => isBoxChecked("towerRescueInHouseCrew")}
                  >
                    <input
                      id="inHouseCrewCheckBox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4  accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="inHouseCrewCheckBox"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      In-house Crew{" "}
                    </label>
                  </div>
                </li>
              </ul>
              <div
                className="flex items-center col-span-2 "
                onChange={handleInputChange}
              >
                <label
                  htmlFor="otherPhysicalHazards-checkbox-list"
                  className="w-full py-1  text-xs font-medium "
                >
                  other:{" "}
                  <input
                    name="towerRescueOtherCheckBox"
                    className="inputStyle text-xs w-full focus:outline-none inputPlaceHolder flex  font-medium  "
                    placeholder="type here..."
                  />
                </label>
              </div>

              <h3 className="col-span-4  font-semibold   text-sm  mt-3">
                1. Job Safety/ Task for Today
              </h3>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg   ">
                <li className="w-full col-span-2  ">
                  <div className="flex items-center ">
                    <input
                      id="firstAidKit-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4  accent-orange-600   rounded   "
                      onChange={() => isBoxChecked("firstAidKitCheckBoxList")}
                    />
                    <label
                      htmlFor="firstAidKit-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Fist Aid kit on site
                    </label>
                  </div>
                </li>
                <li className="w-full col-span-2  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("rescueBagTowerCheckBoxList")}
                  >
                    <input
                      id="rescueBagTower-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="rescueBagTower-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Rescue Bag at Base of Tower
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("towerErectionCheckboxList")}
                  >
                    <input
                      id="towerErection-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="towerErection-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Tower Erection
                    </label>
                  </div>
                </li>
              </ul>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg  ">
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("elecricalCheckBoxList")}
                  >
                    <input
                      id="elecrical-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="elecrical-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Elecrical
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("civilConcreteCheckBoxList")}
                  >
                    <input
                      id="civilConcrete-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="civilConcrete-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Civil/concrete
                    </label>
                  </div>
                </li>
                <li
                  className="w-full "
                  onChange={() => isBoxChecked("lineAtennasCheckBoxList")}
                >
                  <div className="flex items-center">
                    <input
                      id="LineAntennas-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="LineAntennas-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Line and Antennas
                    </label>
                  </div>
                </li>
              </ul>
              <div
                className="flex items-center col-span-4 "
                onChange={handleInputChange}
              >
                <label
                  htmlFor="other-checkbox-list"
                  className="w-full py-[2px] text-xs font-medium "
                >
                  other:{" "}
                  <input
                    name="otherJobSafetyCheckboxList"
                    className="inputStyle text-xs w-full focus:outline-none inputPlaceHolder flex  font-medium  "
                    placeholder="type here..."
                  />
                </label>
              </div>

              {/* RF SIGNAGE AND MPE READING */}

              <div className="col-span-4 p-1 text-sm ">
                <h3>RF Signage & MPE readings </h3>
              </div>
              <ul
                className="col-span-4 items-center w-full text-sm font-medium   rounded-lg  "
                onChange={handleInputChange}
              >
                <li className="w-full ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("blueNoticeSignCheckBoxList")}
                  >
                    <input
                      id="blueNoticeSign-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4  accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="blueNoticeSign-checkbox-list"
                      className="w-full py-[2px] pl-2 text-xs font-medium "
                    >
                      Blue Notice Sign
                    </label>
                  </div>
                </li>
                <li className="w-full ">
                  <div
                    className="flex items-center"
                    onChange={handleInputChange}
                  >
                    <label
                      htmlFor="laravel-checkbox-list"
                      className="w-full py-1 text-xs "
                    >
                      Equipment Elevation MPEreading:{" "}
                      <input
                        name="blueNoticeFirstMpeReading"
                        className="inputStyle focus:outline-none inputPlaceHolder text-xs pl-1"
                        placeholder="type here..."
                      />
                    </label>
                  </div>
                </li>
                <li className="w-full ">
                  <div
                    className="flex items-center"
                    onChange={handleInputChange}
                  >
                    <label
                      htmlFor="laravel-checkbox-list"
                      className="w-full py-1 text-xs "
                    >
                      Equipment Elevation MPEreading:{" "}
                      <input
                        name="blueNoticeSecondMpeReading"
                        className="inputStyle text-xs  focus:outline-none inputPlaceHolder    "
                        placeholder="type here..."
                      />
                    </label>
                  </div>
                </li>
                <li className="w-full   ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("yellowCautionSignCheckBox")}
                  >
                    <input
                      id="yellowCautionSign-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="yellowCautionSign-checkbox-list"
                      className="w-full py-[2px] pl-2 text-xs font-medium "
                    >
                      Yellow caution Sign
                    </label>
                  </div>
                </li>
                <li className="w-full ">
                  <div className="flex items-center">
                    <label
                      htmlFor="laravel-checkbox-list"
                      className="w-full py-1 text-xs "
                    >
                      Equipment Elevation MPEreading:{" "}
                      <input
                        name="yellowCautionFirstMpeReading"
                        className="inputStyle text-xs  focus:outline-none inputPlaceHolder     "
                        placeholder="type here..."
                      />
                    </label>
                  </div>
                </li>
                <li className="w-full ">
                  <div className="flex items-center">
                    <label
                      htmlFor="laravel-checkbox-list"
                      className="w-full py-1 text-xs "
                    >
                      Equipment Elevation MPEreading:{" "}
                      <input
                        name="yellowCautionSecondMpeReading"
                        className="inputStyle text-xs  focus:outline-none inputPlaceHolder     "
                        placeholder="type here..."
                      />
                    </label>
                  </div>
                </li>
                <li className="w-full   ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("redWarningSignCheckBoxList")}
                  >
                    <input
                      id="redWarningSign-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="redWarningSign-checkbox-list"
                      className="w-full py-[2px] pl-2 text-xs font-medium "
                    >
                      red Warning Sign
                    </label>
                  </div>
                </li>
                <li className="w-full ">
                  <div className="flex items-center">
                    <label
                      htmlFor="laravel-checkbox-list"
                      className="w-full py-1 text-xs "
                    >
                      Equipment Elevation MPEreading:{" "}
                      <input
                        name="redWarningFirstMpeReading"
                        className="inputStyle text-xs  focus:outline-none inputPlaceHolder     "
                        placeholder="type here..."
                      />
                    </label>
                  </div>
                </li>
                <li className="w-full ">
                  <div className="flex items-center">
                    <label
                      htmlFor="laravel-checkbox-list"
                      className="w-full py-1 text-xs "
                    >
                      Equipment Elevation MPEreading:{" "}
                      <input
                        name="redWarningSecondMpeReading"
                        className="inputStyle text-xs  focus:outline-none inputPlaceHolder     "
                        placeholder="type here..."
                      />
                    </label>
                  </div>
                </li>
                <li className="w-full   ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("redDangerSignCheckBoxList")}
                  >
                    <input
                      id="redDangerSign-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="redDangerSign-checkbox-list"
                      className="w-full py-[2px] pl-2 text-xs font-medium "
                    >
                      Red Danger Sign
                    </label>
                  </div>
                </li>

                {/* Job Tasks */}
              </ul>

              <div className="col-span-4 grid-col-4 p-1 w-full text-sm ">
                {"3.Conduct feasibility studies and cost analyses for".len}
                <h3 className="text-sm">
                  Job/ Tasks (Be Specific){" "}
                  <span className="text-orange-600 text-xs"> 0 of 4</span>{" "}
                </h3>
                <div
                  className={` col-span-4 bg-[#0D0D0D] sm:w-[50%] md:w-[50%]  ${
                    invalidEmail ? " ring-2 ring-red-500" : " "
                  } rounded-lg  text-gray flex items-center pl-2  mt-1`}
                >
                  <div className="flex  py-3">
                    <FaEdit color="#c9c9c9" size={15} className="mx-1 " />
                  </div>
                  <input
                    onChange={(e) => setNewJobTask(e.target.value)}
                    id="helper-checkbox"
                    value={newJobTask}
                    placeholder="job tasks...."
                    maxLength={51}
                    autoComplete="off"
                    className={` 'flex' ml-2  w-full font-medium bg-transparent text-white/90 text-xs focus:outline-none`}
                  />
                  <div
                    name="jobTasks"
                    className="flex py-3 bg-white/5 justify-self-end   pr-2 rounded-r-md "
                    onClick={addtask}
                  >
                    <IoSend
                      color="#c9c9c9"
                      size={15}
                      className={`flex cursor-pointer ml-2 justify-end bg-transparent text-[#919191] text-sm focus:outline-none`}
                    />
                  </div>
                </div>
                {/* <textarea placeholder="type here... " name="jobTasks" className=" w-full h-[140px] inputStyle font-normal   text-xs resize-none p-2 mt-2" /> */}
              </div>
              <div className="col-span-4 grid-col-4 p-1 w-full text-sm  bg-[#0D0D0D] rounded-md h-[171px]">
                <ul className="grid  p-2 divide-y divide-gray-800">
                  {jobTaskDescription.map((item, i) => {
                    return (
                      <li className="text-xs   flex flex-wrap py-[3px] px-2">
                        <span className=" text-orange-500 pr-[2px]">
                          {" "}
                          {i + 1}.{" "}
                        </span>{" "}
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="col-span-4 p-1 text-sm">
                <h3 className="text-sm">
                  Potential Hazards{" "}
                  <span className="text-orange-600 text-xs"> 0 of 4</span>{" "}
                </h3>
                <div
                  className={` col-span-4 bg-[#0D0D0D] sm:w-[50%] md:w-[50%]  ${
                    invalidEmail ? " ring-2 ring-red-500" : " "
                  } rounded-lg  text-gray flex items-center pl-2  mt-1`}
                >
                  <div className="flex py-3">
                    <FaEdit color="#c9c9c9" size={15} className="mx-1 " />
                  </div>
                  <input
                    onChange={(e) => setNewHazards(e.target.value)}
                    id="helper-checkbox"
                    value={newHazards}
                    autoComplete="off"
                    placeholder="Potential Hazard...."
                    className={` 'flex' ml-2  w-full font-medium bg-transparent text-white/90 text-xs focus:outline-none`}
                  />
                  <div
                    name="PotentialHazards"
                    className="flex py-3 bg-white/5 justify-self-end  pr-2 rounded-r-md "
                    onClick={addtask}
                  >
                    <IoSend
                      color="#c9c9c9"
                      size={15}
                      className={`flex cursor-pointer ml-2 justify-end  bg-transparent text-[#919191] text-sm focus:outline-none`}
                    />
                  </div>
                </div>
                {/* <textarea name="potentialHazards" placeholder="type here..." className="w-full inputStyle font-normal h-[140px]  text-xs font  resize-none p-2 mt-2" /> */}
              </div>
              <div className="col-span-4 grid-col-4 p-1 w-full text-sm  bg-[#0D0D0D] rounded-md h-[171px]">
                <ul className="grid  p-2 divide-y divide-gray-800">
                  <ul className="grid  p-2 divide-y divide-gray-800">
                    {potentialHazardsDescription.map((item, i) => {
                      return (
                        <li className="text-xs   py-[2px] px-2">
                          <span className="text-orange-500"> {i + 1}. </span>{" "}
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </div>

              <div className="col-span-4 p-1 text-sm">
                <h3 className="text-sm">
                  Preventive Measure{" "}
                  <span className="text-orange-600 text-xs"> 0 of 4</span>{" "}
                </h3>
                <div
                  className={` col-span-4 bg-[#0D0D0D] sm:w-[50%] md:w-[50%]  ${
                    invalidEmail ? " ring-2 ring-red-500" : " "
                  } rounded-lg  text-gray flex items-center pl-2  mt-1`}
                >
                  <div className="flex py-3">
                    <FaEdit color="#c9c9c9" size={15} className="mx-1 " />
                  </div>
                  <input
                    onChange={(e) => setNewPreventiveMeasures(e.target.value)}
                    id="helper-checkbox"
                    value={newPreventiveMeasures}
                    placeholder="Preventive Measures...."
                    autoComplete="off"
                    className={` 'flex' ml-2  w-full font-medium bg-transparent text-white/90 text-xs focus:outline-none`}
                  />
                  <div
                    name="preventiveMeasure"
                    className="flex py-3 bg-white/5 justify-self-end pl-[2px] pr-2 rounded-r-md"
                    onClick={addtask}
                  >
                    <IoSend
                      color="#c9c9c9"
                      size={15}
                      className={`flex cursor-pointer ml-2 justify-end bg-transparent text-[#919191] text-sm focus:outline-none`}
                    />
                  </div>
                </div>
                {/* <textarea name="preventiveMeasures" placeholder="type here..." className="w-full inputStyle font-normal h-[140px] text-xs border-orange-500  resize-none p-2 mt-2" /> */}
              </div>
              <div className="col-span-4 grid-col-4 p-1 w-full text-sm  bg-[#0D0D0D] rounded-md h-[171px]">
                <ul className="grid  p-2 divide-y divide-gray-800">
                  <ul className="grid  p-2 divide-y divide-gray-800">
                    {preventiveMeasuresDescription.map((item, i) => {
                      return (
                        <li className="text-xs   py-[2px] px-2">
                          <span className="text-orange-500"> {i + 1}. </span>{" "}
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </div>
              {/* back of the page */}

              <h3 className="col-span-4  font-semibold   text-sm  mt-3">
                Pysical Hazards
              </h3>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg   ">
                <li className="w-full col-span-2  ">
                  <div className="flex items-center">
                    <input
                      id="fallsFromElevations-checkbox-list"
                      onChange={() =>
                        isBoxChecked("fallsFromElevationsCheckedBoxList")
                      }
                      name="fallsFromElevationscheckboxlist"
                      type="checkbox"
                      value=""
                      className="w-4 h-4  accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="fallsFromElevations-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Falls from elevations
                    </label>
                  </div>
                </li>
                <li
                  className="w-full col-span-2  "
                  onChange={() => isBoxChecked("electricalHazardsCheckBoxList")}
                >
                  <div className="flex items-center">
                    <input
                      id="electricalHazards-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="electricalHazards-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Electrical
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() =>
                      isBoxChecked("slipsTripsOrFallsCheckBoxList")
                    }
                  >
                    <input
                      id="slipsTripsOrFalls-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="slipsTripsOrFalls-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Slips, Trips or Falls
                    </label>
                  </div>
                </li>
              </ul>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg  ">
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() =>
                      isBoxChecked("undergroundUtilitiesCheckBoxList")
                    }
                  >
                    <input
                      id="undergroundUtilities-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="undergroundUtilities-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Underground Utilities
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() =>
                      isBoxChecked("elevationSiteTerrainCheckBoxList")
                    }
                  >
                    <input
                      id="elevationSiteTerrain-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="elevationSiteTerrain-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Elevation / Site terrain
                    </label>
                  </div>
                </li>
                <li className="w-full ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("vehicleTrafficCheckBoxList")}
                  >
                    <input
                      id="vehicleTraffic-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="vehicleTraffic-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Vehicle Traffic
                    </label>
                  </div>
                </li>
              </ul>
              <div
                className="flex items-center col-span-2 "
                onChange={handleInputChange}
              >
                <label
                  htmlFor="otherPhysicalHazards-checkbox-list"
                  className="w-full py-1  text-xs font-medium "
                >
                  other:{" "}
                  <input
                    name=" otherPhysicalHazards "
                    className="inputStyle text-xs w-full focus:outline-none inputPlaceHolder flex  font-medium  "
                    placeholder="type here..."
                  />
                </label>
              </div>

              {/* healt hazards */}

              <h3 className="col-span-4  font-semibold  text-sm ">
                Health Hazards
              </h3>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg    ">
                <li className="w-full col-span-2  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("heatStressCheckBoxList")}
                  >
                    <input
                      id="heatStress-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4  accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="heatStress-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Heat Stress
                    </label>
                  </div>
                </li>
                <li className="w-full col-span-2  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("highNoise85DBaCheckBoxList")}
                  >
                    <input
                      id="highNoise85dBA-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="highNoise85dBA-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      High Noise(.85dBA)
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("emeAndRfCheckBoxList")}
                  >
                    <input
                      id="emeAndRF-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="emeAndRF-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      EME / RF
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div className="flex items-center ">
                    <label
                      htmlFor="otherHealthHazards-checkbox-list"
                      className="w-full py-1  text-xs font-medium "
                    >
                      other:{" "}
                      <input
                        className="inputStyle text-xs w-full focus:outline-none inputPlaceHolder flex  font-medium  "
                        placeholder="type here..."
                      />
                    </label>
                  </div>
                </li>
              </ul>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg  ">
                <li className="w-full  ">
                  <div
                    className="flex items-center "
                    onChange={() => isBoxChecked("silicaExposureCheckBox")}
                  >
                    <input
                      id="silicaExposure-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="silicaExposure-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Silica Exposure
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("coldStressCheckBoxList")}
                  >
                    <input
                      id="coldStress-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="coldStress-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Cold Stress
                    </label>
                  </div>
                </li>
                <li className="w-full ">
                  <div
                    className="flex items-center"
                    onChange={() =>
                      isBoxChecked("chemicalExposureCheckBoxList")
                    }
                  >
                    <input
                      id="chemicalExposure-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="chemicalExposure-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Chemical Exposure
                    </label>
                  </div>
                </li>
                <li className="w-full ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("liftingHazardsCheckBoxList")}
                  >
                    <input
                      id="liftingHazards-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="liftingHazards-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Lifiting Hazards
                    </label>
                  </div>
                </li>
              </ul>

              {/* Personal Protective Equipment  */}

              <h3 className="col-span-4 grid  font-semibold   text-sm  mt-3">
                Personal Protective Equipment/Monitoring Equipment
              </h3>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg   ">
                <li className="w-full col-span-2  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("safetyGlassesCheckBoxList")}
                  >
                    <input
                      id="safetyGlasses-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4  accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="safetyGlasses-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Safety Glasses
                    </label>
                  </div>
                </li>
                <li className="w-full col-span-2  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("fallProtectionCheckBoxList")}
                  >
                    <input
                      id="fallProtection-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="fallProtection-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Fall Protection
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("rfSuitsCheckBoxList")}
                  >
                    <input
                      id="rfSuits-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="rfSuits-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      RF Suits
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={handleInputChange}
                  >
                    <label
                      htmlFor="other-checkbox-list"
                      className="w-full py-1 text-xs font-medium "
                    >
                      other:{" "}
                      <input
                        name="otherHealthHazards"
                        className="inputStyle text-xs w-full focus:outline-none inputPlaceHolder flex  font-medium  "
                        placeholder="type here..."
                      />
                    </label>
                  </div>
                </li>
              </ul>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg  ">
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("hardHatCheckBoxList")}
                  >
                    <input
                      id="hardHat-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="hardHat-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Hard Hat
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("hearingCheckBoxList")}
                  >
                    <input
                      id="hearing-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="hearing-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Hearing
                    </label>
                  </div>
                </li>
                <li className="w-full ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("glovesCheckBoxList")}
                  >
                    <input
                      id="gloves-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="gloves-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Gloves
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("rfmonitorsCheckBoxList")}
                  >
                    <input
                      id="rfmonitors-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="rfmonitors-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      RF Monitors{" "}
                    </label>
                  </div>
                </li>
              </ul>

              {/* Inspections */}
              <h3 className="col-span-4  font-semibold   text-sm  mt-3">
                Inspections{" "}
                <span className=" text-red-600">
                  {" "}
                  * Complete all additional forms required *
                </span>
              </h3>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg   ">
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("toolsEquipmentCheckBoxList")}
                  >
                    <input
                      id="toolsEquipment-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="toolsEquipment-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Tools/ Equipment{" "}
                    </label>
                  </div>
                </li>
                <li className="w-full col-span-2  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("ginPolesCheckBoxList")}
                  >
                    <input
                      id="ginPoles-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="ginPoles-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Gin Poles{" "}
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("tagLinesCheckBoxList")}
                  >
                    <input
                      id="tagLines-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="tagLines-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Tag Lines
                    </label>
                  </div>
                </li>
              </ul>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg  ">
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("housekeepingCheckBoxList")}
                  >
                    <input
                      id="Housekeeping-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="Housekeeping-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Housekeeping
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("hoistsCheckBoxList")}
                  >
                    <input
                      id="hoists-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="hoists-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Hoists
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("riggingCheckBoxList")}
                  >
                    <input
                      id="Rigging-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="Rigging-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Rigging{" "}
                    </label>
                  </div>
                </li>
              </ul>
              <div
                className="flex items-center col-span-4"
                onChange={() =>
                  isBoxChecked("groundFaultProtectionCheckBoxList")
                }
              >
                <input
                  id="groundFaultProtection-checkbox-list"
                  type="checkbox"
                  value=""
                  className=" w-4 h-4 accent-orange-600   rounded   "
                />
                <label
                  htmlFor="groundFaultProtection-checkbox-list"
                  className=" w-full py-1 pl-2 text-xs font-medium "
                >
                  Ground Fault Protection
                </label>
              </div>
              <h3 className="col-span-4  font-semibold   text-sm  mt-3">
                Safety Program / Training
              </h3>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg   ">
                <li className="w-full col-span-2  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("tailgateMeetingCheckBoxList")}
                  >
                    <input
                      id="tailgateMeeting-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4  accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="tailgateMeeting-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      tailgateMeeting
                    </label>
                  </div>
                </li>
                <li className="w-full col-span-2  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("lockoutTagoutCheckBoxList")}
                  >
                    <input
                      id="lockoutTagout-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="lockoutTagout-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Lockout/ Tagout
                    </label>
                  </div>
                </li>
              </ul>
              <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg  ">
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("excavationLogCheckBoxList")}
                  >
                    <input
                      id="excavationLog-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="excavationLog-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Excavation Log
                    </label>
                  </div>
                </li>
                <li className="w-full  ">
                  <div
                    className="flex items-center"
                    onChange={() => isBoxChecked("fourInOneSignCheckBoxList")}
                  >
                    <input
                      id="fourInOne-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="fourInOne-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      4 in 1 Sign
                    </label>
                  </div>
                </li>
              </ul>
              <div
                className="flex items-center col-span-4"
                onChange={() => isBoxChecked("permitSystemCheckBoxList")}
              >
                <input
                  id="permitSystem-checkbox-list"
                  type="checkbox"
                  value=""
                  className=" w-4 h-4 accent-orange-600   rounded   "
                />
                <label
                  htmlFor="permitSystem-checkbox-list"
                  className=" w-full py-1 pl-2 text-xs font-medium "
                >
                  Permit System (Hoisting Personnel, Confined Space, Excavation,
                  etc.){" "}
                </label>
              </div>

              {/* Complete for CivilWork */}
              <div
                onClick={() => ToggleView("civilWork")}
                className="col-span-4 text-center loginbg py-2 text-xs border-b black"
              >
                <h1>Complete for CivilWork</h1>
                <FaCircleChevronDown className="ml-1" />
              </div>
              {civilWork ? (
                <></>
              ) : (
                <>
                  <div className="col-span-4 grid  ">
                    <div
                      className="col-span-4 p-1   "
                      onChange={handleInputChange}
                    >
                      <h3 className="text-xs">
                        Describe Type and depth of excavation:{" "}
                      </h3>
                      <input
                        placeholder="this deep as hell..."
                        name="describeTypeDepthExcavation"
                        className="inputStyle text-xs pl-1 flex w-full bg-transparent inputPlaceHolder focus:outline-none    font-medium  "
                      ></input>
                    </div>
                    <h3 className="col-span-4  font-semibold   text-xs  mt-3">
                      2. Cave-in control measures to be used if excavation will
                      be greater than 5 feet and personnel are entering the
                      trench: : :
                    </h3>
                    <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg   ">
                      <li className="w-full col-span-2  ">
                        <div
                          className="flex items-center"
                          onChange={() => isBoxChecked("slopingCheckBoxList")}
                        >
                          <input
                            id="sloping-checkbox-list"
                            type="checkbox"
                            value=""
                            className="w-4 h-4  accent-orange-600   rounded   "
                          />
                          <label
                            htmlFor="sloping-checkbox-list"
                            className="w-full py-[4px] pl-2 text-xs font-medium "
                          >
                            Sloping
                          </label>
                        </div>
                      </li>
                      <li className="w-full col-span-2  ">
                        <div
                          className="flex items-center"
                          onChange={() => isBoxChecked("benchingCheckBoxList")}
                        >
                          <input
                            id="benching-checkbox-list"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 accent-orange-600   rounded   "
                          />
                          <label
                            htmlFor="benching-checkbox-list"
                            className="w-full py-[4px] pl-2 text-xs font-medium "
                          >
                            Benching
                          </label>
                        </div>
                      </li>
                      <li className="w-full  ">
                        <div
                          className="flex items-center"
                          onChange={() => isBoxChecked("shoringCheckBoxList")}
                        >
                          <input
                            id="shoring-checkbox-list"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 accent-orange-600   rounded   "
                          />
                          <label
                            htmlFor="shoring-checkbox-list"
                            className="w-full py-[4px] pl-2 text-xs font-medium "
                          >
                            Shoring
                          </label>
                        </div>
                      </li>
                    </ul>
                    <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg  ">
                      <li className="w-full  ">
                        <div
                          className="flex items-center"
                          onChange={() => isBoxChecked("trenchCheckboxList")}
                        >
                          <input
                            id="trench-checkbox-list"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 accent-orange-600   rounded   "
                          />
                          <label
                            htmlFor="trench-checkbox-list"
                            className="w-full py-[4px] pl-2 text-xs font-medium "
                          >
                            Trench
                          </label>
                        </div>
                      </li>
                      <li className="w-full  ">
                        <div
                          className="flex items-center"
                          onChange={() => isBoxChecked("shieldBoxCheckBoxList")}
                        >
                          <input
                            id="shieldBox-checkbox-list"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 accent-orange-600   rounded   "
                          />
                          <label
                            htmlFor="shieldBox-checkbox-list"
                            className="w-full py-[4px] pl-2 text-xs font-medium "
                          >
                            Shield/Box
                          </label>
                        </div>
                      </li>
                      <li className="w-full ">
                        <div
                          className="flex items-center"
                          onChange={() =>
                            isBoxChecked("laddertrench4FeetCheckBoxList")
                          }
                        >
                          <input
                            id="laddertrench4Feet-checkbox-list"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 accent-orange-600   rounded   "
                          />
                          <label
                            htmlFor="laddertrench4Feet-checkbox-list"
                            className="w-full py-[4px] pl-2 text-xs font-medium "
                          >
                            ladder in Trench at 4 feet
                          </label>
                        </div>
                      </li>
                    </ul>
                    {/* Describe elevation  */}
                    <div
                      className="col-span-4 py-1  "
                      onChange={handleInputChange}
                    >
                      <h3 className="text-xs">
                        Describe elevation/ site terrain/ enviromental concerns
                        or haxards:{" "}
                      </h3>
                      <input
                        placeholder="type..."
                        name="elevationSiteTerrainEnivromentalConcersHazards"
                        className="inputStyle flex text-sm pl-1  w-full bg-transparent inputPlaceHolder focus:outline-none    font-medium  "
                      ></input>
                    </div>
                    <div
                      className="col-span-4 py-1  "
                      onChange={handleInputChange}
                    >
                      <h3 className="text-xs">
                        Describe hazards with site / vehicle acress(i.e boom and
                        cranes/electrical lines) and storage of materials:{" "}
                      </h3>
                      <input
                        placeholder="type..."
                        name=" hazardsWithSiteVehicleStorage"
                        className="inputStyle flex text-sm pl-1  w-full bg-transparent inputPlaceHolder focus:outline-none    font-medium  "
                      ></input>
                    </div>
                    <div
                      className="col-span-4 py-1  "
                      onChange={handleInputChange}
                    >
                      <h3 className="text-xs">
                        Describe the type of electrical concerns or hazards:{" "}
                      </h3>
                      <input
                        placeholder="type..."
                        name=" describeTypeElectricalCocernsOrHazards"
                        className="inputStyle flex text-sm pl-1  w-full bg-transparent inputPlaceHolder focus:outline-none    font-medium  "
                      ></input>
                    </div>
                  </div>
                </>
              )}
              {/* complete for Tower Work(fall Protection A using suspended Oersonnel Platform) */}
              <div
                onClick={() => ToggleView("towerWork")}
                className="col-span-4 border-b  text-center loginbg py-2 text-xs black"
              >
                <h1>Complete for Tower Work </h1>
                <FaCircleChevronDown className="ml-1" />
              </div>
              {towerWork ? (
                <></>
              ) : (
                <>
                  <div className="col-span-2 p-1 " onChange={handleInputChange}>
                    <h3 className="text-xs">Type of Tower/ RoofTop: </h3>
                    <input
                      placeholder="type..."
                      name="typeTowerOrRoofTop"
                      className="flex inputStyle text-xs  pl-1 w-full bg-transparent focus:outline-none inputPlaceHolder font-medium  "
                    ></input>
                  </div>
                  <div
                    className="col-span-2 p-1  "
                    onChange={handleInputChange}
                  >
                    <h3 className="text-xs">Type of Antenna Boom: </h3>
                    <input
                      placeholder="type..."
                      name="typeAntennaBoom"
                      className="flex inputStyle text-xs  pl-1 w-full bg-transparent focus:outline-none inputPlaceHolder font-medium  "
                    ></input>
                  </div>
                  <h3 className="col-span-4  font-semibold   text-xs  mt-3">
                    Fall protection to be used:
                  </h3>
                  <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg   ">
                    <li className="w-full col-span-2  ">
                      <div
                        className="flex items-center"
                        onChange={() =>
                          isBoxChecked("fullBodyHarnessCheckBoxList")
                        }
                      >
                        <input
                          id="fullBodyHarness-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4  accent-orange-600   rounded   "
                        />
                        <label
                          htmlFor="fullBodyHarness-checkbox-list"
                          className="w-full py-[4px] pl-2 text-xs font-medium "
                        >
                          Full Body Harness
                        </label>
                      </div>
                    </li>
                    <li className="w-full  ">
                      <div
                        className="flex items-center"
                        onChange={() => isBoxChecked("ropeGrabCheckBoxList")}
                      >
                        <input
                          id="ropeGrab-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 accent-orange-600   rounded   "
                        />
                        <label
                          htmlFor="ropeGrab-checkbox-list"
                          className="w-full py-[4px] pl-2 text-xs font-medium "
                        >
                          Rope Grab
                        </label>
                      </div>
                    </li>
                    <li className="w-full  ">
                      <div
                        className="flex items-center"
                        onChange={() => isBoxChecked("cableGrabCheckBoxList")}
                      >
                        <input
                          id="cableGrab-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 accent-orange-600   rounded   "
                        />
                        <label
                          htmlFor="cableGrab-checkbox-list"
                          className="w-full py-[4px] pl-2 text-xs font-medium "
                        >
                          Cable Grab
                        </label>
                      </div>
                    </li>
                    <li className="w-full col-span-2  ">
                      <div
                        className="flex items-center"
                        onChange={() =>
                          isBoxChecked("doubleLegTwoLanyardsCheckBoxList")
                        }
                      >
                        <input
                          id="doubleLegTwoLanyards-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 accent-orange-600   rounded   "
                        />
                        <label
                          htmlFor="doubleLegTwoLanyards-checkbox-list"
                          className="w-full py-[4px] pl-2 text-xs font-medium "
                        >
                          Double Leg/ Two Lanyards
                        </label>
                      </div>
                    </li>
                  </ul>
                  <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg  ">
                    <li className="w-full  ">
                      <div
                        className="flex items-center"
                        onChange={() =>
                          isBoxChecked("retractableLifeLineChecBoxList")
                        }
                      >
                        <input
                          id="retractableLifeLine-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 accent-orange-600   rounded   "
                        />
                        <label
                          htmlFor="retractableLifeLine-checkbox-list"
                          className="w-full py-[4px] pl-2 text-xs font-medium "
                        >
                          Retractable LifeLine
                        </label>
                      </div>
                    </li>
                    <li className="w-full  ">
                      <div
                        className="flex items-center"
                        onChange={() =>
                          isBoxChecked("anchorageStrapsCheckBoxList")
                        }
                      >
                        <input
                          id="AnchorageStraps-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 accent-orange-600   rounded   "
                        />
                        <label
                          htmlFor="AnchorageStraps-checkbox-list"
                          className="w-full py-[4px] pl-2 text-xs font-medium "
                        >
                          Anchorage Straps
                        </label>
                      </div>
                    </li>
                    <li className="w-full ">
                      <div
                        className="flex items-center"
                        onChange={() =>
                          isBoxChecked("fallProtectionRopeCheckBoxList")
                        }
                      >
                        <input
                          id="fallProtectionRope-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 accent-orange-600   rounded   "
                        />
                        <label
                          htmlFor="fallProtectionRope-checkbox-list"
                          className="w-full py-[4px] pl-2 text-xs font-medium "
                        >
                          Ropes
                        </label>
                      </div>
                    </li>
                    <li className="w-full ">
                      <div
                        className="flex items-center"
                        onChange={() => isBoxChecked("descendersCheckBoxList")}
                      >
                        <input
                          id="descenders-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 accent-orange-600   rounded   "
                        />
                        <label
                          htmlFor="descenders-checkbox-list"
                          className="w-full py-[4px] pl-2 text-xs font-medium "
                        >
                          Descenders
                        </label>
                      </div>
                    </li>
                  </ul>
                  <div
                    className="flex items-center col-span-4"
                    onChange={() =>
                      isBoxChecked(
                        "inspectedFallProtectionEquipmentCheckBoxList"
                      )
                    }
                  >
                    <input
                      id="inspectedFallProtectionEquipment-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="inspectedFallProtectionEquipment-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Has each employee inspected his or her fall protection
                      equipment?
                    </label>
                  </div>
                  <div
                    className="flex items-center  col-span-4"
                    onChange={() => isBoxChecked("exitManLiftMoPCheckBoxList")}
                  >
                    <input
                      id="exitManLiftMoP-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="exitManLiftMoP-checkbox-list"
                      className="w-full py-[4px] pl-2 text-xs font-medium "
                    >
                      Will operator be required to exit manlift while working at
                      heights?If yes, include MoP:
                    </label>
                  </div>
                  <div
                    className="col-span-4 p-y1  "
                    onChange={handleInputChange}
                  >
                    <h3 className="text-xs">
                      Describe the fall protection system to be used when
                      accessing antenna booms or performing tower erection:{" "}
                    </h3>
                    <input
                      placeholder="type..."
                      name="describeFallProtectionSystemUsedAccessAntenna"
                      className="inputStyle flex text-xs w-full bg-transparent inputPlaceHolder focus:outline-none    font-medium  "
                    ></input>
                  </div>
                  <h3 className="col-span-4  font-semibold   text-xs  mt-3">
                    Hoisting Equipment Equipment to be used:
                  </h3>
                  <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg   ">
                    <li className="w-full col-span-2  ">
                      <div
                        className="flex items-center"
                        onChange={() =>
                          isBoxChecked("craneBoomTruckCheckBoxList")
                        }
                      >
                        <input
                          id="craneBoomTruck-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4  accent-orange-600   rounded   "
                        />
                        <label
                          htmlFor="craneBoomTruck-checkbox-list"
                          className="w-full py-[4px] pl-2 text-xs font-medium "
                        >
                          Crane/Boom{" "}
                        </label>
                      </div>
                    </li>
                  </ul>
                  <ul className="col-span-2 items-center w-full text-sm font-medium   rounded-lg  ">
                    <li className="w-full  ">
                      <div
                        className="flex items-center"
                        onChange={() =>
                          isBoxChecked("towerginPoleCheckBoxList")
                        }
                      >
                        <input
                          id="TowerginPole-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 accent-orange-600   rounded   "
                        />
                        <label
                          htmlFor="TowerginPole-checkbox-list"
                          className="w-full py-[4px] pl-2 text-xs font-medium "
                        >
                          Gin Pole
                        </label>
                      </div>
                    </li>
                  </ul>
                  <div
                    className="flex items-center col-span-4"
                    onChange={() =>
                      isBoxChecked("suspenedPersonnelPlatformChechBoxList")
                    }
                  >
                    <input
                      id="suspenedpersonnelPlatform-checkbox-list"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 accent-orange-600   rounded   "
                    />
                    <label
                      htmlFor="suspenedpersonnelPlatform-checkbox-list"
                      className="w-full  py-1  pl-2 first-letter: text-xs font-medium "
                    >
                      Suspended Personnel Platform(Manbasket)
                    </label>
                  </div>
                </>
              )}
              {/* the end create button */}
              <div className="flex justify-center mb-5 col-span-4">
                <button
                  onClick={CreateJsa}
                  className="flex justify-center w-[30%] bg-blue-gradient text-center rounded-lg mt-2 shadow-orange-600 shadow"
                >
                  {" "}
                  create
                </button>
              </div>
            </section>
          </section>
        </div>
      )}
    </>
  );
};
export default QuestionsForJsa;
