import React, { useState } from "react";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { CheckBadgeIcon, CheckIcon } from "@heroicons/react/20/solid";

const includedFeatures = [
  "Private Job Safety Anaylsis",
  "unlimited Jsa forms",
  "Job task progress",
  "post photos and pre photos",
];
const PricingSection = () => {
  return (
    <div className="  pb-14">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-2 max-w-2xl rounded-3xl rin sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-3 pb-5 sm:p-10 lg:flex-auto">
            <h3 className="text-4xl font-bold tracking-tight text-gray-100">
              Monthly membership
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-200">
              Our platform serves diverse needs across the telecom industry,
              offering a seamless solution for creating Job Safety Analysis
              (JSAs).
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-orange-500">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-orange-500" />
            </div>
            <ul
              role="list"
              className="mt-5 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-200 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li
                  key={feature}
                  className="flex gap-x-3 text-[16px]  text-dimWhite   rounded-md bg-white/5 p-2 ring-1 ring-white/10"
                >
                  <div className="">
                    <CheckBadgeIcon
                      className="h-6 w-6 flex-none text-orange-500"
                      aria-hidden="true"
                    />
                  </div>
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-white/5 ring-2 ring-white/10 py-10 text-center  sm:mt-1 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-200">
                  Pay once, every month
                </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-100">
                    $30
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-200">
                    USD
                  </span>
                </p>
                <a
                  href="/contactUs"
                  className="mt-10 block w-full rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-200"
                >
                  Get access
                </a>
                <p className="mt-6 text-xs leading-5 text-gray-200">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
