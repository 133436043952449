import React, { useEffect, useState } from "react";
import { QUERY_ALL_INDIVIDUAL_JSA_OF_FILE } from "../utils/queries";
import { FaSquarePlus } from "react-icons/fa6";
import { FaLongArrowAltLeft } from "react-icons/fa";
import styles from "../style";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import StackedListUsers from "./StackedListUsers";
import newjsa from "../assets/jsa.png";
import { PiGearBold } from "react-icons/pi";
import { BiTrash } from "react-icons/bi";
import Modals from "./Modals";

function CreateJSA() {
  const id = window.location.pathname.split("Jsa/").pop();
  const { loading, data } = useQuery(QUERY_ALL_INDIVIDUAL_JSA_OF_FILE, {
    variables: { jsaFolderId: id },
  });

  let Jsas = data?.indiviualJsasOfUser.pages || [];
  const [addressencoded, setAddressEncoded] = useState("");

  const [showModal, setShowModal] = useState(true);
  const ToggleView = () => {
    setShowModal((prev) => !prev);
    // setShowModal(prev => !prev)
  };

  useEffect(() => {
    if (data) {
      const encodedAddress = encodeURIComponent(
        data.indiviualJsasOfUser.physicalAddress
      );
      setAddressEncoded(encodedAddress);
    }
  }, []);

  return (
    <section className={`   mx-auto flex min-h-screen flex-col`}>
      <div className="col-span-4 bg-[#000206]    flex items-center w-full">
        <Link to={"/jsaCreator"} className="icon md:ml-5 xl:ml-32 ">
          <FaLongArrowAltLeft size={20} className="icon  pb-1 hover:h-[.1%]" />
        </Link>
        <h1 className="   w-full "> </h1>
        <div onClick={ToggleView} className="mr-5 xl:mr-[13%] sm:mr-16">
          <BiTrash className="" size={20} />
        </div>
      </div>

      <div className="bg-[#000206] shadow-md shadow-[#070b0f]">
        <div className={`${styles.paddingX} ${styles.boxWidth} mx-auto mt-2`}>
          <div className=" sm:px-0 pt-2 ">
            <h3 className="text-base font-semibold leading-6 ">
              {data?.indiviualJsasOfUser.jobNumber || "loading"}
            </h3>
          </div>

          <dl className=" pl-1 text-gray-200">
            <div className=" grid grid-cols-3 gap-4 px-0">
              <dt className="text-xs font-medium leading-6 text-gray-200  ">
                Location
              </dt>
              {/* <a className=" text-xs leading-6 truncate w-44 sm:w-full text-blue-600 col-span-2 mt-0" href={`https://www.google.com/maps/place/${data?.indiviualJsasOfUser.physicalAddress || 'loading...'}`}>{data?.indiviualJsasOfUser.physicalAddress || 'loading...'}</a> */}
              <a
                href={`https://maps.apple.com/?q=${addressencoded}`}
                className=" text-xs underline leading-6 truncate w-44 sm:w-full text-blue-600 col-span-2 mt-0"
              >
                {data?.indiviualJsasOfUser.physicalAddress || "loading..."}
              </a>
            </div>

            <Link
              to={`/QuestionsJSA?one=${data?.indiviualJsasOfUser.pages[0]._id}`}
            >
              <button className="text-center text-xs center my-5 font-semibold  bg-blue-600 px-2 w-full flex justify-center py-2 rounded">
                <FaSquarePlus className="mt-[1px] mr-1" /> Create new JSA{" "}
              </button>
            </Link>
          </dl>
          <p className="text-[8px] text-gray-100 leading-[10px] my-2 mt-10">
            {" "}
            Below, you'll discover a list of all previous JSAs that have been
            generated.
            <br /> Alternatively, if you wish to create a new JSA, simply click
            the inviting orange button.
          </p>
        </div>
        <></>
      </div>

      <div
        className={` ${styles.paddingX} ${styles.boxWidth} mx-auto  mt-5  text-md leading-10 `}
      >
        <ul className="  pb-2 grid gap-2">
          {data === false
            ? "Loading..."
            : Jsas.map((item, i) => {
                return (
                  <>
                    <Link to={`/one/${item._id}`}>
                      <li
                        number={i}
                        name={item._id}
                        className="flex px-2 border-black border-[3px] rounded-md cursor-pointer bg-[#0E0E0E] justify-between gap-x-6 py-2"
                      >
                        <div className="flex min-w-0 gap-x-4">
                          <img
                            className="h-11 w-11 flex-none rounded-md shadow-md shadow-black bg-gray-50"
                            src={newjsa}
                            alt="jsa icon"
                          />
                          <div className="min-w-0 w-36 cursor-default">
                            <p className="text-sm font-semibold leading-6 text-gray-100">
                              created:
                            </p>
                            <p className=" truncate text-xs leading-5 text-gray-300">
                              {item.date}
                            </p>
                          </div>
                        </div>
                        <div className=" shrink-0 flex flex-col items-end">
                          <p className="text-sm  leading-6 text-gray-400">
                            team
                          </p>

                          <div className="flex items-center my-auto  gap-x-1.5">
                            <StackedListUsers
                              crewMembers={item.chosenCrewMembers}
                              key={i}
                            />
                          </div>
                        </div>
                      </li>
                    </Link>
                  </>
                );
              })}
        </ul>
      </div>
      {/* <div className='text-center flex justify-center mt-6  '>
                <Link to={`/QuestionsJSA?one=${data?.indiviualJsasOfUser.pages[0]._id}`} >

                    <button className='text-center  bg-orange-600 px-8 flex justify-center py-2 rounded'><FaSquarePlus />  </button>
                </Link>
            </div> */}
      {showModal ? (
        <></>
      ) : (
        <>
          <Modals
            jsaID={id}
            ToggleView={ToggleView}
            title={"Delete JSA FILE"}
          />
        </>
      )}
    </section>
  );
}

export default CreateJSA;
