import { useEffect, useState } from "react";

const TimeTable = ({
  startTimeLabel,
  stopTimeLabel,
  lapTimeLabel,
  timeLine,
}) => {
  const [timeStarted, setTimeStarte] = useState("8");
  const es = "text-black";
  return (
    <div className="grid ">
      <div className=" mt-10 text-center items-center grid grid-rows-1 grid-cols-2 text-lg font-semibold">
        <div>
          <label className="text-3xl font-bold">Clock In</label>
          <p> {startTimeLabel ? startTimeLabel : "00:00"} </p>
        </div>
        <div>
          <label className="text-3xl font-bold">Clock out</label>
          <p>{stopTimeLabel ? stopTimeLabel : "00:00"}</p>
        </div>
      </div>
      
        <div className=" bg-blue-400/5 m-5 overflow-auto flex-nowrap font-semibold text-white/95  text-md rounded-md p-5  h-[38vh]  flex flex-col ">
          {timeLine.map((item, i) => {
            return (
              <>
                <p className=" flex items-center text-sm font-semibold py-1">
                  <div className="w-1 mr-2 rounded-full h-1 bg-white " />
                  <span className="font-bold mr-2  text-lg"> {item.title}</span>
                  {item.time}
                 
                </p>
                {i == timeLine.length - 1 ? null : (
                  <div className="  bg-white/70 rounded-md h-6 w-[2px]" />
                )}
              </>
            );
          })}
        </div>
      
    </div>
  );
};

export default TimeTable;
