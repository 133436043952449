import React from "react";

import train1 from "../assets/train1.jpg";
import train2 from "../assets/train2.jpg";
import train3 from "../assets/train3.jpg";
import photo1 from "../assets/matthew.jpg";

const Training = () => {
  return (
    <div className="relative py-10 ">
      <div className=" grid grid-cols-2 pt-5 md:pt-16  ">
        <div className="relative mx-auto max-w-7xl px-4  col-span-2 md:col-span-1">
          <div className="sm:max-w-lg mb-5">
            <h1 className="text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
              Reassurance for Those Who Choose Safety
            </h1>
            <p className="mt-4 text-xl text-gray-200">
              Choose us, and
              <span className=" text-[#FF5D00] "> rest assured</span> that tower
              rescue training will be your worry-free fortress in an
              unpredictable world.
            </p>
          </div>

          <a
            href="/jobOpportunities"
            className=" rounded-md  bg-blue-600 px-4 py-3 text-center text-xs  font-medium text-white hover:bg-blue-500"
          >
            Job Opportunities
          </a>
        </div>
        <div className=" col-span-2 grid md:col-span-1 mt-10 md:mt-0">
          <div className="grid  grid-cols-2 gap-2     mx-auto justify-between">
            <div className="flex gap-4 md:gap-5 col-span-2">
              <div className="w-64 flex-shrink">
                <img
                  src={train3}
                  alt="cell tower"
                  className="h-full w-full rounded-md shadow-md shadow-black  object-cover  object-center max-w-full"
                />
              </div>
              <div className="w-64 flex-shrink">
                <img
                  src={train2}
                  alt="self rescue "
                  className="h-full w-full rounded-md shadow-md shadow-black object-cover  object-center max-w-full"
                />
              </div>
              <div className="w-64 flex-shrink">
                <img
                  src={train1}
                  alt="self rescue"
                  className="h-full w-full rounded-md shadow-md shadow-black object-cover  object-center max-w-full"
                />
              </div>
              <div className="w-64 flex-shrink">
                <img
                  src={photo1}
                  alt="self rescue"
                  className="h-full w-full rounded-md shadow-md shadow-black object-cover  object-center max-w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Training;
