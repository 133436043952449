import photo from "../assets/signUp.jpg";
import photo1 from "../assets/login.jpg";
import React, { useState } from "react";
import styles from "../style";
import "react-toastify/dist/ReactToastify.css";
import { useMutation } from "@apollo/client";
import { LOGIN_USER, ADD_USER } from "../utils/mutations";
import { ToastContainer, toast } from "react-toastify";
import Auth from "../utils/auth";

import { Link } from "react-router-dom";

const Login = () => {
  const [userFormData, setUserFormData] = useState({
    email: "",
    password: "",
    username: "",
    profilePicture: "",
  });
  const [verifyPassowrd, setVerifyPassword] = useState("");
  const [refreshCounter, setRefreshCounter] = useState(0);

  const [login, { error }] = useMutation(LOGIN_USER);
  const [addUser, { loading }] = useMutation(ADD_USER);
  const [showModel, setShowModel] = useState(true);

  const handleRefresh = () => {
    // Increment the counter to trigger a re-render of the component
    setRefreshCounter(refreshCounter + 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserFormData({ ...userFormData, [name]: value });
  };
  const handleLoginSubmit = async (event) => {
    async function notify(message) {
      event.preventDefault();

      return new Promise((resolve) => {
        toast.error(message);
        setTimeout(() => {
          resolve();
        }, 1000); // Simulate a 2-second notification
      });
    }
    if (
      userFormData.email === "" ||
      userFormData.password === "" ||
      userFormData.verifyPassword === ""
    ) {
      await notify("cannot leave form boxes blank");
      handleRefresh();

      return;
    }

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      event.preventDefault();
    }
    try {
      const { data } = await login({
        variables: { ...userFormData },
      });
      Auth.login(data.login.token);
    } catch (error) {
      console.error(error);
      notify("email or password is incorrect");
      //show an error for user
    }

    setUserFormData({ email: "", password: "" });
    handleRefresh();
  };
  function pickRandomItem(items) {
    const randomIndex = Math.floor(Math.random() * items.length);
    return items[randomIndex];
  }
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const array = [
      "https://www.aboveground.solutions/static/media/mountainClouds.91d3bea2fc0537fd82d7.jpg",
      "https://www.aboveground.solutions/static/media/yellowBrickWindow.5e3bd8aa91bc1a8abe06.jpg",
      " https://www.aboveground.solutions/static/media/blueFlower.51656fbaeab751296728.jpg",
      " https://www.aboveground.solutions/static/media/northernLights.4b4876264c373d56b9a7.jpg",
    ];

    const payload = {
      ...userFormData,
      profilePicture: pickRandomItem(array),
      cprFirstAid: true,
      certifiedClimber: true,
      company: "AboveGround",
    };

    //firebase create account
    const notify = (message) => {
      toast.error(message);
    };
    if (
      userFormData.email === "" ||
      userFormData.password === "" ||
      userFormData.username === ""
    ) {
      notify("cannot leave form boxes blank");
      return;
    }
    if (userFormData.password !== verifyPassowrd) {
      await notify("passwords must be identical ");
      handleRefresh();

      return;
    }

    try {
      const { data } = await addUser({
        variables: payload,
      });
      Auth.login(data.addUser.token);
      Auth.login(data.addUser.token);
    } catch (err) {
      console.error(err);
      //   setShowAlert(true);
      notify("email or username is taken");
    }
    setUserFormData({
      username: "",
      email: "",
      password: "",
    });
  };

  return (
    <section
      className={`bg-primary herobg h-[100vh] ${styles.paddingX}  ${styles.flexStart} `}
    >
      <section className={` ${styles.boxWidth}`}>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme={"dark"}
        />

        <section className="mt-24   flex items-center justify-center text-white mx-5 mb-12">
          <div className="bg--container--black flex rounded-2xl  max-w-3xl p-5 items-center">
            {showModel ? (
              <>
                <div className="flex shadow-2xl shadow-[black] loginbg items-center  p-3 rounded-lg">
                  <div className="sm:w-1/2 px-8  rounded-md p-5">
                    <h2 className="text-[#FF5D00] font-bold text-2xl">Login</h2>
                    <p className="  text-sm mt-2">
                      {" "}
                      Log in seamlessly if you already have an account.
                    </p>
                    <form
                      className="flex flex-col gap-2"
                      onSubmit={handleLoginSubmit}
                    >
                      <p className="text-[8px] pl-2 text-gray-300 leading-none mt-4 mb-[-3px]">
                        Your Email & Password
                      </p>

                      <input
                        key={refreshCounter}
                        onChange={handleInputChange}
                        className="inputStyle3  w-full  focus:outline-none  inputPlaceHolder flex  font-semibold  "
                        type="email"
                        name="email"
                        placeholder="email"
                      ></input>
                      <div>
                        <Link to="/forgot">
                          <p className=" text-sky-400 hover:text-sky-300  text-[10px] text-end mb-[-4px] ">
                            forgot password?
                          </p>
                        </Link>
                        <input
                          key={refreshCounter}
                          onChange={handleInputChange}
                          className="inputStyle3  w-full  focus:outline-none  inputPlaceHolder flex  font-semibold  "
                          type="password"
                          name="password"
                          placeholder="password"
                        ></input>
                      </div>
                      <button className="mt-7 font-semibold text-xs bg-[#235DDB]  rounded-md py-2">
                        {" "}
                        Login
                      </button>
                    </form>
                    <div className="mt-2 grid grid-cols-3 items-center  ">
                      <hr />
                      <p className="text-center text-[13px] text-gray-300 ">
                        OR
                      </p>
                      <hr />
                    </div>
                    <button
                      onClick={() => setShowModel(false)}
                      className="border mb-[6px] border-[#FF5D00] hover:bg-[#ff5e00eb] rounded-md text-xs py-2 w-full font-semibold mt-2 "
                    >
                      {" "}
                      Sign up
                    </button>
                  </div>
                  <div className="w-1/2 sm:block hidden">
                    <img
                      className="rounded-2xl   shadow-inner"
                      src={photo}
                      alt="login photo"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex shadow-2xl shadow-[black] loginbg items-center  p-3 rounded-lg">
                  <div className="sm:w-1/2 px-8 ">
                    <h2 className="text-[#235DDB] font-bold text-2xl">
                      Sign Up
                    </h2>
                    <p className="  text-sm mt-2  text-gray-100">
                      {" "}
                      Join effortlessly by signing up today!
                    </p>

                    <form
                      className="flex flex-col gap-2 "
                      onSubmit={handleFormSubmit}
                    >
                      <p className="text-[8px] pl-2 text-gray-300 leading-none mt-4 mb-[-5px] ">
                        {" "}
                        Your Name & Email
                      </p>

                      <input
                        onChange={handleInputChange}
                        className="inputStyle3  mb-[-3px]   w-full  focus:outline-none  inputPlaceHolder flex  font-semibold   "
                        type="username"
                        name="username"
                        placeholder="username"
                      ></input>

                      <input
                        onChange={handleInputChange}
                        className="inputStyle3  w-full  focus:outline-none inputPlaceHolder flex  font-semibold  "
                        type="email"
                        name="email"
                        placeholder="email"
                      ></input>
                      <div className=" ">
                        <p className="text-[8px] pl-2 text-gray-300 leading-none mt-2 mb-[3px]">
                          Your password and retype{" "}
                        </p>

                        <input
                          placeholder="password"
                          onChange={handleInputChange}
                          name="password"
                          className="inputStyle3     w-full  focus:outline-none inputPlaceHolder flex  font-semibold  "
                          type="password"
                        ></input>

                        <input
                          placeholder="password"
                          name=""
                          onChange={(e) => setVerifyPassword(e.target.value)}
                          className="inputStyle3  w-full  mt-1 focus:outline-none inputPlaceHolder flex  font-semibold  "
                        ></input>
                      </div>
                      <button className="mt-2 font-semibold shadow-2xl text-xs bg-[#ff5e00f7]  rounded-md py-2">
                        {" "}
                        Sign Up
                      </button>
                    </form>
                    <div className="mt-2 grid grid-cols-3 items-center  ">
                      <hr />
                      <p className="text-center text-[13px] text-gray-300 ">
                        OR
                      </p>
                      <hr />
                    </div>
                    <button
                      onClick={() => {
                        setShowModel(true);
                      }}
                      className="border border-[#235DDB] text-xs  hover:bg-[#235DDB] rounded-md py-2 w-full font-semibold mt-2"
                    >
                      {" "}
                      Login
                    </button>
                  </div>
                  <div className="w-1/2 sm:block hidden h-full ">
                    <img
                      className="rounded-2xl shadow-inner"
                      src={photo1}
                      alt="login photo"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </section>
    </section>
  );
};

export default Login;
