import React from "react";
import { FcVoicePresentation } from "react-icons/fc";
import styles from "../style";

const TermsOfUse = () => {
  return (
    <section
      className={`bg-primary min-h-screen bg-[#00040F] ${styles.paddingX}  ${styles.flexStart} `}
    >
      <section className={`  text-center  ${styles.boxWidth}  `}>
        <h1 className="text-3xl font-bold tracking-tight text-center pt-20 text-gray-100 sm:text-4xl">
          AboveGround Solutions Terms Of Use
        </h1>
        <h2 className="py-4 font-semibold">Updated October 27, 2023</h2>
        <FcVoicePresentation size={100} className="mx-auto py-2" />

        <p className=" text-gray-200 text-start font-light px-3 text-sm leading-5">
          You can view this information at any time, either in settings related
          to those features and/or online at{" "}
          <a
            className="text-blue-400 underline"
            href="https://www.aboveground.solutions/privacyPolicy"
          >
            AboveGround Solutions terms of Service
          </a>{" "}
        </p>
        <h2 className="py-4 font-semibold text-[15px] ">
          Agreement To Our Legal Terms.
        </h2>
        <p className=" text-gray-200 text-start font-light px-3 text-sm leading-5">
          We operate the website AboveGround Solutions, as well as any other
          related products and services that refer or link to these legal terms.
        </p>

        <ul className="text-xs pt-4 leading-5 text-start gap-2 grid pl-7 ">
          <li>
            <span className="text-white font-semibold"> Contact </span>us by
            email at cgarcia@aboveground.solutions.
          </li>
        </ul>

        <p className=" text-gray-200 text-start font-light px-3 pt-2 text-sm leading-5">
          These Legal Terms constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity ("you") and
          AboveGround Solutions , concerning your access to and use of the
          Services. You agree that by accessing the Services, you have read,
          understood, and agreed to be bound by all of these Legal Terms. IF YOU
          DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY
          PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE
          IMMEDIATELY.
        </p>
        <ul className="text-xs pt-4 leading-5 text-start gap-2 grid pl-7 pb-10">
          <li>
            <span className="text-white font-semibold">
              {" "}
              concerning your access to and use of the Services. You agree that
              by accessing the Services, you have read, understood, and agreed
              to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
              ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM
              USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.{" "}
            </span>
          </li>
        </ul>
      </section>
    </section>
  );
};

export default TermsOfUse;
