import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { REMOVE_FRIEND } from "../utils/mutations";

function FriendsList(props) {
  const [comment, setComment] = useState(1);
  const [removeFriend, { error }] = useMutation(REMOVE_FRIEND);

  const { refreshFunction } = props;

  const handleRefresh = () => {
    // Call the refreshFunction to trigger the refresh in the MainComponent
    refreshFunction();
  };

  const unaddFriend = async (email) => {
    const friendEmail = email;
    try {
      const { data } = await removeFriend({
        variables: { email: friendEmail },
      });
      handleRefresh();
      setComment(2);
    } catch (error) {
      console.log(error);
    }
  };
  const { friendsList } = props;
  return (
    <ul role="list" className=" gap-2 grid">
      {friendsList.map((person) => (
        <li
          key={person.email}
          className="flex px-2 rounded-md bg-[#0E0E0E] justify-between gap-x-6 py-2"
        >
          <div className="flex min-w-0 gap-x-4">
            <img
              className="h-12 w-12 flex-none rounded-full bg-gray-50"
              src={person.profilePicture}
              alt="profile picture"
            />
            <div className="min-w-0 w-36 cursor-default">
              <p className="text-sm font-semibold leading-6 text-gray-100">
                {person.username}
              </p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-300">
                {person.email}
              </p>
            </div>
          </div>
          <div className=" shrink-0 flex flex-col items-end">
            <p className="text-sm invisible leading-6 text-gray-400">.</p>

            <div
              onClick={() => unaddFriend(person.email)}
              className="mt- flex items-center gap-x-1.5  text-red-500 hover:text-red-600 hover:h-[22px]"
            >
              <IoMdTrash />
              <p className="text-xs cursor-default hover:h-[-22px] text-gray-500">
                unadd
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
export default FriendsList;
