import { InboxIcon, PaperClipIcon } from "@heroicons/react/20/solid";
import { IoLocationOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function Apprentice() {
  return (
<section className=" bg-[#00040F]">

    <div className="font-semibold p-12  ">
      <div className="p-4 bg-white/5  rounded-lg ">
        <label className="font-bold text-2xl">tech I, II</label>
        <p className=" pl-1  text-white/90 text-base leading-6 pb-3">
          Regular Full-Time / part time
          <br />
          Technical
          <br />
       Chino, CA, US
        </p>
        <span className=" text-lg font-semibold text-white ">
          Salary Range:{" "}
        </span>
        <br />
        <label className="text-base text-white/80 ">
          $17.00 To 23.00 Hourly
        </label>
      </div>
      <div className="mt-6  border-gray-100">
        <dl className="divide-y divide-gray-100/10">
          <div className="px-4 py-6 ">
            <dt className="font-semibold leading-6 text-gray-100">
              Who is AboveGround Solutions?
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-200 ">
              As a leader in the telecommunications industry, Above Ground
              excels in installation, upgrades, and troubleshooting services.
              Our unwavering commitment to a safety-first culture and integrity
              distinguishes us as a trusted partner. We highly value our
              employees, providing a supportive environment with ample
              opportunities for growth. Known for being true to our word, we
              consistently deliver high-quality service to our clients. Explore
              exciting career opportunities with Above Ground in Los Angeles
            </dd>
          </div>
          <div className="px-4 py-6 ">
            <dt className="font-semibold leading-6 text-gray-100">
              Why AboveGround Solutions?
            </dt>
            <dd className=" ml-1 text-sm leading-6 text-gray-200 ">
              {/* Why AboveGround - Because it’s not a job. It’s a career where you are
              treated like family. */}
              <div className="ml-1 leading-7">
                <br />
                ⭐️ Unparalleled market quarterly bonuses (50-100% of time over
                last three years)
                <br />
                ⭐️ Matching 401k
                <br />
                ⭐️ Opportunities for advancement
                <br />
                ⭐️ Annual employees survey which result in real action! We have
                a 98% employee satisfaction rate
                <br />
                ⭐️ Unprecedented culture
                <br />
                ⭐️ Long-term full-time employment
                <br />
              </div>
              <p className="py-6">
                We can{" "}
                <span className="font-bold text-white">beat top pay</span> upon
                successful interview and we offer a competitive benefit and
                incentive package!
              </p>
              <p>
                AboveGround is Now Hiring Tower Top Technicians for our San
                Diego Market where culture means everything. We are excited
                about this year and beyond with a heavy backlog of awarded work.
                If you want to work with a great team centrally located in
                Escondido, we would love to hear from you. As the title says, we
                understand that our crews are pivotal in achieving our client
                targets and therefore give the best performance bonuses in the
                market back to our crews on a quarterly basis.
              </p>
            </dd>
          </div>
          <div className="px-4 py-6 ">
            <dt className="font-semibold leading-6 text-gray-100">
              Physical Requirements of Position
            </dt>
            <ul className="mt-1 pl-2 text-sm leading-7 text-gray-200 list-item">
              <li>
                💪 Ability to push, pull, lift over
                <span className="font-bold">50 lbs.</span>
              </li>
              <li> 💪Bend, lift hands over head</li>

              <li>
                💪 Climb up an outdoor tower with a 25 lb. tool bag up to and in
                excess of 300 feet in all types of weather
              </li>

              <li>
                💪 Maintain OSHA safe weight requirements for safety harness (up
                to 280 lbs.)
              </li>

              <li>
                💪 Perform technical scopes of work with hands and tools per
                client specifications and standing for at least 8 hours while
                standing either on the ground or up on a tower
              </li>
            </ul>
          </div>
          <div className="px-4 py-6 ">
            <dt className="font-semibold leading-6 text-gray-100">
              Technical Requirements of Position:
            </dt>
            <dd className="mt-1 ml-1 text-sm leading-7 text-gray-200 ">
              🧠 Knowledge and experience of major carrier sites including LTE,
              NSB, Electrical, Civil, DAS, and/or Small Cell
              <br />
              🦾 Work as a part of a team with the ability to maintain good
              interpersonal relationships/communication within a small group of
              co-workers
              <br />
              🦾 Installation of RF and antenna system components such as BTS,
              RRU's, antennas, TMA's, diplexers, COAX, grounding, supports,
              weatherproofing, connectors, hardware, etc.)
              <br />
              🧠 Identify essential materials and tools to carry out scope of
              work according to client and industry methods of procedures (MOP)
              <br />
              🦾 Ability to operate tools of trade (hand and power tools and
              test gear)
              <br />
              🧠 Prioritize and organize work to maximize time efficiency with
              given deadlines
              <br />
              🧠 Read, implement and reline RFDS and site CDs.
              <br />
              🧠 Perform Job Safety Analysis, promote team safety and proper PPE
              with risk mitigation
              <br />
              🧠 Computer skills (working knowledge of Microsoft Excel and
              Outlook)
              <br />
              🦾 Must be able to work at various heights
            </dd>
          </div>
          <div className="px-4 py-6 ">
            <dt className="font-semibold leading-6 text-gray-100">
              Technical Requirements of Position:
            </dt>
            <ul className="mt-1 ml-2 text-sm leading-7 text-gray-200 ">
              <li>✅ OSHA 10 or 30</li>
              <li>✅ RF Technician Awareness Certification</li>
              <li>✅ CPR/First Aid</li>
              <li>✅ Man-lift/Aerial Platform</li>
              <li>✅ Competent Climber & Rescuer</li>
            </ul>
          </div>
          <div className="px-4 py-6 ">
            <dt className="text-sm font-medium leading-6 text-gray-100">
              If you are interested in being a part of our team, please reply to
              this ad with your resume. X3 is an Equal Opportunity Employer.{" "}
            </dt>
            <dd className="mt-5 sticky bottom-0  z-10 text-sm text-gray-100 ">
              <Link   to="/TowerTech1,2,3/JobForm">
                    <button
                    
                      className="font-bold text-lg  bg-blue-600 px-4 w-full py-1 rounded-lg"
                      >
                      APPLY
                    </button>
                      </Link>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    </section>

  );
}
