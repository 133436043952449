import React from "react";
import styles from "../../style";
import Business from "./Business";
import { useMutation } from "@apollo/client";
import Billing from "./Billing";
import Stats from "./Stats";
import { ADD_FRIENDS } from "../../utils/mutations";
import EmployeeTrainingBanner from "./EmployeeTraining";
import CertifiedClouds from "./CertifiedClouds";
import BannerSplit from "./BannerSplit";
import Training from "../Training";
import Services from "./Services";
import EstimateService from "./EstimateService";
import PricingSection from "./PricingSection";

const Hero = () => {
  const [addFriend, { error }] = useMutation(ADD_FRIENDS);

  return (
    <section className="cursor-default bg-[#00040F]">
      <section
        className={`bg-primary herobg ${styles.paddingX}  ${styles.flexStart} `}
      >
        <section className={`   ${styles.boxWidth}  `}>
          <section
            id="home "
            className={`h-[100vh] flex md:flex-row flex-col ${styles.paddingY}`}
          >
            <div className={`flex-1flex-col xl:px-0 sm:px16 px-6`}>
              <div className="flex flex-row justify-between items-center w-full mb-2 ">
                <h1 className="flex-1 md:mt-[20%] mt-[40%] ss:mt-[20%]  font-bold ss:text-[72px] text-[52px] ss:leading-[80px] leading-[47px] ">
                  ABOVE{" "}
                  <span className="text-[#FF5D00] ">
                    GROUND
                    <br className="sm:block hidden" />
                  </span>{" "}
                  SOLUTIONS.
                </h1>
                <div className="ss:flex hidden md:mr-4 mr-0 "></div>
              </div>

              <h1 className=" text-[#FF5D00] mt-24  ss:text-[48px] text-[32px] ss:leading-[35px] leading-[35px] w-full  font-extrabold ">
               
            SAFETY ABOVE ALL
              </h1>

              <p
                className={`${styles.paragraph}  max-w-[470px] text-base text-white/90 mt-5 leading-[20px]`}
              >
                "Welcome to{" "}
                <span className=" font-bold">
                  Above Ground Solutions
                </span>
                , your trusted telecom partner. We specialize in providing
                reliable cell tower solutions for seamless connectivity. Our
                expert team installs, antennas and cutting-edge infrastructure."
              </p>
            </div>
            <div></div>
          </section>

          <Stats />
        </section>
      </section>

      <div className={` ${styles.paddingX} ${styles.flexStart} `}>
        <div className={`${styles.boxWidth}`}>
          <Business />

          <div className="border-[1px] border-white/10"> </div>
        </div>
      </div>
      <BannerSplit />

      <div className=" teambg ">
        <div className={`${styles.paddingX} loginbg2 `}>
          <EmployeeTrainingBanner />
        </div>
      </div>
      <div className={`${styles.paddingX}  ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Training />
        </div>
      </div>
      <div className={` ${styles.paddingX} ${styles.flexStart} `}>
        <div className={`${styles.boxWidth}`}>
          <div className="border-[1px] border-white/10"> </div>

          <Services />
          <div className="border-[1px] border-white/10"> </div>
          <PricingSection />
          <div className="border-[1px] border-white/10"> </div>
        </div>
      </div>
      <CertifiedClouds />
      <div className={`billingbg ${styles.paddingX} ${styles.flexStart} `}>
        <div className={`${styles.paddingX} ${styles.boxWidth}  `}>
          <Billing />
        </div>
      </div>
    </section>
  );
};

export default Hero;
