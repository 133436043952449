import { CloudArrowUpIcon, CheckBadgeIcon, HeartIcon, ArchiveBoxIcon, RocketLaunc, HeartIconhIcon, RocketLaunchIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import styles from '../style'
import Services from './frontPage/Services'
import maintenance1 from "../assets/maintenance.jpg"
import cellTower2 from "../assets/towerService2.jpg"

export default function MaintenanceService() {
    return (
        <section className={`bg-[#00040F]  ${styles.paddingX}  ${styles.flexStart} `}>
            <section className={`   ${styles.boxWidth}  `}>


                <div className="relative isolate overflow-hidden  bg-[#00040F] text-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
                    <div className="absolute inset-0 -z-10 overflow-hidden">

                    </div>
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-12 lg:mx-0 lg:max-w-none md:grid-cols-2 lg:items-start lg:gap-y-10">
                        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                            <div className="lg:pr-4">
                                <div className="lg:max-w-lg">
                                    <p className="text-base font-semibold leading-5 text-orange-600">Shelter Services
                                    </p>
                                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl"> Maintaining Excellence, Ensuring Reliability</h1>
                                    <p className="mt-6 leading-6 text-gray-200">
                                        At AboveGround Solutions, we are the protectors of your telecom network's heart. Our Shelter Services encompass the design, installation,
                                        and maintenance of secure enclosures that house your essential equipment. We're committed to safeguarding your technology, ensuring it thrives in even the harshest conditions.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className=" rounded-md mt-  lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 ">
    
                            <img
                                className=" rounded-md"
                                src={cellTower2}
                                alt="antennas"
                            />
                        </div>
                        <strong className='w-full my-[-10px]  md:col-span-2 text-center'>Safeguarding Connectivity, Building for Reliability—Drawing from Our Journey of Expertise.</strong>
                        <div className='grid md:col-span-2   xl:w-[70%] mx-auto w-full  '>

                            <ul className=' grid grid-cols-2 col-span-2 text-xs '>
                                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-l-md ">
                                    <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600 " aria-hidden="true" />
                                    Battery maintenance
                                </li>
                                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-r-md ">
                                    <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600 " aria-hidden="true" />

                                    Installation Integration
                                </li>
                                <li className="flex items-center gap-x-3 p-2">
                                    <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600 " aria-hidden="true" />

                                    Site Preparation
                                </li>
                                <li className="flex items-center gap-x-3 p-2">
                                    <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600 " aria-hidden="true" />

                                    Compound containment
                                </li>
                                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-l-md ">
                                    <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600 " aria-hidden="true" />

                                    Insulation & Sealing:
                                </li>
                                <li className="flex items-center gap-x-3 bg-slate-900 p-2 rounded-r-md ">
                                    <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600  " aria-hidden="true" />

                                    Cable Management
                                </li>
                                <li className="flex items-center gap-x-3 p-2">
                                    <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600 " aria-hidden="true" />

                                    Equipment Removal
                                </li>
                                <li className="flex items-center gap-x-3 p-2">
                                    <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600 " aria-hidden="true" />

                                    Compliance & Documentation
                                </li>
                            </ul>

                        </div>
                        <div className="md:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                            <div className="">
                                <div className="text-base  max-w-xl leading-7 text-gray-200 lg:max-w-lg">
                                    <img className='rounded-md' alt='antennas' src={maintenance1} />

                                    <ul role="list" className="mt-8 space-y-2 text-gray-200">

                                        <li className="flex gap-x-3 bg-slate-900 p-2 rounded-md ">
                                            <HeartIcon className="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                            <span>
                                                <strong className="font-semibold text-gray-100">Safe</strong>  shelter services are our foremost commitment, assuring that your project is executed with the highest level of care and
                                                protection. Our unwavering dedication to safety ensures your shelter service experience is secure and successful, just as you expect.
                                            </span>
                                        </li>
                                        <li className="flex gap-x-3 p-2">
                                            <CheckBadgeIcon className="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                            <span>
                                                <strong className="font-semibold text-gray-100">Quality</strong> stands at the core of our shelter services at AboveGround Solutions. We prioritize delivering top-notch and precise shelter
                                                services, making sure your project goals seamlessly become a reality.
                                            </span>
                                        </li>
                                        <li className="flex gap-x-3 bg-slate-900 p-2 rounded-md">
                                            <RocketLaunchIcon className="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                            <span>
                                                <strong className="font-semibold text-gray-100">Speed</strong>  and efficiency define our approach to shelter services at AboveGround Solutions. We pride ourselves on our consistent
                                                ability to complete projects on time with unmatched precision.
                                            </span>
                                        </li>
                                    </ul>
                                    <p className="mt-8">
                                        Our experienced team at AboveGround Solutions is well-prepared to anticipate and resolve unforeseen challenges in shelter services, drawing from our extensive hands-on experience in the field.
                                        We've encountered and overcome the obstacles, so your shelter project can progress smoothly.
                                        Our expertise ensures a seamless journey for your shelter service needs, even in the face of unexpected hurdles.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-10'>

                    <Services />
                </div>
            </section>
        </section>

    )
}
