import React from "react";
import { FcConferenceCall, FcRating } from "react-icons/fc";
import styles from "../style";

const PrivacyPolicy = () => {
  return (
    <section
      className={`bg-primary min-h-screen bg-[#00040F] ${styles.paddingX}  ${styles.flexStart} `}
    >
      <section className={`  text-center  ${styles.boxWidth}  `}>
        <h1 className="text-3xl font-bold tracking-tight text-center pt-20 text-gray-100 sm:text-4xl">
          AboveGround Solutions Policy
        </h1>
        <h2 className="py-4 font-semibold">Updated October 25, 2023</h2>
        <p className=" text-gray-200 text-center font-light px-3 text-sm leading-5">
          AboveGround Solutions Privacy Policy describes how AboveGround
          collects, uses, and shares your personal data.
        </p>

        <FcRating size={100} className="mx-auto py-3" />

        <p className=" text-gray-200 text-start font-light px-3 text-sm leading-5">
          You can view this information at any time, either in settings related
          to those features and/or online at{" "}
          <a
            className="text-blue-400 underline"
            href="https://www.aboveground.solutions/privacyPolicy"
          >
            AboveGround Solutions privacyPolicy
          </a>{" "}
        </p>
        <h2 className="pt-4 font-semibold text-[15px] text-start">
          1. Personal Information we Collect
        </h2>
        <p className=" text-gray-200 text-start font-light px-3 text-sm leading-5">
          We collect personal information about you when you use the AboveGround
          Solutions Platform. Without it, we may not be able to provide all
          services requested. This information includes:
        </p>

        <ul className="text-xs pt-4 leading-5 text-start gap-2 grid pl-7 list-disc">
          <li>
            <span className="text-white font-semibold">
              {" "}
              Contact, Account, and Profile Information.{" "}
            </span>
            Such as your first name, last name, email address, and profile
            photo, some of which will depend on the features you use.
          </li>
          <li>
            <span className="text-white font-semibold">
              {" "}
              Third-Party Services Payment Information.{" "}
            </span>
            Verification information, bank account or payment account
            information.
          </li>
        </ul>
        <h2 className="pt-4 font-semibold text-[15px] text-start">
          2. Information You Choose to Give Us.
        </h2>

        <p className=" text-gray-200 text-start font-light px-3 text-sm leading-5">
          You can choose to provide us with additional personal information.
          This information may include:{" "}
        </p>
        <ul className="text-xs pt-4 leading-5 text-start gap-2 grid pl-7 list-disc pb-10">
          <li>
            <span className="text-white font-semibold">
              {" "}
              Additional Profile Information.{" "}
            </span>
            Such as gender, city, and personal description. Some of this
            information as indicated in your account settings is part of your
            public profile page and will be publicly visible.
          </li>
          <li>
            <span className="text-white font-semibold">
              {" "}
              Other Information.{" "}
            </span>
            Such as when you fill in a form, add information to your account,
            respond to surveys, post to community forums, participate in
            promotions, communicate with AboveGround So Support and other
            Members, import or manually enter address book contacts, provide
            your address and/or geolocation, or share your experience with us.
            This may include health information if you choose to share it with
            us.
          </li>
        </ul>
      </section>
    </section>
  );
};

export default PrivacyPolicy;
