import React from "react";

const BannerSplit = () => {
  return (
    <div className="relative flex items-center gap-x-2    text-gray-300 py-3 md:py-6  px-3.5 before:flex-1">
      <div className="flex gap-1 flex-wrap items-center  gap-y-1">
        <p className="text-xs   md:text-lg leading-6 ">
          <strong className="font-semibold ">Above Ground 2023</strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          Training Methodology.
        </p>
      </div>
      <div className="flex flex-1 justify-end"></div>
    </div>
  );
};

export default BannerSplit;
